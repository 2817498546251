import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    Modal,
    Paper,
    Select,
    Table,
    Text,
    Textarea,
    TextInput
} from "@mantine/core";
import {useStyles} from "./trackingVehiclesArrivals.styles";
import Heading from "../../../components/heading/Heading";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store/store";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";
import {getAllLocationsAction} from "../../../redux/actions/location.action";
import {getAllCabs, getCabArrivalsReports} from "../../../redux/actions/cab.action";
import {addCabTrips, addCabTripsComplete, getVisitorById} from "../../../api/api";
import {showNotification} from "@mantine/notifications";
import {TimeInput} from "@mantine/dates";
import ImagePreview from "../../../components/imagePreview/ImagePreview";
import {Cab, CabTripCreateRequest, GeoLocation} from "../../../@types";
import CircularLoader from "../../../components/loader/CircularLoader";
import {Qrcode} from "tabler-icons-react";
import {QrReader} from "react-qr-reader";
import {getAllAvailableRoutes} from "../../../redux/actions/route.action";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {GMAPIK} from "../../../config/google";


const TrackingVehiclesArrivalsScreen = () => {

    const [destinationPoint, setDestinationPoint] = useState<any>(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllLocationsAction())
        dispatch(getAllAvailableRoutes([dayjs(new Date()).format("YYYY-MM-DD")]))
        const timer = setInterval(async () => {

            dispatch(
                getCabArrivalsReports(
                    destinationPoint,
                    false
                )
            );
        }, 60000)
        dispatch(
            getCabArrivalsReports(
                destinationPoint,
                true
            )
        );
        return () => {
            clearInterval(timer);
        };
    }, [dispatch, destinationPoint]);
    const cabsState = useSelector((state: RootState) => state.cabs);
    const [cab, setCab] = useState<Cab>();
    const {classes} = useStyles();
    const [loading, setLoading] = useState(false);
    const locationState = useSelector((state: RootState) => state.location);
    const [hfnId, setHfnId] = useState<string>("");
    const [visitorContact, setVisitorContact] = useState<string>("");
    const [visitorEmail, setVisitorEmail] = useState<string>("");
    const [visitorName, setVisitorName] = useState<string>("");
    const [headCount, setHeadCount] = useState<any>("");
    const [preDefinedRoute, setPreDefinedRoute] = useState<any>(false);
    const [route, setRoute] = useState<any>("");
    const [driverContact, setDriverContact] = useState<string>("");
    const [driverPhotoUrl, setDriverPhotoUrl] = useState<string>("");
    const [driverId, setDriverId] = useState<string>();
    const [tripStartTime] = useState<Date>(new Date());
    const [tripEndTime, setTripEndTime] = useState<Date>(new Date());
    const routesList = useSelector((state: RootState) => state.route.availableRoutesList);
    const [showModal, setShowModal] = useState(false)
    const [regFilter, setRegFilter] = useState<string>()
    const [openRegQr, setOpenRegQr] = useState(false);
    const [openVidQr, setOpenVidQr] = useState(false);
    const [data, setData] = useState("No result");
    const [sourcePlace, setSourcePlace] = useState<any>(null);
    const [destinationPlace, setDestinationPlace] = useState<any>(null);
    const [sourcePlaceName, setSourcePlaceName] = useState<any>(null);
    const [destinationPlaceName, setDestinationPlaceName] = useState<any>(null);
    const [note, setNote] = useState<any>("");
    const [tripDuration, setTripDuration] = useState<any>("");
    const locations = locationState.locationList.locations.filter(
        (i: any) => i.enabled === true
    );
    const [mapCenter, setMapCenter] = useState([51.505, -0.09]);

    const [fare, setFare] = useState<any>(null);
    const onChangeDestinationPoint = (value: any) => {
        setDestinationPoint(value)

    }

    const completeCab = async (tripId: string) => {
        setLoading(true);

        try {
            const response = await addCabTripsComplete(String(tripId));
            if (response.data && response.data.success) {
                setLoading(false);
                showNotification({
                    title: "Success",
                    message: "Cab updated successfully",
                    color: "green",
                });
                dispatch(
                    getCabArrivalsReports(
                        destinationPoint,
                        true
                    )
                );
            }
        } catch (error: any) {
            console.log("error updating cab", error);
            setLoading(false);
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }
    }

    const handleIdChange = async (v: string) => {
        // console.log("e.target.value", e.target.value);
        setHfnId(v);
        try {
            const res = await getVisitorById([v]);
            console.log("res from visitorbyid", res.data);
            if (res.data && res.data.payload.length) {
                setVisitorContact(res.data.payload[0].phone_number);
                setVisitorEmail(res.data.payload[0].email);
                setVisitorName(res.data.payload[0].name)
            } else {
                setVisitorContact("");
                setVisitorEmail("");
            }
        } catch (error: any) {
            setVisitorContact("");
            setVisitorEmail("");
            console.log("error in getting visitor id");
        }
    };

    const handleDriverChange = (v: any) => {
        if (cab?.drivers) {
            var d = cab.drivers.find((e: any) => e.id == v)
            if (d) {
                setDriverContact(d.primary_phone_number);
                setDriverPhotoUrl(d.photo);
                setDriverId(d.id);
            }
        }
    }


    const onValidate = () => {
        if (visitorContact) {
            if (visitorContact.length >= 0) {
                if (visitorContact.startsWith("+91") && visitorContact.length != 13) {

                    showNotification({
                        title: "Error",
                        message: "Invalid Indian Contact number",
                        color: "red",
                    });
                    return false
                } else if (!visitorContact.startsWith("+") && visitorContact.length != 10) {
                    showNotification({
                        title: "Error",
                        message: "Invalid Indian Contact number",
                        color: "red",
                    });
                    return false
                }
            }

        }

        const emailRegex = /\S+@\S+\.\S+/;

        if (visitorEmail && !emailRegex.test(visitorEmail)) {
            showNotification({
                title: "Error",
                message: "Invalid Email",
                color: "red",
            });
            return false
        }

        if (hfnId === "") {
            showNotification({
                title: "Error",
                message: "Visitor ID cannot be empty",
                color: "red",
            });
        } else if (visitorName === "") {
            showNotification({
                title: "Error",
                message: "Visitor name can not be empty",
                color: "red",
            });
        } else if (!headCount || headCount === "") {
            showNotification({
                title: "Error",
                message: "Please enter Passengers headcount",
                color: "red",
            });
        } else if (headCount > 2 * cab!.number_of_seats) {
            showNotification({
                title: "Error",
                message: "Passengers Headcount cannot be more than car seats: " + 2 * cab!.number_of_seats,
                color: "red",
            });
        } else if (preDefinedRoute && route === "") {
            showNotification({
                title: "Error",
                message: "Select A Route",
                color: "red",
            });
        } else if (tripDuration === "") {
            showNotification({
                title: "Error",
                message: "Trip duration cannot be empty",
                color: "red",
            });
        }else if (!preDefinedRoute && sourcePlace == null || sourcePlace == "") {
            showNotification({
                title: "Error",
                message: "Select Source Place",
                color: "red",
            });
        } else if (!preDefinedRoute && destinationPlace == null || destinationPlace == "") {
            showNotification({
                title: "Error",
                message: "Select Destination Place",
                color: "red",
            });
        } else if (fare === "") {
            showNotification({
                title: "Error",
                message: "Please set fare",
                color: "red",
            });
        } else if (driverId === "") {
            showNotification({
                title: "Error",
                message: "Please select the Driver",
                color: "red",
            });
        } else if (driverContact === "") {
            showNotification({
                title: "Error",
                message: "Please enter driver's contact number",
                color: "red",
            });
        } else {
            return true;
        }
    };
    const onSubmit = () => {
        if (onValidate()) {
            _addTrip(0);
        }
    };
    const resetForm = () => {
        setHfnId("");
        setVisitorContact("");
        setVisitorEmail("");
        setVisitorName("");
        setHeadCount("");
        setRoute("");
        setDriverContact("");
        setDriverPhotoUrl("");
    };

    const onChangeRoute = (v: any) => {
        console.log(v)
        setRoute(v)
        var route = routesList.routes?.find((el) => String(el.id) === String(v))
        if (route) {
            setTripEndTime(dayjs(new Date()).add(route.time_estimates_minutes, "minute").toDate())
            setTripDuration(route.time_estimates_minutes)
        }

    }
    const _addTrip = async (id?: any) => {
        setLoading(true)
        const cabTripData: CabTripCreateRequest = {
            cab_id: String(cab?.id),
            driver_id: String(driverId),
            number_of_seats: Number(headCount),
            visitor_name: visitorName,
            visitor_email: visitorEmail,
            visitor_hfn_id: hfnId,
            visitor_phone: visitorContact,
            has_pre_defined_route: preDefinedRoute,
            note: note,
            eta: dayjs(new Date()).add(tripDuration, 'minute').toDate()
        };
        if (preDefinedRoute) {
            cabTripData.route_id = route
        }
        debugger
        if (!preDefinedRoute) {
            cabTripData.source_geo_location = {
                lat: sourcePlace?.geometry?.location.lat(),
                lon: sourcePlace?.geometry?.location.lng(),
                name: sourcePlaceName
            }
            cabTripData.destination_geo_location = {
                lat: destinationPlace?.geometry?.location.lat(),
                lon: destinationPlace?.geometry?.location.lng(),
                name: destinationPlaceName
            }
            cabTripData.fare = Number(fare)
        }
        debugger
        console.log(cabTripData)
        try {
            const res = await addCabTrips(cabTripData);
            if (res.data && res.data.success) {
                setLoading(false)
                console.log("res from cabtrip", res.data);
                showNotification({
                    title: "Success",
                    message: "Trip added successfully",
                    color: "green",
                });
                resetForm();
                setShowModal(false)
                dispatch(
                    getCabArrivalsReports(
                        destinationPoint,
                        true
                    )
                );
            } else {
                setLoading(false)
                console.log("res from cabtrip", res);
                showNotification({
                    title: "Error",
                    message: "Error in adding trip",
                    color: "red",
                });
                dispatch(
                    getCabArrivalsReports(
                        destinationPoint,
                        true
                    )
                );
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
            showNotification({
                title: "Error",
                message: "Error in adding trip",
                color: "red",
            });
        }
    };


    console.log(cabsState.cabArrivalsReport.reports)
    const rows = cabsState.cabArrivalsReport.reports.length ? (
        cabsState.cabArrivalsReport.reports.filter((v) => (v.status === 'READY_FOR_WORK' || v.status === "ONGOING_RIDE")).filter((v) => (regFilter == null || regFilter.trim() === '') || v.registration_number.toUpperCase().startsWith(regFilter.toUpperCase())).map(
            (element: any, x: number) => (
                <tr key={x}>
                    <td style={{
                        backgroundColor: element.commercial ? 'yellow' : '',
                        color: element.commercial ? 'black' : '',
                        fontWeight: element.commercial ? 'bold' : 'normal',
                    }}>
                        <Grid>
                            <Grid.Col mt={3} ml={15}>{element.registration_number}</Grid.Col>
                            <Grid.Col>
                                {element.current_trip && <Button onClick={() => {
                                    completeCab(element.current_trip?.id)
                                }} ml={15}>
                                    Complete Trip
                                </Button>}
                                {!element.current_trip && <Button onClick={() => {
                                    setCab(element);
                                    handleDriverChange(element.current_driver?.id);
                                    setShowModal(true)
                                }} ml={15}>
                                    Allocate
                                </Button>}
                            </Grid.Col>
                        </Grid>
                    </td>
                    <td>{element.location_name}</td>
                    <td>{element.current_trip?.source_geo_location.name}</td>
                    <td>{element.current_trip?.destination_geo_location.name}</td>
                    <td style={{background: `${(element?.status === "ONGOING_RIDE" && dayjs(element.location_eta).add(10, 'minute').isBefore(new Date())) ? "red" : "green"}`}}>{element?.status === "READY_FOR_WORK" && dayjs(element.location_eta).isBefore(new Date()) ? "Arrived" : dayjs(element.location_eta).format("YYYY-MM-DD hh:mm a")}</td>
                    <td>{element.type}</td>
                    <td>{element.status}</td>
                    <td>
                        <Table>
                            <tr>
                                <td>
                                    <Text style={{textAlign: 'left'}}>
                                        Name:&nbsp;&nbsp;&nbsp;{element.current_driver?.first_name}
                                    </Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text style={{textAlign: 'left'}}>
                                        Phone:&nbsp;&nbsp;&nbsp;<a
                                        href={"tel:" + element.current_driver?.primary_phone_number}>{element.current_driver?.primary_phone_number}</a>
                                    </Text>
                                </td>
                            </tr>
                        </Table>
                    </td>
                </tr>
            )
        )
    ) : (
        <tr>
            <td colSpan={5}>
                <NotEnoughData/>
            </td>
        </tr>
    );

    return (
        <>
            <Box py="md">
                <Box>
                    <Heading title="Tracking Vehicle Arrivals"/>
                </Box>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                {loading && <CircularLoader/>}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <Select
                            searchable
                            clearable
                            label="Vehicles arriving to"
                            placeholder="Pick one"
                            defaultValue={destinationPoint}
                            onChange={onChangeDestinationPoint}
                            data={
                                locations.map((i: any) => ({
                                    value: i.id,
                                    label: i.name,
                                }))
                            }
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <Box className={classes.qrSccannerBox}>
                            <TextInput label="Cab Reg Number"
                                       type="text"
                                       value={regFilter!}
                                       onChange={(e) => setRegFilter(e.target.value)}/>
                            <Qrcode size={40} onClick={() => setOpenRegQr(true)} style={{cursor: 'pointer'}}/>
                        </Box>
                        {openRegQr && (
                            <Modal centered opened={openRegQr} onClose={() => setOpenRegQr(false)} title="Scan Qr">
                                <QrReader
                                    onResult={(result: any, error) => {
                                        setData(result?.text)
                                        if (result) {
                                            console.log('result from qr scanner', result)
                                            setRegFilter(result?.text)
                                            setOpenRegQr(false)
                                        }

                                        if (error) {
                                            console.info(error);
                                        }
                                    }}
                                    constraints={{facingMode: "environment"}}
                                    videoStyle={{width: "100%"}}
                                />
                                <p>{data}</p>
                            </Modal>
                        )}
                    </Grid.Col>
                </Grid>
                {cabsState.cabArrivalsReport.loading ? (
                    SkeletonLoader()
                ) : (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            overflow: "auto",
                            marginTop: "24px",
                        }}
                    >
                        <Table highlightOnHover className={classes.table}>
                            <thead>
                            <tr>
                                <th>Vehicle Number</th>
                                <th>Live Location</th>
                                <th>Source</th>
                                <th>Destination</th>
                                <th>Est. Arrival Time</th>
                                <th>Car Type</th>
                                <th>Status</th>
                                <th>Driver</th>
                            </tr>
                            </thead>
                            <tbody>
                            {cabsState.cabArrivalsReport.reports.length ? (
                                rows
                            ) : (
                                <tr>
                                    <td colSpan={11}>
                                        <NotEnoughData/>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <Modal opened={showModal} onClose={() => {
                            setShowModal(false)
                        }}>
                            <Box py="md">
                                <Heading title="Allocation of Cab to visitors"/>
                            </Box>
                            <Paper shadow="xs" p="md">
                                {/* <Text color='red' size="xs" mb={8}>Note: Email or contact # is mandatory</Text> */}
                                <Grid>
                                    <Grid.Col>
                                        <Box className={classes.qrSccannerBox}>
                                            <TextInput
                                                //type="number"
                                                placeholder="Enter Abhyasi ID / Government ID"
                                                label="Enter Abhyasi ID / Government ID"
                                                value={hfnId}
                                                required
                                                onChange={(e) => handleIdChange(e.target.value)}
                                            />
                                            <Qrcode size={40} onClick={() => setOpenVidQr(true)}
                                                    style={{cursor: 'pointer'}}/>
                                        </Box>

                                        {openVidQr && (
                                            <Modal centered opened={openVidQr} onClose={() => setOpenVidQr(false)}
                                                   title="Scan Qr">
                                                <QrReader
                                                    onResult={(result: any, error) => {
                                                        setData(result?.text)
                                                        if (result) {
                                                            console.log('result from qr scanner', result)
                                                            setOpenVidQr(false)
                                                            handleIdChange(result?.text)
                                                        }

                                                        if (error) {
                                                            console.info(error);
                                                        }
                                                    }}
                                                    constraints={{facingMode: "environment"}}
                                                    videoStyle={{width: "100%"}}
                                                />
                                                <p>{data}</p>
                                            </Modal>
                                        )}
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            placeholder="+910000000000"
                                            label="Visitor contact #"
                                            type="tel"
                                            value={visitorContact}
                                            onChange={(e) => {
                                                const phoneRegex = /^[\+]?[0-9]*$/;
                                                if (e.target.value.length >= 0 && phoneRegex.test(e.target.value)) {
                                                    if (e.target.value.startsWith("+")) {
                                                        if (e.target.value.startsWith("+91")) {
                                                            e.target.value.length <= 13 && setVisitorContact(e.target.value)
                                                        } else {
                                                            e.target.value.length <= 20 && setVisitorContact(e.target.value)
                                                        }
                                                    } else {
                                                        e.target.value.length <= 10 && setVisitorContact(e.target.value)
                                                    }
                                                }

                                            }
                                            }
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            placeholder="Visitor email"
                                            label="Visitor email"
                                            type="email"
                                            value={visitorEmail}
                                            onChange={(e) => setVisitorEmail(e.target.value)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            placeholder=" Visitor Name"
                                            label="Visitor Name "
                                            required
                                            value={visitorName}
                                            onChange={(e) => setVisitorName(e.target.value)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            readOnly
                                            label="Cab"
                                            value={cab?.registration_number}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Select
                                            label="Driver Name"
                                            placeholder={"Pick one"}
                                            required
                                            data={cab ? cab.drivers?.filter((e: any) => e.enabled).map((i: any) => ({
                                                value: String(i.id),
                                                label: `${i.first_name} ${i.last_name}`,
                                            }))! : []}
                                            value={String(driverId)}
                                            onChange={(value: any) => handleDriverChange(value)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            placeholder="Passengers"
                                            label="Passengers"
                                            required
                                            type={"number"}
                                            value={headCount}
                                            onChange={(e) => setHeadCount(Number(e.target.value))}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Checkbox
                                            label="Pre Defined Routes"
                                            required
                                            value={preDefinedRoute}
                                            onChange={(e) => setPreDefinedRoute(!preDefinedRoute)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col style={{display: preDefinedRoute ? 'block' : 'none'}}>
                                        <Select
                                            placeholder="Pick one"
                                            label="Route"
                                            required
                                            searchable
                                            value={route}
                                            defaultValue={""}
                                            onChange={onChangeRoute}
                                            data={
                                                routesList.routes.length
                                                    ? routesList.routes?.filter((i: any) => i.source.id === cab?.destination?.id && i.mode === "CAB").map((i: any) => ({
                                                        value: String(i.id),
                                                        label: i.source.name + " ===>> " + i.destination.name,
                                                    }))
                                                    : []
                                            }
                                        />
                                    </Grid.Col>
                                    <Grid.Col style={{display: !preDefinedRoute ? 'block' : 'none'}}>
                                        <ReactGoogleAutocomplete style={{width: "100%", height: "40px"}}
                                                                 apiKey={GMAPIK}
                                                                 onError={event => console.log(event)}
                                                                 placeholder={"Enter Source Location"}
                                                                 options={{fields: ["address_components", "geometry", "icon", "name"],types: ['establishment',], componentRestrictions:{ country: "in" }}}
                                                                 onPlaceSelected={(places, ref: any) => {setSourcePlace(places); setSourcePlaceName(ref.value) }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col style={{display: !preDefinedRoute ? 'block' : 'none'}}>
                                        <ReactGoogleAutocomplete style={{width: "100%", height: "40px"}}
                                                                 onError={event => console.log(event)}
                                                                 apiKey={GMAPIK}
                                                                 placeholder={"Enter Destination Location"}
                                                                 options={{fields: ["address_components", "geometry", "icon", "name"],types: ['establishment',], componentRestrictions:{ country: "in" }}}
                                                                 onPlaceSelected={(places, ref: any) => {debugger;setDestinationPlace(places); setDestinationPlaceName(ref.value) }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Textarea placeholder={"Notes"} onChange={event => setNote(event.target.value)}>
                                        </Textarea>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <TextInput readOnly={preDefinedRoute} label="Trip Duration in Minutes" type={"number"} value={tripDuration} onChange={event => {setTripDuration(event.target.value)}}/>
                                    </Grid.Col>

                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <TextInput label="Fare"
                                                   type={"number"}
                                                   value={preDefinedRoute ? routesList.routes.find((v) => v.id == route)?.fare : fare}
                                                   disabled={preDefinedRoute}
                                                   onChange={event => (setFare(event.target.value))}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <TimeInput label="Trip Start time" value={tripStartTime} disabled/>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <TimeInput label="Trip end time" value={tripEndTime} disabled/>
                                    </Grid.Col>

                                    <Grid.Col>
                                        <Text weight={500} mb={8} size="sm">
                                            Driver Photo
                                        </Text>
                                        <Box sx={{width: "100%", height: "250px"}}>
                                            {driverPhotoUrl ? (
                                                <Box
                                                    key={driverPhotoUrl}
                                                    sx={{width: "100%", height: "250px"}}
                                                >
                                                    <ImagePreview src={driverPhotoUrl} closeIcon={false}/>
                                                </Box>
                                            ) : null}
                                        </Box>
                                    </Grid.Col>
                                </Grid>
                                <Divider mt={16}/>
                                <Grid justify="flex-end" pt={24} pb={16} pr={8}>
                                    <Button mr={8} onClick={() => {
                                        resetForm();
                                        setShowModal(false)
                                    }}>
                                        Discard
                                    </Button>
                                    <Button onClick={onSubmit}>Allocate</Button>
                                </Grid>
                                {loading && <CircularLoader/>}
                            </Paper>
                        </Modal>
                    </div>
                )}
            </Paper>
        </>
    );
};

export default TrackingVehiclesArrivalsScreen;
