import {Box} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./ImagePreview.styles";
import {X} from "tabler-icons-react";
import {downloadImage} from "../../services/fileUploadServices";

const ImagePreview = ({src, onCloseImage, closeIcon, type, index}: any) => {
    const {classes} = useStyles();
    const [imgSrc, setImgSrc] = useState(src);
    // console.log('src',src)
    useEffect(() => {
        onDownloadImage();
    }, []);
    const onDownloadImage = async () => {
        const res = await downloadImage(src);
        //console.log("res from downloadImage", res);
        if (res) {
            setImgSrc(res[src]);
        } else {
            setImgSrc("");
        }
    };
    return (
        <>
            <Box className={classes.imageContainer}>
                <img src={imgSrc} className={classes.image}/>
                {closeIcon === false ? null : (
                    <Box
                        className={classes.iconBox}
                        onClick={() =>
                            type === "multiple" ? onCloseImage(index) : onCloseImage()
                        }
                    >
                        <X size={20}/>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default ImagePreview;
