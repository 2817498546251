import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Select,
    Switch,
    Table,
    Text,
    Textarea,
    TextInput,
} from "@mantine/core";
import Heading from "../../../components/heading/Heading";
import {useStyles} from "./DriverRegistration.styles";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store/store";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import React, {useEffect, useState} from "react";
import {downloadImage} from "../../../services/fileUploadServices";
import CircularLoader from "../../../components/loader/CircularLoader";

const DriverRegistrationScreen = ({
                                      onClickAddNew,
                                      onClickEditBtn,
                                      onChangeVehicleeNo,
                                      vehicleNo,
                                      onDownloadImage,
                                      isLoading
                                  }: any) => {
    const {classes} = useStyles();
    const state = useSelector((state: RootState) => state.cabs);
    const driversLists = state.vehiclesList.vehicles.length
        ? state.vehiclesList.vehicles.filter(
            (i: any) => i.registration_number === vehicleNo
        )
        : [];

    const rows = (driversLists: any) => (
        <>
            {driversLists &&
                driversLists[0].drivers.map((ele: any, x: number) => (
                    <tr key={x}>
                        <td>
                            <Avatar
                                color="cyan"
                                radius="xl"
                                sx={{cursor: 'pointer'}}
                                src={ele.photo}
                                alt={ele.first_name}
                                onClick={() => onDownloadImage(ele.photo)}
                            />
                        </td>
                        <td>
                            {ele.first_name} {ele.last_name}
                        </td>
                        <td>{ele.license_number}</td>
                        <td>{ele.aadhar_number}</td>
                        <td>{ele.primary_phone_number}</td>
                        <td>{ele.secondary_phone_number}</td>
                        <td style={{background: `${ele.enabled?"green":"red"}`}}>{ele.enabled?"Enabled":"Disabled"}</td>
                        <td>{ele.rating.toFixed(1)}</td>
                        <td>
                            <Button onClick={() => onClickEditBtn(ele)}>Edit</Button>
                        </td>
                    </tr>
                ))}
        </>
    );
    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Heading title="Driver Registration"/>
                <Button ml={16} onClick={onClickAddNew}>
                    Add New
                </Button>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                {isLoading && <CircularLoader/>}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <Select
                            searchable
                            label="Vehicle No"
                            placeholder="Pick one"
                            value={vehicleNo}
                            onChange={onChangeVehicleeNo}
                            data={state.vehiclesList.vehicles.map((i: any) => ({
                                value: i.registration_number,
                                label: i.registration_number,
                            }))}
                        />
                    </Grid.Col>
                </Grid>
                <div style={{overflowX: "auto", height: '100%', marginTop: '24px'}}>
                    <Table highlightOnHover className={classes.table}>
                        <thead>
                        <tr>
                            <th>Driver Photo</th>
                            <th>Driver Name</th>
                            <th>License No</th>
                            <th>Aadhaar No</th>
                            <th>Primary Phone No</th>
                            <th>Secondary Phone No</th>
                            <th>Status</th>
                            <th>Ratings</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {driversLists.length ? (
                            driversLists[0].drivers?.length ? (
                                rows(driversLists)
                            ) : (
                                <tr>
                                    <td colSpan={8}>
                                        <NotEnoughData/>
                                    </td>
                                </tr>
                            )
                        ) : (
                            <tr>
                                <td colSpan={8}>
                                    <NotEnoughData/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
            </Paper>
        </>
    );
};

export default DriverRegistrationScreen;
