import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Route} from "../../@types";

export interface RouteList {
    routesList: {
        loading: boolean,
        routes: Route[]
    },
    availableRoutesList: {
        loading: boolean,
        routes: Route[]
    }
}

const initialState: RouteList = {
    routesList: {
        loading: false,
        routes: []
    },
    availableRoutesList: {
        loading: false,
        routes: []
    },
};
const routeStateSlice = createSlice({
    name: "routeList",
    initialState,
    reducers: {
        setRouteListLoading: (state, action: PayloadAction<boolean>) => {
            state.routesList.loading = action.payload;
        },
        setAvailableRouteListLoading: (state, action: PayloadAction<boolean>) => {
            state.availableRoutesList.loading = action.payload;
        },
        setRoutesList: (state,action: PayloadAction<Route[]>) => {
            state.routesList.routes = action.payload;
            state.routesList.loading = false
        },
        setAvailableRoutesList: (state,action: PayloadAction<Route[]>) => {
            state.availableRoutesList.routes = action.payload;
            state.availableRoutesList.loading = false;
        }
    },
});

export const {
    setRoutesList,
    setAvailableRoutesList,
    setAvailableRouteListLoading,
    setRouteListLoading
} = routeStateSlice.actions;

export default routeStateSlice.reducer;
