import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    mainBox: {
        paddingTop: theme.spacing.sm,
        borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
        padding: theme.spacing.xs,
        backgroundColor: theme.colors[theme.primaryColor][0],
        borderRadius: theme.radius.md,
    },
    userProfile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
  