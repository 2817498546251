import {showNotification} from "@mantine/notifications";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// import { CabListPayload } from "../../../@types";
import {getAllCabs} from "../../../redux/actions/cab.action";
import {Cab, EditCab, RegisterCab} from "../../../@types";
import {addCab, updateCab} from "../../../api/api";
import {RootState} from "../../../redux/store/store";
import {cabRegistrationScreen} from "../../../shared/constants";
import AddNewCab from "./AddNewCab";
import CabRegistrationScreen from "./CabRegistrationScreen";
import {
    downloadImage,
    uploadFile,
} from "../../../services/fileUploadServices";

const CabRegistration = () => {
    const [currentScreen, setCurrentScreen] = useState(
        cabRegistrationScreen.ALL_CABS_SCREEN
    );

    const [vehicleNo, setVehicleNo] = useState("");
    const [carType, setCarType] = useState("");
    const [carArea, setCarArea] = useState("");
    const [cabStatus, setCabStatus] = useState("");
    const [commercial, setCabCommercial] = useState("");
    const [noOfSeats, setNoOfSeats] = useState("4");
    const [rating, setRating] = useState("5");
    const [carImageSrc, setCarImageSrc] = useState<any>(null);
    const [rcImageSrc, setRcImageSrc] = useState<any>(null);
    const [pollutionImageSrc, setPollutionImageSrc] = useState<any>(null);
    const [taxiPermitImageSrc, setTaxiPermitImageSrc] = useState<any>(null);
    const [rcImageUrl, setRcImageUrl] = useState<any>("");
    const [pollutionImageUrl, setPollutionImageUrl] = useState<any>("");
    const [taxiPermitImageUrl, setTaxiPermitImageUrl] = useState<any>("");
    const [editCab, setEditCab] = useState<any>({
        isEditing: false,
        editItem: null,
    });
    const [enabled, setEnabled] = useState<string>("true");
    const [loading, setLoading] = useState({
        submit: false,
        rcImg: false,
        pollutionImg: false,
        taxiPermitImg: false,
        carImg: false,
    });
    const [imgArr, setImgArr] = useState<any>([{imgSrc: "", imgUrl: ''}]);
    const [currentIndex, setcurrentIndex] = useState(0);

    const hiddenCarImageInputRef: any = React.useRef(null);
    const hiddenRcImageInputRef: any = React.useRef(null);
    const hiddenPollutionImageInputRef: any = React.useRef(null);
    const hiddenTaxiPermitImageInputRef: any = React.useRef(null);
    const hiddenImageInput: any = React.useRef([]);
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.cabs.vehiclesList);
    const isLoading = useSelector((state: RootState) => state.cabs.vehiclesList.loading);
    useEffect(() => {
        dispatch(getAllCabs());
        console.log("cab list", state);

    }, []);

    const onClickAddNew = () => {
        onDiscard();
        setEditCab({isEditing: false});
        setCurrentScreen(cabRegistrationScreen.ADD_CAB_SCREEN);
        setEnabled("true")
        setCabCommercial("true")
        setCabStatus("OFF_FROM_WORK")
    };
    const onClickBack = () => {
        onDiscard();
        setCurrentScreen(cabRegistrationScreen.ALL_CABS_SCREEN);
    };
    const onClickEditBtn = (editCabData: Cab) => {
        setEditCab({isEditing: true, editItem: editCabData});
        setCurrentScreen(cabRegistrationScreen.ADD_CAB_SCREEN);
    };
    const onChangeVehicleNo = (e: any) => {
        setVehicleNo(e.target.value);
    };
    const onChangeCarType = (value: any) => {
        setCarType(value);
    };
    const onChangeCarArea = (e: any) => {
        setCarArea(e.target.value);
    };
    const onChangeNoOfSeat = (e: any) => {
        setNoOfSeats(e.target.value);
    };
    const onChangeCabStatus = (value: any) => {
        setCabStatus(value);
    };

    const handleUploadCarImgClick = () => {
        hiddenCarImageInputRef.current.click();
    };
    const handleUploadRcImgClick = () => {
        hiddenRcImageInputRef.current.click();
    };
    const handleUploadPollutionImgClick = () => {
        hiddenPollutionImageInputRef.current.click();
    };
    const handleUploadTaxiPermitImgClick = () => {
        hiddenTaxiPermitImageInputRef.current.click();
    };

    const handleImageClick = (idx: any) => {
        hiddenImageInput.current[idx].click();
    };

    const onClickAdd = () => {
        setImgArr([...imgArr, {imgSrc: "", imgUrl: ''}]);
    };
    const onClickDelete = (i: any) => {
        let newImagarr = [...imgArr];
        newImagarr.splice(i, 1);
        setImgArr(newImagarr);
    };
    const onCloseImage = (index: number) => {
        setCarImageSrc('')
        let newImagarr = [...imgArr];
        newImagarr[index].imgSrc = "";
        newImagarr[index].imgUrl = "";
        setImgArr(newImagarr);
    };
    const onSelectImage = async (event: any, index: number) => {
        setcurrentIndex(index);
        // console.log("e.target.files", event.target.files);
        if (event.target.files[0]) {
            setLoading({...loading, carImg: true});
            const res = await uploadFile(event.target.files[0]);
            console.log("res from upload image", res);
            if (res) {
                setLoading({...loading, carImg: false});
                setCarImageSrc(res);
                let newImgArr = [...imgArr];
                newImgArr[index].imgUrl = res;
                newImgArr[index].imgSrc = res;
                setImgArr(newImgArr);

            } else {
                setLoading({...loading, carImg: false});
                showNotification({
                    title: "Error",
                    message: "Error in uploading car photo",
                    color: "red",
                });
            }
        }
    };

    const onDownloadImage = async (url: string) => {
        setLoading({...loading, rcImg: true, pollutionImg: true, taxiPermitImg: true, carImg: true});
        const res = await downloadImage(url);
        if (res) {
            setLoading({...loading, rcImg: false, pollutionImg: false, taxiPermitImg: false, carImg: false});
            //window.location.href = `${res[url]}`;
            window.open(`${res[url]}`, '_blank');
        } else {
            setLoading({...loading, rcImg: false, pollutionImg: false, taxiPermitImg: false, carImg: false});
        }
    };

    const onChangeEnabled = (e: any) => {
        setEnabled(e);
    };
    const onChangeCommercial = (e: any) => {
        setCabCommercial(e);
    };



    const onSelectRcImage = async (event: any) => {
        if (event.target.files[0]) {
            setLoading({...loading, rcImg: true});

            const res = await uploadFile(event.target.files[0]);
            console.log("res from upload image", res);
            if (res) {
                setLoading({...loading, rcImg: false});
                setRcImageUrl(res);
                setRcImageSrc(res);
            } else {
                setLoading({...loading, rcImg: false});
                showNotification({
                    title: "Error",
                    message: "Error in uploading Rc photo",
                    color: "red",
                });
            }
        }
    };

    const onSelectPollutionImage = async (event: any) => {
        if (event.target.files[0]) {
            setLoading({...loading, pollutionImg: true});

            const res = await uploadFile(event.target.files[0]);
            console.log("res from upload image", res);
            if (res) {
                setLoading({...loading, pollutionImg: false});
                setPollutionImageUrl(res);
                setPollutionImageSrc(res);
            } else {
                setLoading({...loading, pollutionImg: false});
                showNotification({
                    title: "Error",
                    message: "Error in uploading pollution photo",
                    color: "red",
                });
            }
        }
    };
    const onSelectTaxiPermitImage = async (event: any) => {
        if (event.target.files[0]) {
            setLoading({...loading, taxiPermitImg: true});

            const res = await uploadFile(event.target.files[0]);
            console.log("res from upload image", res);
            if (res) {
                setLoading({...loading, taxiPermitImg: false});
                setTaxiPermitImageUrl(res);
                setTaxiPermitImageSrc(res);
            } else {
                setLoading({...loading, taxiPermitImg: false});
                showNotification({
                    title: "Error",
                    message: "Error in uploading taxi permit photo",
                    color: "red",
                });
            }
        }
    };

    const onCloseRcImage = () => {
        setRcImageSrc(null);
    };
    const onClosePollutionImage = () => {
        setPollutionImageSrc(null);
    };
    const onCloseTaxiPermitImage = () => {
        setTaxiPermitImageSrc(null);
    };

    const onOpenEditCab = (cabDetails: Cab) => {
        setVehicleNo(cabDetails.registration_number);
        setCarType(cabDetails.type);
        setCarArea(cabDetails.area.toString());
        setCabStatus(cabDetails.status);
        setCarImageSrc(cabDetails.pictures?cabDetails.pictures[0]:"");
        setRcImageSrc(cabDetails.rc_photo);
        setPollutionImageSrc(cabDetails.pollution_certificate_photo);
        setTaxiPermitImageSrc(cabDetails.taxi_permit_photo);
        setRcImageUrl(cabDetails.rc_photo);
        setPollutionImageUrl(cabDetails.pollution_certificate_photo);
        setTaxiPermitImageUrl(cabDetails.taxi_permit_photo);
        setImgArr(cabDetails.pictures?.map((i: any) => ({imgSrc: i, imgUrl: i})));
        setNoOfSeats(cabDetails.number_of_seats.toString())
        // alert(cabDetails.enabled)
        setEnabled(cabDetails.enabled)
        setCabCommercial(cabDetails.commercial)
    };
    const onValidate = () => {
        if (vehicleNo === "") {
            showNotification({
                title: "Error",
                message: "Vehicle no can not be empty",
                color: "red",
            });
        } else if (carType === "") {
            showNotification({
                title: "Error",
                message: "Car type can not be empty",
                color: "red",
            });
        } else if (carArea === "") {
            showNotification({
                title: "Error",
                message: "car area can not be empty",
                color: "red",
            });
        } else if (cabStatus === "") {
            showNotification({
                title: "Error",
                message: "status can not be empty",
                color: "red",
            });
        } else if (rcImageSrc === null) {
            showNotification({
                title: "Error",
                message: "Rc photo can not be empty",
                color: "red",
            });
        } else if (pollutionImageSrc === null) {
            showNotification({
                title: "Error",
                message: "pollution picture can not be empty",
                color: "red",
            });
        } else if (taxiPermitImageSrc === null) {
            showNotification({
                title: "Error",
                message: "Taxi permit picture can not be empty",
                color: "red",
            });
        } else if (carImageSrc === null || carImageSrc === '' || imgArr.some((i: any) => i.imgUrl === '')) {
            showNotification({
                title: "Error",
                message: "Car pictures can not be empty",
                color: "red",
            });
        } else {
            return true;
        }
    };
    const onSubmit = async () => {
        if (onValidate()) {
            setLoading({...loading, submit: true});
            const data: RegisterCab = {
                registration_number: vehicleNo,
                type: carType,
                pictures: imgArr.map((i: any) => i.imgUrl),
                //pictures: [carImageUrl],
                rc_photo: rcImageUrl,
                pollution_certificate_photo: pollutionImageUrl,
                taxi_permit_photo: taxiPermitImageUrl,
                area: carArea,
                rating: Number(rating),
                status: cabStatus,
                driver_ids: [],
                enabled: enabled,
                commercial: commercial,
                number_of_seats: noOfSeats === '' ? 4 : Number(noOfSeats)
            };
            console.log("payload for cab reg.", data);
            //console.log('pictures',imgArr.some((i:any)=>i.imgUrl===''))
            try {
                const response = await addCab(data);
                if (response.data && response.data.success) {
                    setLoading({...loading, submit: false});
                    showNotification({
                        title: "Success",
                        message: "Cab added successfully",
                        color: "green",
                    });
                    dispatch(getAllCabs());
                    setTimeout(() => onClickBack(), 1000);
                }
            } catch (error: any) {
                console.log("error adding cab", error);
                setLoading({...loading, submit: false});
                showNotification({
                    title: "Error",
                    message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                    color: "red",
                });
            }
        }
    };
    const onUpdate = async () => {
        if (onValidate()) {
            setLoading({...loading, submit: true});
            const data: EditCab = {
                registration_number: vehicleNo,
                type: carType,
                pictures: imgArr.map((i: any) => i.imgUrl),
                rc_photo: rcImageUrl,
                pollution_certificate_photo: pollutionImageUrl,
                taxi_permit_photo: taxiPermitImageUrl,
                area: carArea,
                rating: Number(rating),
                status: cabStatus,
                enabled: enabled,
                commercial: commercial,
                number_of_seats: noOfSeats === '' ? 4 : Number(noOfSeats)
                // driver_ids: [],
            };
            try {
                const response = await updateCab(editCab.editItem.id, data);
                if (response.data && response.data.success) {
                    setLoading({...loading, submit: false});
                    showNotification({
                        title: "Success",
                        message: "Cab updated successfully",
                        color: "green",
                    });
                    dispatch(getAllCabs());
                    setTimeout(() => onClickBack(), 1000);
                }
            } catch (error: any) {
                console.log("error updating cab", error);
                setLoading({...loading, submit: false});
                showNotification({
                    title: "Error",
                    message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                    color: "red",
                });
            }
        }
    };

    const onDiscard = () => {
        setVehicleNo("");
        setCarType("");
        setCarArea("");
        setCabStatus("");
        setCarImageSrc(null);
        setRcImageSrc(null);
        setPollutionImageSrc(null);
        setTaxiPermitImageSrc(null);
        setNoOfSeats("");
        setRcImageUrl("");
        setPollutionImageUrl("");
        setTaxiPermitImageUrl("");
        setImgArr([{imgSrc: "", imgUrl: ''}])
        setCabCommercial("");
    };
    return (

        <>
            {currentScreen === cabRegistrationScreen.ALL_CABS_SCREEN && (
                <CabRegistrationScreen
                    onClickAddNew={onClickAddNew}
                    onClickEditBtn={onClickEditBtn}
                    cabList={state}
                    loading={isLoading}
                    onDownloadImage={onDownloadImage}
                />
            )}
            {currentScreen === cabRegistrationScreen.ADD_CAB_SCREEN && (
                <AddNewCab
                    vehicleNo={vehicleNo}
                    carType={carType}
                    carArea={carArea}
                    cabStatus={cabStatus}

                    hiddenRcImageInputRef={hiddenRcImageInputRef}
                    hiddenPollutionImageInputRef={hiddenPollutionImageInputRef}
                    hiddenTaxiPermitImageInputRef={hiddenTaxiPermitImageInputRef}
                    rcImageSrc={rcImageSrc}
                    pollutionImageSrc={pollutionImageSrc}
                    taxiPermitImageSrc={taxiPermitImageSrc}
                    handleUploadCarImgClick={handleUploadCarImgClick}
                    handleUploadRcImgClick={handleUploadRcImgClick}
                    handleUploadPollutionImgClick={handleUploadPollutionImgClick}
                    handleUploadTaxiPermitImgClick={handleUploadTaxiPermitImgClick}

                    onSelectRcImage={onSelectRcImage}
                    onSelectPollutionImage={onSelectPollutionImage}
                    onSelectTaxiPermitImage={onSelectTaxiPermitImage}

                    onCloseRcImage={onCloseRcImage}
                    onClosePollutionImage={onClosePollutionImage}
                    onCloseTaxiPermitImage={onCloseTaxiPermitImage}
                    onClickBack={onClickBack}
                    editCab={editCab}
                    onOpenEditCab={onOpenEditCab}
                    onChangeVehicleNo={onChangeVehicleNo}
                    onChangeCarType={onChangeCarType}
                    onChangeCarArea={onChangeCarArea}
                    onChangeCabStatus={onChangeCabStatus}
                    onDiscard={onDiscard}
                    onSubmit={onSubmit}
                    onUpdate={onUpdate}
                    noOfSeats={noOfSeats}
                    onChangeNoOfSeat={onChangeNoOfSeat}
                    loading={loading}
                    hiddenImageInput={hiddenImageInput}
                    handleImageClick={handleImageClick}
                    onSelectImage={onSelectImage}
                    imgArr={imgArr}
                    onClickAdd={onClickAdd}
                    onClickDelete={onClickDelete}
                    onCloseImage={onCloseImage}
                    enabled={enabled}
                    onChangeEnabled={onChangeEnabled}
                    commercial={commercial}
                    onChangeCommercial={onChangeCommercial}
                />
            )}
        </>
    );
};

export default CabRegistration;
