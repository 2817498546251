import {
    Box,
    Button,
    Divider,
    Grid,
    Paper, Select,
    Text,
    Textarea,
    TextInput,
} from "@mantine/core";
import Heading from "../../../components/heading/Heading";
import ImagePreview from "../../../components/imagePreview/ImagePreview";
import {ArrowLeft, FileUpload} from "tabler-icons-react";
import {useStyles} from "./DriverRegistration.styles";
import React, {useEffect} from "react";
import CircularLoader from "../../../components/loader/CircularLoader";

const AddNewDriver = ({
                          vehicleNo,
                          driverFirstName,
                          driverLastName,
                          licenseNo,
                          aadharNo,
                          primaryMobileNo,
                          secondaryMobileNo,
                          rating,
                          hiddenLicenseImageInputRef,
                          hiddenDriverImageInputRef,
                          licenseImageSrc,
                          driverImageSrc,
                          handleUploadCarImgClick,
                          handleUploadRcImgClick,
                          onSelectLicenseImage,
                          onSelectDriverImage,
                          onCloseLicenseImage,
                          onCloseDriverImage,
                          onClickBack,
                          editDriver,
                          onOpeneditDriver,
                          onChangeDriverFirstName,
                          onChangeDriverLastName,
                          onChangeLicenseNo,
                          onChangeAadharNo,
                          onChangePrimaryMobileNo,
                          onChangeSecondaryMobileNo,
                          onChangeRating,
                          onDiscard,
                          onSubmit,
                          onUpdate,
                          isLoading,
                          onChangeComments,
                          comments,
                          enabled,
                          onChangeEnabled,
                      }: any) => {
    const {classes} = useStyles();
    useEffect(() => {
        if (editDriver.isEditing) {
            onOpeneditDriver();
        }
    }, [editDriver.isEditing]);
    return (
        <>
            {/* {isLoading && <CircularLoader/>} */}
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Box
                    mr={16}
                    onClick={onClickBack}
                    sx={{cursor: "pointer", display: "flex", alignItems: "center"}}
                >
                    <ArrowLeft/>
                </Box>
                <Heading
                    title={editDriver.isEditing ? "Edit Driver" : "Add New Driver"}
                />
            </Box>

            <Paper shadow="xs" p="md">
                {/* {isLoading && <CircularLoader/>} */}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Vehicle No"
                            label="Vehicle No"
                            value={vehicleNo}
                            onChange={() => null}
                            disabled
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Driver First Name "
                            label="Driver First Name "
                            required
                            value={driverFirstName}
                            onChange={onChangeDriverFirstName}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Driver Last Name "
                            label="Driver Last Name "
                            required
                            value={driverLastName}
                            onChange={onChangeDriverLastName}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="License Number"
                            label="License Number"
                            required
                            value={licenseNo}
                            onChange={onChangeLicenseNo}
                        />
                    </Grid.Col>

                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Aadhaar Number"
                            label="Aadhaar Number "
                            required
                            value={aadharNo}
                            onChange={onChangeAadharNo}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Driver Phone Primary "
                            label="Driver Phone Primary "
                            required
                            value={primaryMobileNo}
                            onChange={onChangePrimaryMobileNo}
                            type="number"
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Driver Phone Secondary"
                            label="Driver Phone Secondary"
                            value={secondaryMobileNo}
                            onChange={onChangeSecondaryMobileNo}
                            type="number"
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Rating"
                            label="Rating"
                            value={rating.toFixed(1)}
                            onChange={onChangeRating}
                            type="number"
                            readOnly
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>

                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Select
                            label="Status"
                            placeholder="Pick one"
                            required
                            value={String(enabled)}
                            defaultValue={"true"}
                            onChange={onChangeEnabled}
                            data={[{ value: "true", label: "Enabled"},{ value: "false", label: "Disabled"}]}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}/>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Text weight={500} mb={8} size="sm">
                            Upload License Photo
                        </Text>
                        <Box className={classes.thumbnail} style={{position: 'relative'}}>
                            {isLoading.licenseImg && <CircularLoader/>}
                            {licenseImageSrc ? (
                                <ImagePreview src={licenseImageSrc} onCloseImage={onCloseLicenseImage}/>
                            ) : (
                                <Box
                                    onClick={handleUploadCarImgClick}
                                    className={classes.uploadImageBox}
                                >
                                    <FileUpload size={48} color="#444"/>
                                    <input
                                        accept="image/*"
                                        id="audioFile"
                                        type="file"
                                        hidden
                                        ref={hiddenLicenseImageInputRef}
                                        onChange={onSelectLicenseImage}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Text weight={500} mb={8} size="sm">
                            Upload Driver Photo
                            <Text component="span" color="red"> *</Text>
                        </Text>
                        <Box className={classes.thumbnail} style={{position: 'relative'}}>
                            {isLoading.driverImg && <CircularLoader/>}
                            {driverImageSrc ? (
                                <ImagePreview src={driverImageSrc} onCloseImage={onCloseDriverImage}/>
                            ) : (
                                <Box
                                    onClick={handleUploadRcImgClick}
                                    className={classes.uploadImageBox}
                                >
                                    <FileUpload size={48} color="#444"/>
                                    <input
                                        accept="image/*"
                                        id="audioFile"
                                        type="file"
                                        hidden
                                        ref={hiddenDriverImageInputRef}
                                        onChange={onSelectDriverImage}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid.Col>
                </Grid>
                <Divider mt={16}/>
                <Grid justify="flex-end" pt={24} pb={16} pr={8}>
                    <Button mr={8} onClick={onDiscard}>Discard</Button>
                    <Button onClick={editDriver.isEditing ? onUpdate : onSubmit}>
                        {isLoading.submit ? <CircularLoader size='sm' color='#fff'/> :
                            ' Save Details'}
                    </Button>
                </Grid>
            </Paper>
        </>
    );
};

export default AddNewDriver;
