import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    table: {
        "& tr": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
        },
        "& td": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
        },
        "& th": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
            //   position: "sticky",
            //   top: "0",
            //   zIndex: 1,
            //   background: " #fff",
        },
        // "& thead": {
        //   position: "fixed",
        //   //top:'0'
        // },
    },
    status: {
        width: "150px",
        [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
            width: "250px",
        },
    },
    name: {
        width: "150px",
        [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
            width: "250px",
        },
    },
    time: {
        [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
            width: "100px",
        },
    },
    number: {
        textDecoration: 'none',
        //color:'inherit'
    },
    qrSccannerBox: {
        width: '100%',
        display: 'flex',
        alignItems: 'end',
        //border:'1px solid black'
    }
}));
