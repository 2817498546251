import {getAllConfig} from "../../api/api";
import {setLoadingConfigsList, setConfigLists} from "../reducers/config.reducer";
import {AppThunk} from "../store/store";

export const getConfigs = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingConfigsList(true));
        const res = await getAllConfig();
        console.log('get config response ', res.data)
        if (res && res.data.success) {
            dispatch(setConfigLists(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingConfigsList(false));
    }
};