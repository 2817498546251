import {getUserList, getVisitosEstimationReport} from "../../api/api";
import {
    setLoadingVisitorEstimationReports,
    setUserList, setUserListLoading,
    setVisitorEstimationReports,
} from "../reducers/user.reducer";
import {AppThunk} from "../store/store";

export const getAllUsers = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setUserListLoading(true));
        const res = await getUserList();
        console.log('get users response', res.data)
        if (res.data && res.data.success) {
            dispatch(setUserList(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setUserListLoading(false));
    }
};


export const getVisitorEstimationReports = (start: any, end: any, destination?: string): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingVisitorEstimationReports(true));
        const res = await getVisitosEstimationReport(start, end, destination==null?"":destination);
        console.log('get visitor estimation response', res.data)
        if (res.data && res.data.success) {
            dispatch(setVisitorEstimationReports(res.data.payload.report))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingVisitorEstimationReports(false));
    }
};