import React, {useState} from "react";
import {Drawer, Grid, Text} from "@mantine/core";
import {navItems, adminNavItems} from "./navItems";
import {NavLink} from "react-router-dom";
import {useStyles} from "./Nav.styles";
import {Menu2} from "tabler-icons-react";
import {LoginResponse} from "../../@types";

const Nav = ({view}: any) => {
    const {classes, cx} = useStyles();
    const data = localStorage.getItem("user");
    const user = data ? JSON.parse(data) : null;
    const [opened, setOpened] = useState(false);
    return (
        <React.Fragment>
            {view === "mobile" ? (
                <Grid className={classes.mobileLinkIContainer}>
                    {(user && user.roles && user.roles.includes('ADMIN') ? adminNavItems : [])
                        .slice(0, 3)
                        .map((item: any, x: number) => (
                            <NavLink
                                to={item.href}
                                className={({isActive}) =>
                                    cx(classes.link, {
                                        [classes.linkActive]: isActive,
                                    })
                                }
                                key={item.title}
                            >
                                <div className={classes.mobileLinkItem}>
                                    <item.icon className={classes.mobileLinkIcon} size={20}/>
                                    <Text size="xs">{item.mobileViewTitle}</Text>
                                </div>
                            </NavLink>
                        ))}

                    {(user && user.roles && user.roles.includes('VOLUNTEER') ? navItems : [])
                        .slice(0, 3)
                        .map((item: any, x: number) => (
                            <NavLink
                                to={item.href}
                                className={({isActive}) =>
                                    cx(classes.link, {
                                        [classes.linkActive]: isActive,
                                    })
                                }
                                key={item.title}
                            >
                                <div className={classes.mobileLinkItem}>
                                    <item.icon className={classes.mobileLinkIcon} size={20}/>
                                    <Text size="xs">{item.mobileViewTitle}</Text>
                                </div>
                            </NavLink>
                        ))}
                    {/* {isAdmin === "true" ? null : ( */}
                    <>
                        <Drawer
                            opened={opened}
                            onClose={() => setOpened(false)}
                            padding="md"
                            size="md"
                            position="right"
                            className={classes.openMoredrawer}
                        >
                            {(user && user.roles && user.roles.includes('ADMIN') ? adminNavItems : [])
                                .slice(3)
                                .map((item: any, x: number) => (
                                    <NavLink
                                        to={item.href}
                                        className={({isActive}) =>
                                            cx(classes.link, {
                                                [classes.linkActive]: isActive,
                                            })
                                        }
                                        key={item.title}
                                        onClick={() => setOpened(false)}
                                    >
                                        <div className={classes.linkItems}>
                                            <item.icon className={classes.linkIcon}/>
                                            <span>{item.title}</span>
                                        </div>
                                    </NavLink>
                                ))}

                            {(user && user.roles && user.roles.includes('VOLUNTEER') ? navItems : [])
                                .slice(3)
                                .map((item: any, x: number) => (
                                    <NavLink
                                        to={item.href}
                                        className={({isActive}) =>
                                            cx(classes.link, {
                                                [classes.linkActive]: isActive,
                                            })
                                        }
                                        key={item.title}
                                        onClick={() => setOpened(false)}
                                    >
                                        <div className={classes.linkItems}>
                                            <item.icon className={classes.linkIcon}/>
                                            <span>{item.title}</span>
                                        </div>
                                    </NavLink>
                                ))}
                        </Drawer>

                        <div className={classes.link} onClick={() => setOpened(true)}>
                            <div className={classes.mobileLinkItem}>
                                <Menu2 className={classes.mobileLinkIcon} size={20}/>
                                <Text size="xs">More</Text>
                            </div>
                        </div>
                    </>
                    {/* )} */}
                </Grid>
            ) : (
                <>
                    {(user && user.roles && user.roles.includes('ADMIN') ? adminNavItems : []).map(
                        (item: any, x: number) => (
                            <NavLink
                                to={item.href}
                                className={({isActive}) =>
                                    cx(classes.link, {
                                        [classes.linkActive]: isActive,
                                    })
                                }
                                key={item.title}
                            >
                                <div className={classes.linkItems}>
                                    <item.icon className={classes.linkIcon}/>
                                    <span>{item.title}</span>
                                </div>
                            </NavLink>
                        )
                    )}
                    {(user && user.roles && user.roles.includes('VOLUNTEER') ? navItems : []).map(
                        (item: any, x: number) => (
                            <NavLink
                                to={item.href}
                                className={({isActive}) =>
                                    cx(classes.link, {
                                        [classes.linkActive]: isActive,
                                    })
                                }
                                key={item.title}
                            >
                                <div className={classes.linkItems}>
                                    <item.icon className={classes.linkIcon}/>
                                    <span>{item.title}</span>
                                </div>
                            </NavLink>
                        )
                    )}
                </>
            )}
        </React.Fragment>
    );
};

export default Nav;
