import React from "react";
import {useState} from "react";
import {
    Drawer,
    Button,
    Group,
    Avatar,
    Box,
    MediaQuery,
    Text,
} from "@mantine/core";
import {User} from "../user/User";
import {useStyles} from "../Dashboard.styles";
import version from "../../../../package.json";
import {useNavigate} from "react-router-dom";

const SwipableDrawer = () => {
    const [opened, setOpened] = useState(false);
    const {classes} = useStyles();
    const navigate = useNavigate();
    const data = localStorage.getItem("user");
    const user = data ? JSON.parse(data) : null;
    return (
        <>
            <Drawer
                opened={opened}
                onClose={() => setOpened(false)}
                padding="md"
                size="sm"
                position="right"
            >
                <User view="mobile"/>
                <Box my={32} sx={{display: "flex", justifyContent: "center"}}>
                    <Button
                        onClick={() => {
                            localStorage.clear();
                            navigate("/");
                        }}
                        className={classes.button}
                    >
                        LogOut
                    </Button>
                </Box>
                <Box
                    className={classes.version}
                    styles={{position: "absolute", bottom: "30%"}}
                >
                    <Text align="center" color="#000">
                        Transport portal
                    </Text>
                    <Text align="center" color="#ffb875">{`V #${version.version}`}</Text>
                </Box>
            </Drawer>
            <Box>
                <MediaQuery largerThan="sm" styles={{display: "none"}}>
                    <Box onClick={() => setOpened(true)}>
                        <Avatar
                            src={user && user.username}
                            radius="xl"
                            size="md"
                            color="cyan"
                        />
                    </Box>
                </MediaQuery>
            </Box>
        </>
    );
};

export default SwipableDrawer;
