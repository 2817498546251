import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    table: {
        width: "100%",
        "& tr": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
        },
        "& td": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
        },
        "& th": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
            position: "sticky",
            top: "0",
            zIndex: 1,
            background:
                theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : theme.colors.gray[0],
        },
    },
    thumbnail: {
        height: "250px",
        width: "100%",
        border: "1px solid #ced4da",
    },
    uploadImageBox: {
        height: "100%",
        width: "100%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignContent: "cenrter",
        alignItems: "center",
    },
    notEnoughData: {
        height: "300px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));
