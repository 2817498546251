import React from "react";
import {Table, Grid, Paper, Box, Button, Text, Group} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import Heading from "../../../components/heading/Heading";
import {useStyles} from "./LocationConfiguration.styles";
import dayjs from "dayjs";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";

const LocationConfigurationScreen = ({
                                         onClickAddNew,
                                         onClickEditBtn,
                                         locationList,
                                         onClickDeleteBtn
                                     }: any) => {
    const {classes} = useStyles();

    const rows = (data: any) => {
        return (
            <>
                {data.length ? (
                    <>
                        {data.map((ele: any, x: number) => {
                            return (
                                <tr key={x}>
                                    <td>{ele.name}</td>
                                    <td>{ele.place}</td>
                                    <td>{ele.sub_place}</td>
                                    <td style={{background: `${ele.enabled?"green":"red"}`}}>{ele.enabled?"Enabled":"Disabled"}</td>
                                    <td>
                                        <Group>
                                            <Button onClick={() => onClickEditBtn(ele)}>Edit</Button>
                                            <Button className={classes.deleteBtn}
                                                    onClick={() => onClickDeleteBtn(ele.id)}>Delete</Button>
                                        </Group>
                                    </td>
                                </tr>
                            );
                        })}
                    </>
                ) : (
                    <tr>
                        <td colSpan={9}>
                            <NotEnoughData/>
                        </td>
                    </tr>
                )}
            </>
        );
    };

    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Heading title="Location Configuration"/>
                <Button ml={16} onClick={onClickAddNew}>
                    Add New Location
                </Button>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                {locationList.loading ? SkeletonLoader() :
                    <div style={{overflowX: "auto", height: '100%', margin: '24px 0'}}>
                        <Table highlightOnHover className={classes.table}>
                            <thead>
                            <tr>
                                <th>Title</th>
                                <th>Place</th>
                                <th>Stop</th>
                                <th>Enabled</th>
                            </tr>
                            </thead>

                            <tbody>
                            {locationList.locations.length ? (
                                rows(locationList.locations)
                            ) : (
                                <tr>
                                    <td colSpan={9}>
                                        <NotEnoughData/>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>}
            </Paper>
        </>
    );
};

export default LocationConfigurationScreen;
