import React, {useEffect} from "react";
import {
    Box,
    Button, Checkbox,
    Divider,
    Grid,
    Group,
    MultiSelect,
    Paper,
    Select,
    TextInput,
} from "@mantine/core";
import {ArrowLeft} from "tabler-icons-react";
import Heading from "../../../components/heading/Heading";
import CircularLoader from "../../../components/loader/CircularLoader";
import dayjs from "dayjs";

const AddNewRoute = ({
                         onClickBack,
                         onSubmit,
                         onUpdate,
                         onDiscard,
                         onChangeMode,
                         onChangeSource,
                         onChangeDestination,
                         onChangeCost,
                         onChangeCostNonCommercial,
                         onChangeTimeDuration,
                         onOpenEditRoute,
                         mode,
                         source,
                         destination,
                         cost,
                         costNonCommercial,
                         timeDuration,
                         editRoute,
                         isLoading,
                         locationsState,
                         enabled,
                         onChangeEnabled,
                         archive,
                         onChangeArchive
                     }: any) => {

    const locations = locationsState.locations;
    useEffect(() => {
        if (editRoute.isEditing) {
            onOpenEditRoute();
        }
    }, [editRoute.isEditing]);


    // datArr.push(availabilityDays.map((dd:any)=>{
    //     return
    // ));
    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Box
                    mr={16}
                    onClick={onClickBack}
                    sx={{cursor: "pointer", display: "flex", alignItems: "center"}}
                >
                    <ArrowLeft/>
                </Box>
                <Heading
                    title={editRoute.isEditing ? "Edit Route Config" : "Add New Route"}
                />
            </Box>

            <Paper shadow="xs" p="md" mt={18}>
                {isLoading && <CircularLoader/>}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={6}>
                        {editRoute.isEditing && <TextInput label={"Source"} value={mode} readOnly={true}></TextInput>}
                        {!editRoute.isEditing &&
                            <Select
                                placeholder="Pick one"
                                label="Mode"
                                required
                                value={mode}
                                onChange={onChangeMode}
                                data={[
                                    {value: "CAB", label: "Cab"},
                                    {value: "AC_BUS", label: "AC Bus"},
                                    {value: "BUS", label: "Bus"},

                                ]}
                            />
                        }
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        {editRoute.isEditing &&
                            <TextInput label={"Source"} value={editRoute.editItem.source.name} readOnly={true}></TextInput>}
                        {!editRoute.isEditing &&
                            <Select
                                searchable
                                placeholder="Pick one"
                                label="Source"
                                required
                                value={String(source)}
                                onChange={onChangeSource}
                                readOnly={editRoute.isEditing}
                                data={
                                    locations.map((i: any) => ({
                                        value:String( i.id),
                                        label: i.name,
                                    }))
                                }
                            />
                        }
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        {editRoute.isEditing &&
                            <TextInput label={"Destination"} value={editRoute.editItem.destination.name} readOnly={true}></TextInput>}
                        {!editRoute.isEditing &&
                            <Select
                                searchable
                                placeholder="Pick one"
                                label="Destination"
                                required
                                value={String(destination)}
                                readOnly={editRoute.isEditing}
                                onChange={onChangeDestination}
                                data={
                                    locations.map((i: any) => ({
                                        value: String(i.id),
                                        label: i.name,
                                    }))
                                }
                            />}
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            required
                            label="Cost"
                            placeholder="Cost"
                            value={cost}
                            onChange={onChangeCost}
                            type="number"
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            required
                            label="Cost Non Commercial"
                            placeholder="Cost Non Commercial"
                            value={costNonCommercial}
                            onChange={onChangeCostNonCommercial}
                            type="number"
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Time Duration (in minutes)"
                            required
                            value={timeDuration}
                            onChange={onChangeTimeDuration}
                            type="number"
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Select
                            label="Status"
                            placeholder="Pick one"
                            required
                            value={String(enabled)}
                            defaultValue={"true"}
                            onChange={onChangeEnabled}
                            data={[{ value: "true", label: "Enabled"},{ value: "false", label: "Disabled"}]}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Checkbox style={{margin: 20}}
                            label="Archive (Note: Routes Cannot be used after Archival)"
                            value={archive}
                            onChange={onChangeArchive}
                        />
                    </Grid.Col>
                </Grid>
                <Divider mt={16}/>
                <Group position="right" pt={24} pb={16} pr={8}>
                    <Button mr={8} onClick={onDiscard}>
                        Discard
                    </Button>
                    <Button mr={8} onClick={editRoute.isEditing ? onUpdate : onSubmit}>
                        Save
                    </Button>
                </Group>
            </Paper>
        </>
    );
};

export default AddNewRoute;
