import {showNotification} from "@mantine/notifications";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AddUserReq, AdminUser, UpdateUserReq} from "../../../@types";
import {addUser, updateUser} from "../../../api/api";
import {getAllUsers} from "../../../redux/actions/user.action";
import {RootState} from "../../../redux/store/store";
import {userScreen} from "../../../shared/constants";
import AddUser from "./AddUser";
import UserScreen from "./UserScreen";
import {AdminUserList, setUserListLoading} from "../../../redux/reducers/user.reducer";

const User = () => {
    const [currentScreen, setCurrentScreen] = useState(
        userScreen.ALL_USER_SCREEN
    );
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [password, setPassword] = useState("");
    const [hfn_id, setHfnId] = useState("");
    const [role, setRole] = useState<any>('');
    const [editUser, setEditUser] = useState<any>({
        isEditing: false,
        editItem: null,
    });
    const loading = useSelector((state: RootState) => state.user.loading);

    const dispatch = useDispatch();
    const userList = useSelector((state: RootState) => state.user);

    useEffect(() => {
        dispatch(getAllUsers());
    }, []);


    const onClickAddNew = () => {
        setEditUser({isEditing: false, editItem: null});
        onDiscard()
        setCurrentScreen(userScreen.ADD_USER_SCREEN);
    };
    const onClickBack = () => {
        onDiscard();
        setCurrentScreen(userScreen.ALL_USER_SCREEN);
    };
    const onChangeUserName = (e: any) => {
        setUserName(e.target.value);
    };
    const onChangeHfnId = (e: any) => {
        setHfnId(e.target.value);
    };
    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
    };
    const onChangeMobileNo = (e: any) => {
        if(e.target.value.length<=10)
        setMobileNo(e.target.value);
    };
    const onChangePassword = (e: any) => {
        setPassword(e.target.value);
    };
    const onChangeRole = (value: any) => {
        console.log('role', value);
        setRole(value);
    };

    const onClickEditBtn = (editUserData: AdminUser) => {
        console.log('data coming for editing', editUserData)
        setEditUser({isEditing: true, editItem: editUserData});
        setCurrentScreen(userScreen.ADD_USER_SCREEN);
    };

    const onOpenEditUser = () => {
        setUserName(editUser.editItem.username);
        setEmail(editUser.editItem.email);
        setMobileNo(editUser.editItem.phone_number);
        setPassword(editUser.editItem.password);
        setRole(editUser.editItem.roles);
        setHfnId(editUser.editItem.hfn_id)
    };

    const onValidate = () => {
        const emailRegex = /\S+@\S+\.\S+/;
        if (userName === "") {
            showNotification({
                title: "Error",
                message: "User name can not be empty",
                color: "red",
            });
        } else if (email === "") {
            showNotification({
                title: "Error",
                message: "Email name can not be empty",
                color: "red",
            });
        } else if (emailRegex.test(email) === false) {
            showNotification({
                title: "Error",
                message: "Invalid Email",
                color: "red",
            });
        } else if (mobileNo === "") {
            showNotification({
                title: "Error",
                message: "mobile number can not be empty",
                color: "red",
            });
        } else if (password === "") {
            showNotification({
                title: "Error",
                message: "Password can not be empty",
                color: "red",
            });
        } else if (role.length === 0) {
            showNotification({
                title: "Error",
                message: "Roles can not be empty",
                color: "red",
            });
        } else {
            return true;
        }
    };
    const onSubmit = async () => {
        console.log('onSubmit called', onValidate())
        if (onValidate()) {
            dispatch(setUserListLoading(true))
            const data: AddUserReq = {
                hfn_id,
                username: userName,
                email: email,
                phone_number: mobileNo,
                password: password,
                roles: role,
            };
            console.log("add user data", data);
            try {
                const res = await addUser(data);
                console.log("Add user res", res?.data);
                if (res?.data && res.data.success) {
                    dispatch(setUserListLoading(false))
                    showNotification({
                        title: "Success",
                        message: "User added successfully",
                        color: "green",
                    });
                    dispatch(getAllUsers());
                    setTimeout(() => onClickBack(), 1000);
                }
            } catch (error: any) {
                console.log("error adding user", error);
                dispatch(setUserListLoading(false))
                showNotification({
                    title: "Error",
                    message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                    color: "red",
                });
            }
        }
    };
    const onUpdate = async (id: any) => {
        if (onValidate()) {
            const data: UpdateUserReq = {
                hfn_id,
                username: userName,
                email: email,
                phone_number: mobileNo,
                password: password,
                roles: role,
            };
            console.log('update user payload', id, data)
            try {
                dispatch(setUserListLoading(true))
                const res = await updateUser(id, data);
                console.log("update user res", res.data);
                if (res.data && res.data.success) {
                    dispatch(setUserListLoading(false))
                    showNotification({
                        title: "Success",
                        message: "User updated successfully",
                        color: "green",
                    });
                    dispatch(getAllUsers());
                    setTimeout(() => onClickBack(), 1000);
                }
            } catch (error: any) {
                console.log("error adding user", error);
                dispatch(setUserListLoading(false))
                showNotification({
                    title: "Error",
                    message: error.message ? error.message : "Something went wrong",
                    color: "red",
                });
            }
        }
    };
    const onDiscard = () => {
        setUserName("");
        setEmail("");
        setMobileNo("");
        setPassword("");
        setRole([]);
        setHfnId('');
    };
    return (
        <>
            {currentScreen === userScreen.ALL_USER_SCREEN && (
                <UserScreen
                    onClickAddNew={onClickAddNew}
                    onClickEditBtn={onClickEditBtn}
                    userList={userList}
                    loading={loading}
                />
            )}
            {currentScreen === userScreen.ADD_USER_SCREEN && (
                <AddUser
                    onClickBack={onClickBack}
                    onSubmit={onSubmit}
                    onUpdate={onUpdate}
                    onDiscard={onDiscard}
                    onChangeUserName={onChangeUserName}
                    onChangeEmail={onChangeEmail}
                    onChangeMobileNo={onChangeMobileNo}
                    onChangePassword={onChangePassword}
                    onChangeRole={onChangeRole}
                    onOpenEditUser={onOpenEditUser}
                    userName={userName}
                    email={email}
                    mobileNo={mobileNo}
                    password={password}
                    role={role}
                    editUser={editUser}
                    hfn_id={hfn_id}
                    onChangeHfnId={onChangeHfnId}
                    loading={loading}
                />
            )}
        </>
    );
};

export default User;
