import React, {useEffect, useState} from "react";
import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {Link, useNavigate} from "react-router-dom";
import {login} from "../../../api/api";
import {AdminUser, Login} from "../../../@types";
import CircularLoader from "../../../components/loader/CircularLoader";

export function LogIn() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async () => {
        if (!username || !password) {
            console.log("please enter username or password to proceed");
            showNotification({
                title: "Error",
                message: "Please enter username or password to proceed",
                color: "red",
            });
        } else {
            setLoading(true);
            const payload: Login = {
                username,
                password,
            };
            try {
                const response = await login(payload);
                console.log("login response", response?.data);
                if (response?.data && response.data.success) {
                    if (response.data.payload.admin_user.roles.includes("ADMIN")) {
                        localStorage.setItem(
                            "user",
                            JSON.stringify(response.data.payload.admin_user)
                        );
                        localStorage.setItem("token", response.data.payload.token);
                        console.log("i am admin");
                        setLoading(false);
                        showNotification({
                            title: "Success",
                            message: "Login successful as admin",
                            color: "green",
                        });
                        navigate("/app");
                    } else {
                        console.log("i am volunteer");
                        localStorage.setItem(
                            "user",
                            JSON.stringify(response.data.payload.admin_user)
                        );
                        localStorage.setItem("token", response.data.payload.token);
                        setLoading(false);
                        showNotification({
                            title: "Success",
                            message: "Login successful as volunteer",
                            color: "green",
                        });
                        navigate("/app");
                    }
                }
            } catch (error: any) {
                setLoading(false);
                if (error.response.status === 401) {
                    showNotification({
                        title: "Error",
                        message: "Invalid Username or Password",
                        color: "red",
                    });
                } else {
                    showNotification({
                        title: "Error",
                        message: error.message
                            ? error.message
                            : "Something went wrong please try again later",
                        color: "red",
                    });
                }
            }
        }
    };
    useEffect(() => {
        const data = localStorage.getItem("user");
        const user: AdminUser = data ? JSON.parse(data) : null;
        if (user && user.id) {
            navigate("/app");
        }
    }, []);

    return (
        <Container size={420} my={40}>
            <Title
                align="center"
                sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 900,
                })}
            >
                Welcome back!
            </Title>
            {/* <Text color="dimmed" size="sm" align="center" mt={5}>
        Do not have an account yet?{" "}
        <Anchor<"a">
          href="#"
          size="sm"
          onClick={(event) => event.preventDefault()}
        >
          Create account
        </Anchor>
      </Text> */}

            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <TextInput
                    value={username}
                    label="Username"
                    placeholder="volunteerheartfulness"
                    onChange={(e) => {
                        setUsername(e.target.value);
                    }}
                    required
                />
                <PasswordInput
                    value={password}
                    label="Password"
                    placeholder="Your password"
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    required
                    mt="md"
                />
                <Group position="apart" mt="md">
                    {/* <Checkbox label="Remember me" /> */}
                    <Anchor<"a">
                        onClick={(event) => {
                            showNotification({
                                title: "Oops!",
                                message: "Please contact the admin for further help",
                                color: "yellow",
                            });
                        }}
                        href="#"
                        size="sm"
                    >
                        Forgot password?
                    </Anchor>
                </Group>
                <Button disabled={loading} fullWidth mt="xl" onClick={handleSubmit}>
                    {loading ? <CircularLoader size={20} style={{
                        position: "absolute",
                        top: "20%",
                        left: "50%",
                        zIndex: 1
                    }}/> : "Sign in"}
                </Button>
            </Paper>
            {/* {loading && <CircularLoader />} */}
        </Container>
    );
}
