import React, {useState} from 'react';
import Star from './Star';
import {Text} from "@mantine/core";

const RatingStars = ({onRatingChange, title = 'Rate'}) => {
    const [gradeIndex, setGradeIndex] = useState();
    const GRADES = ['Poor', 'Fair', 'Good', 'Very good', 'Excellent'];
    const GRADES_VAL = [1, 2, 3, 4, 5];
    const activeStar = {
        fill: 'yellow'
    };

    const changeGradeIndex = (index) => {
        setGradeIndex(index);
        onRatingChange(GRADES_VAL[index])
    }

    return (
        <div className="container">


            <div className="stars">
                <div style={{alignItems: "left"}}>
                    <table style={{ margin:"auto"}}>
                        <tr>
                            <td style={{width:"100px", textAlign: "left"}}><Text>{title}</Text></td>
                            <td>{
                                GRADES.map((grade, index) => (
                                    <Star
                                        index={index}
                                        key={grade}
                                        changeGradeIndex={changeGradeIndex}
                                        style={gradeIndex >= index ? activeStar : {}}
                                    />
                                ))
                            }</td>
                            {/*<td><h5  className="result">{ GRADES[gradeIndex] ? GRADES[gradeIndex] : 'Not Rated'}</h5></td>*/}
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    );
}

export default RatingStars;