import {getAvailableRoutersList, getRoutersList} from "../../api/api";
import {
    setAvailableRouteListLoading,
    setAvailableRoutesList,
    setRouteListLoading,
    setRoutesList
} from "../reducers/route.reducer";
import {AppThunk} from "../store/store";

export const getAllRoutes = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setRouteListLoading(true));
        const res = await getRoutersList();
        console.log('get routes response', res.data)
        if (res.data && res.data.success) {
            dispatch(setRoutesList(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setRouteListLoading(false));
    }
};

export const getAllAvailableRoutes = (days:string[]): AppThunk => async (dispatch) => {
    try {
        dispatch(setAvailableRouteListLoading(true));
        const res = await getAvailableRoutersList(days);
        console.log('get routes response', res.data)
        if (res.data && res.data.success) {
            dispatch(setAvailableRoutesList(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setAvailableRouteListLoading(false));
    }
};
