import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllCabTrips} from "../../../redux/actions/cab.action";
import {RootState} from "../../../redux/store/store";
import TrackingVehiclesArrivalsScreen from "./TrackingVehiclesArrivalsScreen";

const TrackingVehicleArrivals = () => {



    return (
        <TrackingVehiclesArrivalsScreen/>
    );
};

export default TrackingVehicleArrivals;
