import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Select,
    Text,
    TextInput,
} from "@mantine/core";
import Heading from "../../../components/heading/Heading";
import {useStyles} from "./CabStatus.styles";
import {ArrowLeft} from "tabler-icons-react";
import CircularLoader from "../../../components/loader/CircularLoader";
import dayjs from "dayjs";

const cabsStatus = [
    {value: "OFF_FROM_WORK", label: "Off From Work"},
    {value: "READY_FOR_WORK", label: "Ready For Work"},
    {value: "ONGOING_RIDE", label: "On Trip"}

];


const CabDetailScreen = ({
                             cab,
                             onClickBack,
                             onChangeCabStatus,
                             cabStatus,
                             updateCabStatus,
                             completeCabTrip,
                             cancelCabTrip,
                             loading,
                             location,
                             eta,
                             onChangeLocation,
                             onChangeEta,
                             captureLocationAssignment,
                             locations,
                             currentDriver,
                             onCurrentDriverChange
                         }: any) => {
    const {classes} = useStyles();
    return (
        <>
            {loading && <CircularLoader/>}
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Box
                    mr={16}
                    onClick={onClickBack}
                    sx={{cursor: "pointer", display: "flex", alignItems: "center"}}
                >
                    <ArrowLeft/>
                </Box>
                <Heading title={"Cab Details ::     " + cab?.registration_number}/>
            </Box>
            <Paper shadow="xs" p="md">
                <Text>Basic Details</Text>
                <Divider mt={10}/>
                <Grid mt={5}>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="Vehicle Number"
                            label="Vehicle Number"
                            contentEditable={false}
                            value={cab?.registration_number}
                            autoCapitalize={"uppercase"}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Commercial"
                            contentEditable={false}
                            placeholder="Commercial"
                            value={cab?.commercial? "Commercial": "Non Commercial"}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Car Type"
                            contentEditable={false}
                            placeholder="Pick one"
                            value={cab?.type}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="Car area"
                            label="Car area"
                            contentEditable={false}
                            value={cab?.area}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="Eg. 4"
                            label="Number of seats"
                            contentEditable={false}
                            value={cab?.number_of_seats}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Rating"
                            readOnly
                            value={cab?.rating.toFixed(1)} //+ dayjs(cab?.location_eta).isBefore(new Date())?"": " Arriving at "+dayjs(cab?.location_eta).format("DD/MM/YYYY HH:mm a")
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}></Grid.Col>
                </Grid>
            </Paper>

            <Paper shadow="xs" p="md" mt={10}>
                <Text>Status Details</Text>
                <Divider mt={10}/>
                <Grid mt={5} justify="flex-left" pb={16} pr={8}>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Select
                            searchable
                            label="Status"
                            required
                            placeholder="Pick one"
                            defaultValue={cabStatus}
                            onChange={onChangeCabStatus}
                            data={cabsStatus}
                        />
                        {captureLocationAssignment && <Select
                            mt={10}
                            placeholder="Pick one"
                            label="Driver"
                            searchable
                            required
                            defaultValue={String(currentDriver)}
                            onChange={onCurrentDriverChange}
                            data={
                                cab.drivers.map((i: any) => ({
                                    value: String(i.id),
                                    label: i.first_name+" "+i.last_name,
                                }))
                            }
                        />}
                        {captureLocationAssignment &&
                            <TextInput
                                label="Current Location"
                                contentEditable={false}
                                value={ cab.location_name?.substring(0, cab.location_name?.indexOf(','))} //+ dayjs(cab?.location_eta).isBefore(new Date())?"": " Arriving at "+dayjs(cab?.location_eta).format("DD/MM/YYYY HH:mm a")
                            />
                        }
                        {captureLocationAssignment && <Select
                            mt={10}
                            placeholder="Pick one"
                            label="Assigned Destination"
                            searchable
                            defaultValue={String(location)}
                            onChange={onChangeLocation}
                            data={
                                locations.map((i: any) => ({
                                    value: String(i.id),
                                    label: i.name,
                                }))
                            }
                        />}
                        {captureLocationAssignment && <TextInput
                            mt={10}
                            label="ETA to Reach in minutes"
                            maxLength={3}
                            value={eta}
                            onChange={(e) =>  onChangeEta(e.target.value) }
                        />}
                        <Button onClick={updateCabStatus} mt={25}>
                            Update Status
                        </Button>
                    </Grid.Col>
                </Grid>
            </Paper>


            <Paper shadow="xs" p="md" mt={10}>
                <Text>Trip Details</Text>
                <Divider mt={10}/>
                {cab?.current_trip ? <Grid justify="flex-left" pt={24} pb={16} pr={8}>
                    <Grid.Col sm={12} md={6} lg={6}><TextInput label={"Trip"}
                                                               value={cab?.current_trip?.id ? "ON Trip" : "No Trip Assigned"}
                                                               readOnly={true}/></Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}><TextInput label={"Trip Status"}
                                                               value={cab?.current_trip?.status}
                                                               readOnly={true}/></Grid.Col>
                    {cab?.current_trip.has_pre_defined_route && <Grid.Col sm={12} md={6} lg={6}><TextInput label={"Route"}
                                                                                                               value={cab?.current_trip?.source.name + " ===>> " + cab?.current_trip?.destination.name}
                                                                                                               readOnly={true}/></Grid.Col>}
                    {!cab?.current_trip.has_pre_defined_route && <Grid.Col sm={12} md={6} lg={6}><TextInput label={"Route"}
                                                                                                           value={cab?.current_trip?.source_geo_location.name + " ===>> " + cab?.current_trip?.destination_geo_location.name}
                                                                                                           readOnly={true}/></Grid.Col>}
                    <Grid.Col sm={12} md={6} lg={6}> <TextInput label={"Scheduled Start"}
                                                                value={dayjs(cab?.current_trip?.scheduled_time).format("YYYY-MM-DD hh:MM a")}
                                                                readOnly={true}/></Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}> <TextInput label={"Estimated Arrival Time"}
                                                                value={dayjs(cab?.current_trip?.eta).format("YYYY-MM-DD hh:MM a")}
                                                                readOnly={true}/></Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}> <TextInput label={"Actual Start"}
                                                                value={dayjs(cab?.current_trip?.start).format("YYYY-MM-DD hh:MM a")}
                                                                readOnly={true}/></Grid.Col>

                    <Grid.Col>
                        <Button onClick={cancelCabTrip} mt={25}>
                            Cancel Trip
                        </Button>
                        <Button onClick={completeCabTrip} mt={25} ml={15}>
                            Complete Trip
                        </Button>
                    </Grid.Col>
                </Grid> : <Grid><Grid.Col><TextInput
                    value={"No Trip Assigned"}
                    readOnly={true}/></Grid.Col></Grid>}

            </Paper>
        </>
    )
};

export default CabDetailScreen;
