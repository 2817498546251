import {AppThunk} from "../store/store";
import {
    setLocationList,
    setLocationListLoading,
} from "../reducers/location.reducer";
import {getAllLocations} from "../../api/api";


export const getAllLocationsAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLocationListLoading(true));
        const res = await getAllLocations();
        console.log('get  location response', res.data)
        if (res.data && res.data.success) {
            dispatch(setLocationList(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLocationListLoading(false));
    }
};
