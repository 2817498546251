export const timeList = [
    {value: "0", label: "0.00-2.00"},
    {value: "2", label: "2.00-4.00"},
    {value: "4", label: "4.00-6.00"},
    {value: "6", label: "6.00-8.00"},
    {value: "8", label: "8.00-10.00"},
    {value: "10", label: "10.00-12.00"},
    {value: "12", label: "12.00-14.00"},
    {value: "14", label: "14.00-16.00"},
    {value: "16", label: "16.00-18.00"},
    {value: "18", label: "18.00-20.00"},
    {value: "20", label: "20.00-22.00"},
    {value: "22", label: "22.00-24.00"},
]