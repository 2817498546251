import React, {useEffect} from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    Group,
    MultiSelect,
    Paper,
    Select,
    TextInput,
} from "@mantine/core";
import {ArrowLeft, ToggleRight} from "tabler-icons-react";
import Heading from "../../../components/heading/Heading";
import CircularLoader from "../../../components/loader/CircularLoader";
import dayjs from "dayjs";

const AddNewLocation = ({
                            onClickBack,
                            onSubmit,
                            onUpdate,
                            onDiscard,
                            onOpenEditLocation,
                            enabled,
                            onChangeEnabled,
                            text,
                            onChangeText,
                            place,
                            onChangePlace,
                            subPlace,
                            onChangeSubPlace,
                            editLocation,
                            isLoading,
                            glink,
                            onChangeGlink,
                            lat,
                            onChangeLat,
                            lon,
                            onChangeLon,
                        }: any) => {
    useEffect(() => {
        if (editLocation.isEditing) {
            onOpenEditLocation();
        }
    }, [editLocation.isEditing]);
    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Box
                    mr={16}
                    onClick={onClickBack}
                    sx={{cursor: "pointer", display: "flex", alignItems: "center"}}
                >
                    <ArrowLeft/>
                </Box>
                <Heading
                    title={editLocation.isEditing ? "Edit Location" : "Add New Location"}
                />
            </Box>

            <Paper shadow="xs" p="md" mt={18}>
                {isLoading && <CircularLoader/>}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Place"
                            required
                            readOnly={editLocation.isEditing}
                            autoCapitalize={"characters"}
                            value={place}
                            onChange={onChangePlace}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Stop"
                            required
                            value={subPlace}
                            readOnly={editLocation.isEditing}
                            autoCapitalize={"characters"}
                            onChange={onChangeSubPlace}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Title"
                            required
                            value={text}
                            onChange={onChangeText}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Google Link"
                            required
                            value={glink}
                            onChange={onChangeGlink}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Latitude"
                            required
                            value={lat}
                            onChange={onChangeLat}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            label="Longitude"
                            required
                            value={lon}
                            onChange={onChangeLon}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Select
                            label="Status"
                            placeholder="Pick one"
                            required
                            value={enabled}
                            defaultValue={"true"}
                            onChange={onChangeEnabled}
                            data={[{ value: "true", label: "Enabled"},{ value: "false", label: "Disabled"}]}
                        />
                    </Grid.Col>
                </Grid>
                <Divider mt={16}/>
                <Group position="right" pt={24} pb={16} pr={8}>
                    <Button mr={8} onClick={onDiscard}>
                        Discard
                    </Button>
                    <Button mr={8} onClick={editLocation.isEditing ? onUpdate : onSubmit}>
                        Save
                    </Button>
                </Group>
            </Paper>
        </>
    );
};

export default AddNewLocation;
