import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Select,
    Text,
    TextInput,
} from "@mantine/core";
import {TimeInput} from "@mantine/dates";
import {showNotification} from "@mantine/notifications";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BusTripCreateRequest,} from "../../../@types";
import {
    addBusTrips,
    getVisitorById,
} from "../../../api/api";
import Heading from "../../../components/heading/Heading";
import {getConfigs} from "../../../redux/actions/config.action";
import {
    getAllAvailableRoutes,
    getAllRoutes,
} from "../../../redux/actions/route.action";
import {RootState} from "../../../redux/store/store";
import CircularLoader from "../../../components/loader/CircularLoader";

const AllocationOfBusScreen = () => {
    const configState = useSelector((state: RootState) => state.config);
    const state = useSelector((state: RootState) => state.route);

    const [visitorId, setVisitorId] = useState("");
    const [hfnId, setHfnId] = useState<any>("");
    const [isVisitorAvailable, setisVisitorAvailable] = useState(false);
    const [visitorContact, setVisitorContact] = useState<string>("");
    const [visitorEmail, setVisitorEmail] = useState<string>("");
    const [visitorName, setVisitorName] = useState<string>("");
    const [route, setRoute] = useState<string>("");
    const [mode, setMode] = useState<string>("");
    const [vehicleRegNumber, setVehicleRegNumber] = useState<string>("");
    const [driverName, setDriverName] = useState<string>("");
    const [driverContact, setDriverContact] = useState<string>("");
    const [totalSeats, setTotalSeats] = useState("");
    const [headCount, setHeadCount] = useState<any>("");
    const [tripStartTime] = useState<Date>(new Date());
    const [tripEndTime, setTripEndTime] = useState<Date>(new Date());
    const routesList = useSelector((state: RootState) => state.route.availableRoutesList);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const config = configState.configLists.configs.filter(
        (i: any) => i.key_name === "location" || i.key_name === "Location"
    );
    console.log("config", config);

    useEffect(() => {
        dispatch(getAllAvailableRoutes( [dayjs(new Date()).format("YYYY-MM-DD")]))
    }, []);

    const handleIdChange = async (e: any) => {
        setHfnId(e.target.value);
        try {
            const res = await getVisitorById([e.target.value]);
            console.log("res from habdle change visitor id", res.data);
            if (res.data && res.data.payload.length) {
                setVisitorId(res.data.payload[0].id);
                setVisitorContact(res.data.payload[0].phone_number);
                setVisitorEmail(res.data.payload[0].email);
                setVisitorName(res.data.payload[0].name);
                setisVisitorAvailable(true);
            } else {
                setisVisitorAvailable(false);
                setVisitorContact("");
                setVisitorEmail("");
                setVisitorName("")
            }
        } catch (error: any) {
            setVisitorContact("");
            setVisitorEmail("");
            setisVisitorAvailable(false);
            setVisitorName("")
            console.log("error in getting visitor id");
        }
    };


    const onChangeRoute = (v: any) => {
        console.log(v)
        setRoute(v)
        var route = routesList.routes?.find((el) => String(el.id) === String(v))
        if(route){
            setTripEndTime(dayjs(new Date()).add(route.time_estimates_minutes,"minute").toDate())
        }

    }

    const onValidate = () => {

        if(visitorContact){
            if(visitorContact.length >=0){
                if(visitorContact.startsWith("+91") && visitorContact.length!=13){

                       showNotification({
                           title: "Error",
                           message: "Invalid Indian Contact number",
                           color: "red",
                       });
                       return false
                }else if(!visitorContact.startsWith("+") && visitorContact.length!=10){
                    showNotification({
                        title: "Error",
                        message: "Invalid Indian Contact number",
                        color: "red",
                    });
                    return false
                }
            }

        }
        const emailRegex = /\S+@\S+\.\S+/;
        if(visitorEmail && !emailRegex.test(visitorEmail)){
            showNotification({
                title: "Error",
                message: "Invalid Email",
                color: "red",
            });
            return false
        }

        if (!hfnId || hfnId === "") {
            showNotification({
                title: "Error",
                message: "Enter a valid Visitor/Abhyasi Id",
                color: "red",
            });
        } else if (route === "") {
            showNotification({
                title: "Error",
                message: "Please select a Route",
                color: "red",
            });
        } else if (vehicleRegNumber === "") {
            showNotification({
                title: "Error",
                message: "Vehicle Registration Number cannot be empty",
                color: "red",
            });
        } else if (driverName === "") {
            showNotification({
                title: "Error",
                message: "Driver's name cannot be empty",
                color: "red",
            });
        } else if (driverContact === "") {
            showNotification({
                title: "Error",
                message: "Driver's Phone cannot be empty",
                color: "red",
            });
        } else if (totalSeats === "") {
            showNotification({
                title: "Error",
                message: "Total Seats can't be empty",
                color: "red",
            });
        } else if (headCount === "") {
            showNotification({
                title: "Error",
                message: "Head count can't be empty",
                color: "red",
            });
        } else if (headCount > totalSeats) {
            showNotification({
                title: "Error",
                message: "Head count cannot be more than total seats",
                color: "red",
            });
        }else {
            return true;
        }
    };
    const resetForm = () => {
        setVisitorId("");
        setHfnId("");
        setVehicleRegNumber("");
        setDriverName("");
        setDriverContact("");
        setTotalSeats("");
        setHeadCount("")
        setVisitorContact("");
        setVisitorEmail("");
        setVisitorName("");
        setMode("")
        //setDriverDetails([]);
    };
    const onChangeMode = (value: any) => {
        setMode(value);
        setRoute("")
    };

    const _addTrip = async (id?: any) => {
        const busTripData: BusTripCreateRequest = {
            registration_number: String(vehicleRegNumber),
            driver_name: String(driverName),
            driver_number: String(driverContact),
            total_number_of_seats: String(totalSeats),
            number_of_occupied_seats: String(headCount),
            route_id: route,
            visitor_name: visitorName,
            visitor_email: visitorEmail,
            visitor_hfn_id: hfnId,
            visitor_phone: visitorContact
        };
        setLoading(true)
        try {
            const res = await addBusTrips(busTripData);
            setLoading(false)
            if (res.data && res.data.success) {
                showNotification({
                    title: "Success",
                    message: "Trip added successfully",
                    color: "green",
                });
                resetForm();
            } else {
                showNotification({
                    title: "Error",
                    message: "Error in adding trip",
                    color: "red",
                });
            }
        } catch (error) {
            setLoading(false)
            console.log("error in adding bus trip", error);
            showNotification({
                title: "Error",
                message: "Error in adding trip",
                color: "red",
            });
        }
    };
    const onSubmit = () => {
        if (onValidate()) {
             _addTrip();
        }
    };

    return (
        <>
            <Box py="md">
                <Heading title="Allocation Of Bus to visitors"/>
            </Box>
            <Paper shadow="xs" p="md">
                {/* <Text color="red" size="xs" mb={8}>
          Note: Email or contact # is mandatory
        </Text> */}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            // type="number"
                            placeholder="Enter Abhyasi ID"
                            label="Enter Abhyasi ID "
                            required
                            value={hfnId}
                            onChange={handleIdChange}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="+910000000000"
                            label="Visitor contact #"
                            // label={
                            //   <Text size="sm">
                            //     Visitor contact #
                            //     <Text component="span" color="red">
                            //       {" "}
                            //       **
                            //     </Text>
                            //   </Text>
                            // }
                            type="tel"
                            value={visitorContact}
                            onChange={(e) => {

                                const phoneRegex = /^[\+]?[0-9]*$/;
                                if(e.target.value.length >= 0  && phoneRegex.test(e.target.value)){
                                    if(e.target.value.startsWith("+")){
                                        if(e.target.value.startsWith("+91")){
                                            e.target.value.length <= 13 && setVisitorContact(e.target.value)
                                        }else{
                                            e.target.value.length <= 20 && setVisitorContact(e.target.value)
                                        }
                                    }else{
                                        e.target.value.length <= 10 && setVisitorContact(e.target.value)
                                    }
                                }
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Visitor email"
                            label="Visitor email"
                            type="email"
                            value={visitorEmail}
                            onChange={(e) => setVisitorEmail(e.target.value)}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder=" Visitor Name"
                            label="Visitor Name"
                            value={visitorName}
                            onChange={(e) => setVisitorName(e.target.value)}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Enter Vehicle  Number"
                            label="Enter Vehicle Number"
                            required
                            value={vehicleRegNumber}
                            onChange={(e) => setVehicleRegNumber(e.target.value)}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <Select
                            placeholder="Pick one"
                            label="Mode"
                            required
                            value={mode}
                            onChange={onChangeMode}
                            data={[
                                {value: "AC_BUS", label: "AC Bus"},
                                {value: "BUS", label: "Bus"},

                            ]}
                        />
                    </Grid.Col>


                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder=" Driver's Name"
                            label=" Driver's Name"
                            value={driverName}
                            onChange={(e) => setDriverName(e.target.value)}
                            required
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Driver's Number"
                            label="Driver's Number"
                            value={driverContact}
                            type={'number'}
                            onChange={(e) =>  e.target.value.length <= 10 && setDriverContact(e.target.value)}
                            maxLength={10}
                            //required
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            type="number"
                            placeholder="Total Capacity Of Bus"
                            label="Total Seats in Bus"
                            required
                            value={totalSeats}
                            onChange={(e) => setTotalSeats(e.target.value)}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            type="number"
                            placeholder="Enter Headcount of people"
                            label="Enter Headcount of people"
                            required
                            value={headCount}
                            onChange={(e) => setHeadCount(e.target.value)}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <Select
                            placeholder="Pick one"
                            label="Route"
                            required
                            searchable
                            value={route}
                            defaultValue={""}
                            onChange={onChangeRoute}
                            data={
                                routesList.routes.length
                                    ? routesList.routes?.filter((i: any) => i.mode === mode).map((i: any) => ({
                                        value: String(i.id),
                                        label: i.source.name + " ===>> " + i.destination.name,
                                    }))
                                    : []
                            }
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TimeInput label="Trip Start time" value={tripStartTime} disabled/>
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TimeInput label="Trip end time" value={tripEndTime} disabled/>
                    </Grid.Col>
                </Grid>
                <Divider mt={16}/>
                <Grid justify="flex-end" pt={24} pb={16} pr={8}>
                    <Button mr={8} onClick={resetForm}>
                        Discard
                    </Button>
                    <Button onClick={onSubmit}>Allocate</Button>
                </Grid>
                {loading && <CircularLoader/>}
            </Paper>
        </>
    );
};

export default AllocationOfBusScreen;
