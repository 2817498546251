import {
    Box,
    Button,
    Divider,
    Grid,
    Group,
    MultiSelect,
    Paper,
    Select,
    TextInput,
} from "@mantine/core";
import React, {useEffect} from "react";
import {ArrowLeft} from "tabler-icons-react";
import Heading from "../../../components/heading/Heading";
import CircularLoader from "../../../components/loader/CircularLoader";
import {useStyles} from "./Config.styles";

const AddNewConfig = ({
                          keyName,
                          value,
                          onClickBack,
                          onCreatedValues,
                          onChangeKey,
                          editConfig,
                          onDiscard,
                          onSubmit,
                          onUpdate,
                          onOpenEditConfig,
                          isLoading,
                          onChangeValue
                      }: any) => {
    const {classes} = useStyles();
    useEffect(() => {
        if (editConfig.isEditing) {
            onOpenEditConfig();
        }
    }, [editConfig.isEditing]);
    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Box
                    mr={16}
                    onClick={onClickBack}
                    sx={{cursor: "pointer", display: "flex", alignItems: "center"}}
                >
                    <ArrowLeft/>
                </Box>
                <Heading
                    title={editConfig.isEditing ? "Edit Config" : "Add New Config"}
                />
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                {isLoading && <CircularLoader/>}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <TextInput
                            placeholder="Key"
                            label="Key"
                            value={String(keyName)}
                            onChange={onChangeKey}
                            required
                        />
                    </Grid.Col>

                    <Grid.Col sm={12} md={6} lg={4}>
                        <MultiSelect
                            label="value"
                            placeholder="Pick one"
                            data={value}
                            value={value}
                            required
                            searchable
                            creatable
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onCreate={onCreatedValues}
                            onChange={onChangeValue}
                        />
                    </Grid.Col>
                </Grid>
                <Divider mt={16}/>
                <Group position="right" pt={24} pb={16} pr={8}>
                    <Button mr={8} onClick={onDiscard}>
                        Discard
                    </Button>
                    <Button mr={8} onClick={editConfig.isEditing ? onUpdate : onSubmit}>
                        Save
                    </Button>
                </Group>
            </Paper>
        </>
    );
};

export default AddNewConfig;
