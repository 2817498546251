import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Select,
    Text,
    TextInput,
} from "@mantine/core";
import React, {useEffect, useState} from "react";
import Heading from "../../../components/heading/Heading";
import ImagePreview from "../../../components/imagePreview/ImagePreview";
import {useStyles} from "./CabRegistration.styles";
import {ArrowLeft, FileUpload} from "tabler-icons-react";
import CircularLoader from "../../../components/loader/CircularLoader";
import {SquarePlus} from "tabler-icons-react";
import {Archive} from "tabler-icons-react";
//import { RMIUploader } from "react-multiple-image-uploader";
//import { RMIUploader } from "react-multiple-image-uploader";
import {X} from "tabler-icons-react";


const AddNewCab = ({
                       vehicleNo,
                       carType,
                       carArea,
                       cabStatus,

                       hiddenRcImageInputRef,
                       hiddenPollutionImageInputRef,
                       hiddenTaxiPermitImageInputRef,
                       rcImageSrc,
                       pollutionImageSrc,
                       taxiPermitImageSrc,
                       handleUploadCarImgClick,
                       handleUploadRcImgClick,
                       handleUploadPollutionImgClick,
                       handleUploadTaxiPermitImgClick,

                       onSelectRcImage,
                       onSelectPollutionImage,
                       onSelectTaxiPermitImage,

                       onCloseRcImage,
                       onClosePollutionImage,
                       onCloseTaxiPermitImage,
                       onClickBack,
                       editCab,
                       onOpenEditCab,
                       onChangeVehicleNo,
                       onChangeCarType,
                       onChangeCarArea,
                       onChangeCabStatus,
                       onDiscard,
                       onSubmit,
                       onUpdate,
                       noOfSeats,
                       onChangeNoOfSeat,
                       loading,
                       hiddenImageInput,
                       handleImageClick,
                       onSelectImage,
                       imgArr,
                       onClickAdd,
                       onClickDelete,
                       onCloseImage,
                       enabled,
                       onChangeEnabled,
                       commercial,
                       onChangeCommercial,

                   }: any) => {
    const {classes} = useStyles();
    //console.log("imgArr", imgArr);
    useEffect(() => {
        if (editCab.isEditing) {
            onOpenEditCab(editCab.editItem);
        }
    }, [editCab.isEditing]);
    return (
        <>
            {loading.submit && <CircularLoader/>}
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Box
                    mr={16}
                    onClick={onClickBack}
                    sx={{cursor: "pointer", display: "flex", alignItems: "center"}}
                >
                    <ArrowLeft/>
                </Box>
                <Heading title={editCab.isEditing ? "Edit Cab" : "Add New Cab"}/>
            </Box>
            <Paper shadow="xs" p="md">

                <Grid>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="Vehicle Number"
                            label="Vehicle Number "
                            required
                            readOnly={editCab.isEditing}
                            value={vehicleNo}
                            onChange={onChangeVehicleNo}
                            autoCapitalize={"uppercase"}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Select
                            searchable
                            label="Car Type"
                            placeholder="Pick one"
                            required
                            value={carType}
                            onChange={onChangeCarType}
                            data={[
                                {value: "SEDAN", label: "SEDAN"},
                                {value: "HATCHBACK", label: "HATCHBACK"},
                                {value: "SUV", label: "SUV"},
                                {value: "MINI", label: "MINI"},
                            ]}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Select
                            label="Service Status"
                            placeholder="Pick one"
                            required
                            value={String(enabled)}
                            defaultValue={"true"}
                            onChange={onChangeEnabled}
                            data={[{ value: "true", label: "Active"},{ value: "false", label: "Inactive"}]}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <Select
                            label="Commercial Status"
                            placeholder="Pick one"
                            required
                            value={String(commercial)}
                            defaultValue={"true"}
                            onChange={onChangeCommercial}
                            data={[{ value: "true", label: "Commercial"},{ value: "false", label: "Non Commercial"}]}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="Car area"
                            label="Car area"
                            required
                            value={carArea}
                            onChange={onChangeCarArea}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="Eg. 4"
                            label="Number of seats"
                            value={noOfSeats}
                            onChange={onChangeNoOfSeat}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <Text weight={500} mb={8} size="sm">
                            Upload RC Photo
                            <Text component="span" color="red"> *</Text>
                        </Text>
                        <Box className={classes.thumbnail} sx={{position: 'relative'}}>
                            {loading.rcImg && <CircularLoader/>}
                            {rcImageSrc ? (
                                <ImagePreview
                                    src={rcImageSrc}
                                    onCloseImage={onCloseRcImage}
                                    //imgType='link'
                                />
                            ) : (
                                <Box
                                    onClick={handleUploadRcImgClick}
                                    className={classes.uploadImageBox}
                                >
                                    <FileUpload size={48} color="#444"/>
                                    <input
                                        accept="image/*"
                                        id="audioFile"
                                        type="file"
                                        hidden
                                        ref={hiddenRcImageInputRef}
                                        onChange={onSelectRcImage}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <Text weight={500} mb={8} size="sm">
                            Upload Pollution Photo
                            <Text component="span" color="red"> *</Text>
                        </Text>
                        <Box className={classes.thumbnail} sx={{position: 'relative'}}>
                            {loading.pollutionImg && <CircularLoader/>}
                            {pollutionImageSrc ? (
                                <ImagePreview
                                    src={pollutionImageSrc}
                                    onCloseImage={onClosePollutionImage}
                                />
                            ) : (
                                <Box
                                    onClick={handleUploadPollutionImgClick}
                                    className={classes.uploadImageBox}
                                >
                                    <FileUpload size={48} color="#444"/>
                                    <input
                                        accept="image/*"
                                        id="audioFile"
                                        type="file"
                                        hidden
                                        ref={hiddenPollutionImageInputRef}
                                        onChange={onSelectPollutionImage}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={4}>
                        <Text weight={500} mb={8} size="sm">
                            Upload Taxi Permit Photo
                            <Text component="span" color="red"> *</Text>
                        </Text>
                        <Box className={classes.thumbnail} sx={{position: 'relative'}}>
                            {loading.taxiPermitImg && <CircularLoader/>}
                            {taxiPermitImageSrc ? (
                                <ImagePreview
                                    src={taxiPermitImageSrc}
                                    onCloseImage={onCloseTaxiPermitImage}
                                />
                            ) : (
                                <Box
                                    onClick={handleUploadTaxiPermitImgClick}
                                    className={classes.uploadImageBox}
                                >
                                    <FileUpload size={48} color="#444"/>
                                    <input
                                        accept="image/*"
                                        id="audioFile"
                                        type="file"
                                        hidden
                                        ref={hiddenTaxiPermitImageInputRef}
                                        onChange={onSelectTaxiPermitImage}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid.Col>
                    <Grid.Col sm={12} md={12} lg={12}>
                        <Box className={classes.addBtn} onClick={onClickAdd}>
                            <Text weight={500} mb={8} size="sm">
                                Upload Car Pictures (Front,Back,Sides)
                                <Text component="span" color="red"> *</Text>
                            </Text>
                            <Button size="xs" ml={80}>
                                Add
                            </Button>
                            {/* <SquarePlus size={30} color="#444" className={classes.addBtn1} /> */}
                        </Box>
                        <Box className={classes.thumbnail1} sx={{position: 'relative'}}>
                            {loading.carImg && <CircularLoader/>}
                            <Grid>
                                {imgArr.map((i: any, x: number) => (
                                    <React.Fragment key={x}>
                                        <Grid.Col md={4} lg={4} sm={12} xs={12}>
                                            {i.imgSrc ? (
                                                <Box className={classes.imagePrevieBox}>
                                                    <ImagePreview
                                                        src={i.imgSrc}
                                                        onCloseImage={onCloseImage}
                                                        type="multiple"
                                                        index={x}
                                                    />
                                                </Box>
                                            ) : (
                                                <>
                                                    <Box
                                                        onClick={() => handleImageClick(x)}
                                                        className={classes.uploadImageBox1}
                                                    >
                                                        <FileUpload size={48} color="#444"/>
                                                        <input
                                                            accept="image/*"
                                                            id="audioFile"
                                                            type="file"
                                                            // multiple
                                                            hidden
                                                            ref={(el) => (hiddenImageInput.current[x] = el)}
                                                            onChange={(e) => onSelectImage(e, x)}
                                                        />
                                                    </Box>
                                                </>
                                            )}
                                            {/* </Grid.Col>
                    </Grid> */}
                                            <Box className={classes.deleteIcon}>
                                                <Archive
                                                    className={classes.closeIcon}
                                                    onClick={() => onClickDelete(x)}
                                                />
                                            </Box>
                                        </Grid.Col>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Box>
                    </Grid.Col>
                </Grid>
                <Divider mt={16}/>
                <Grid justify="flex-end" pt={24} pb={16} pr={8}>
                    <Button mr={8} onClick={onDiscard}>
                        Discard
                    </Button>
                    <Button onClick={editCab.isEditing ? onUpdate : onSubmit}>
                        Save Details
                    </Button>
                </Grid>
            </Paper>
        </>
    );
};

export default AddNewCab;
