import {createSlice} from "@reduxjs/toolkit";

interface userDefaultState {
    volunteerName: string;
}

const initialState: userDefaultState = {
    volunteerName: "",
};
const userStateSlice = createSlice({
    name: "volunteerData",
    initialState,
    reducers: {
        setvolunteerName: (state, action) => {
            state.volunteerName = action.payload;
        },

    },
});

export const {
    setvolunteerName,
} = userStateSlice.actions;

export default userStateSlice.reducer;
