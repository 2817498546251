import {Box, Button, Grid, Input, Modal, Paper, Select, Table, Text, TextInput} from "@mantine/core";
import Heading from "../../../components/heading/Heading";
import {useStyles} from "./CabStatus.styles";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store/store";
import {Qrcode} from "tabler-icons-react";
import {QrReader} from "react-qr-reader";
import CircularLoader from "../../../components/loader/CircularLoader";
//"How_to_use_icon.svg.png_24e6652d-4848-4514-8de8-e66844b2cf3d.png"
const CabSearchScreen = ({
                             onClickOpen,
    isLoading,
                         }: any) => {
    const [regNumber, onChangeRegNumber] = React.useState("");
    const [openQr, setOpenQr] = useState(false);
    const [data, setData] = useState("No result");
    const [text, onChangeText] = React.useState("Useless Text");
    const state = useSelector((state: RootState) => state.cabs);
    const [vehicleNo, setVehicleNo] = useState("");
    const {classes} = useStyles();
    const onChangeVehicleeNo = (value: any) => {
        setVehicleNo(value);
    };

    const customStyles = {
        option: (provided:any, state:any) => ({
            ...provided,
            backgroundColor: state.isSelected ? state.data.color : 'white',
        }),
    };
    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Heading title="Cab Status Update"/>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                {isLoading && <CircularLoader/>}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <Box className={classes.qrSccannerBox}>
                        <Select
                            searchable
                            label="Vehicle No"
                            placeholder="Pick one"
                            value={vehicleNo}
                            onChange={onChangeVehicleeNo}
                            data={state.vehiclesList.vehicles.filter((c)=>c.enabled).map((i: any) => ({
                                value: i.registration_number,
                                label: i.registration_number + (i.commercial ? " (c)":""),
                                background: "blue"
                            }))}
                        />
                            <Qrcode size={40} onClick={() => setOpenQr(true)} style={{cursor: 'pointer'}}/>
                        </Box>

                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={3}>

                        <Button ml={16} mt={28} onClick={() => onClickOpen(vehicleNo)}>
                            Open
                        </Button>
                    </Grid.Col>
                </Grid>
                {openQr && (
                    <Modal centered opened={openQr} onClose={() => setOpenQr(false)} title="Scan Qr">
                        <QrReader
                            onResult={(result: any, error) => {

                                if (result) {
                                    console.log('result from qr scanner', result)
                                    setData(result?.text)
                                    onClickOpen(result?.text);
                                }

                                if (error) {
                                    console.info(error);
                                }
                            }}
                            constraints={{facingMode: "environment"}}
                            videoStyle={{width: "100%"}}
                        />
                        <p>{data}</p>
                    </Modal>
                )}
            </Paper>
        </>
    );
};

export default CabSearchScreen;
