import axios from "axios";
import {downLoadImageUrl, uploadImageFile} from "../api/api";
import {AxiosWithToken} from "../api/api-config";
import {AppThunk, store} from "../redux/store/store";

export const getFileExtension = (filename: string): string =>
    filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
    filename;

export function fileToUint8Array(file: File) {
    let reader = new FileReader();
    return new Promise<Uint8Array>((resolve, reject) => {
        try {
            reader.readAsArrayBuffer(file);
            reader.onloadend = (evt: any) => {
                if (evt.target.readyState === FileReader.DONE) {
                    let arrayBuffer = evt.target.result,
                        arr = new Uint8Array(arrayBuffer);
                    resolve(arr)
                }
            };
        } catch (e) {
            reject(e);
            console.log("something went wrong", e);
        }
    });
}

export const uploadFile = async (file: any) => {
    console.log("file", file);
    const data = {
        signed_url_details: [
            {
                file_name: file.name,
                content_type: `image/${getFileExtension(file.name)}`,
                signed_url: "",
                object_path: "",
            },
        ],
    };
    return uploadImage(file, data);
};

const uploadImage = async (file: any, data: any) => {
    try {
        const res = await uploadImageFile(data);
        console.log("get file upload response by id", res.data);
        if (res && res.data.success) {
            const binaryData = await fileToUint8Array(file);
            //console.log('binary data',binaryData)
            //console.log('content type', `image/${getFileExtension(file.name)}`)
            const response = await axios.put(
                `${res.data.payload.signed_url_details[0].signed_url}`,
                binaryData,
                {
                    headers: {
                        "Content-Type": `image/${getFileExtension(file.name)}`,
                    },
                }
            );
            if (response && res.status === 200) {
                //downloadImage(res.data.payload.signed_url_details[0].object_path)
                return res.data.payload.signed_url_details[0].object_path;
            }
            //console.log("response image", response);
        }
    } catch (error) {
        console.log("error", error);
    }
};
export const downloadImage = async (data: string) => {
    try {
        const res = await downLoadImageUrl([data]);
        //console.log("get file download response by ", res.data);
        if (res && res.data.success) {
            return res.data.payload;
        }
    } catch (error) {
        console.log("error", error);
    }
};
