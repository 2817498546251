import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AdminUser, Route} from "../../@types";

export interface AdminUserList {
    users: AdminUser[];
    loading: boolean;
    visitorEstimationReports: {
        loading: boolean,
        reports: []
    }

}

const initialState: AdminUserList = {
    users: [],
    loading: false,
    visitorEstimationReports: {
        loading: false,
        reports: []
    }
};
const userStateSlice = createSlice({
    name: "userList",
    initialState,
    reducers: {
        setUserList: (state, action:PayloadAction<AdminUser[]>) => {
            state.users = action.payload;
            state.loading = false;
        },
        setUserListLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setVisitorEstimationReports: (state, action) => {
            state.visitorEstimationReports.loading = false;
            state.visitorEstimationReports.reports = action.payload;
        },
        setLoadingVisitorEstimationReports: (state, action:PayloadAction<boolean>) => {
            state.visitorEstimationReports.loading = action.payload;
        },
    },
});

export const {
    setUserList,
    setUserListLoading,
    setVisitorEstimationReports,
    setLoadingVisitorEstimationReports
} = userStateSlice.actions;

export default userStateSlice.reducer;

