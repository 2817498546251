import React, {useEffect, useState} from "react";
import {useStyles} from "./VisitorsEstimation.styles";
import {Box, Grid, Paper, Select, Table, Text,} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import Heading from "../../../components/heading/Heading";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store/store";
import {getAllLocationsAction} from "../../../redux/actions/location.action";
import dayjs from "dayjs";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import {getVisitorEstimationReports} from "../../../redux/actions/user.action";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";

export const elements = [
    {title: "Number of cabs needed", noOfCabsAvailable: 100},
    {title: "Number of visitors arriving", noOfCabsAvailable: 60},
    {title: "# of cabs arriving", noOfCabsAvailable: 80},
    {title: "# of cabs reached", noOfCabsAvailable: 80},
];
const VisitorsEstimationScreen = () => {
    const {classes} = useStyles();
    const [destinationPoint, setDestinationPoint] = useState("");
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState("0");
    const dispatch = useDispatch();

    const reports = useSelector((state: RootState) => state.user.visitorEstimationReports.reports);
    const loading = useSelector((state: RootState) => state.user.visitorEstimationReports.loading);
    const locationsState = useSelector((state: RootState) => state.location);

    const endDate = new Date(date);
    endDate.setDate(date?.getDate() + 1);
    useEffect(() => {
        dispatch(getAllLocationsAction());
        dispatch(
            getVisitorEstimationReports(
                dayjs(date).format("YYYY-MM-DD"),
                dayjs(endDate).format("YYYY-MM-DD"),
                destinationPoint
            )
        );
    }, [dispatch, date, destinationPoint, time]);


    // var configDetails = []
    // config?.value.forEach((value: any, index: any, array: any)=> configDetails.push({ value: value , label: value}))
    //
    let locations = locationsState.availableLocationList.locations.map((l)=>l.place);

    locations = locations.filter((l, index) => {
        return locations.indexOf(l) == index;
    });

    const onDestinationChange =(value: any) => {
        setDestinationPoint(value);
    }

    const onDatechange = (value: any) => {
        setDate(value);
    };

    const rows = reports.length ? reports
        .map((i: any, x: number) => (
            <tr key={x}>
                <td>{i.date}</td>
                <td>{i.time}:00</td>
                <td>{i.destination}</td>
                <td>{i.expected_visitors}</td>
                <td>{parseInt("" + Math.ceil(i.expected_visitors / 3.0), 10)}</td>
                <td>{i.cabs_arriving}</td>
                <td>{i.cabs_reached}</td>
            </tr>
        )) : <tr>
        <td colSpan={4}>
            <NotEnoughData/>
        </td>
    </tr>

    return (
        <>
            <Box py="md">
                <Box>
                    <Heading title="  Visitors Arrival Estimation"/>
                </Box>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <DatePicker
                            placeholder="Pick date"
                            label="Date"
                            required
                            value={date}
                            onChange={onDatechange}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <Select
                            allowDeselect
                            clearable
                            searchable
                            label="Location"
                            placeholder="Pick one"
                            onChange={(value: string) => onDestinationChange(value)}
                            data={
                                locations?.map((i: any) => ({
                                    value: String(i),
                                    label: i,
                                }))
                            }
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={3} pt={20}>
                        <h6 > Note: Departure time is adjusted by 4 hours based on flight schedules</h6>
                    </Grid.Col>
                </Grid>
                {loading ? SkeletonLoader() :
                <div style={{overflowX: "auto"}}>
                    <Table highlightOnHover mt={24} className={classes.table}>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Arrival Location</th>
                            <th>Number of Visitors Arriving</th>
                            <th>Numbers of Cabs Needed</th>
                            <th># of Cabs Arriving</th>
                            <th># of Cabs Reached</th>
                        </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                </div>}
            </Paper>
        </>
    );
};

export default VisitorsEstimationScreen;
