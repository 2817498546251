import React, {useEffect, useState} from "react";
import RouteConfigurationScreen from "./RouteConfigurationScreen";
import {routeConfigScreen} from "../../../shared/constants";
import {showNotification} from "@mantine/notifications";
import AddNewRoute from "./AddNewRoute";
import {useDispatch, useSelector} from "react-redux";
import {getAllRoutes} from "../../../redux/actions/route.action";
import {RootState} from "../../../redux/store/store";
import {addRoute, deleteRouteById, updateRoute} from "../../../api/api";
import dayjs from "dayjs";
import {getConfigs} from "../../../redux/actions/config.action";
import {getAllLocationsAction} from "../../../redux/actions/location.action";

const RouteConfiguration = () => {
    const dispatch = useDispatch();
    const routeLists = useSelector((state: RootState) => state.route.routesList);
    const locationState = useSelector((state: RootState) => state.location.availableLocationList);
    const [currentScreen, setCurrentScreen] = useState(
        routeConfigScreen.ALL_ROUTE_CONFIGS_SCREEN
    );
    const [mode, setMode] = useState("");
    const [source, setSource] = useState({});
    const [destination, setDestination] = useState({});
    const [cost, setCost] = useState("");
    const [costNonCommercial, setCostNonCommercial] = useState("");
    const [timeDuration, setTimeDuration] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [editRoute, setEditRoute] = useState<any>({
        isEditing: false,
        editItem: null,
    });
    const [enabled, setEnabled] = useState<string>("true");
    const [archive, setArchive] = useState<string>("false");
    // console.log('unique1',sourceStopsData)
    useEffect(() => {
        dispatch(getAllRoutes());
        dispatch(getConfigs());
        dispatch(getAllLocationsAction());
        // dispatch(getRouteDestinations());

    }, [dispatch]);

    const onChangeEnabled = (e: any) => {
        setEnabled(e);
    };

    const onChangeArchive= () => {
        setArchive(archive==="false"? "true": "false");
    };

    const onClickAddNew = () => {
        setEditRoute({isEditing: false});
        setCurrentScreen(routeConfigScreen.ADD_ROUTE_CONFIGS_SCREEN);
    };
    const onClickBack = () => {
        onDiscard();
        setCurrentScreen(routeConfigScreen.ALL_ROUTE_CONFIGS_SCREEN);
    };
    const onChangeMode = (value: any) => {
        setMode(value);
    };
    const onChangeSource = (value: any) => {
        setSource(value);
    };
    const onChangeDestination = (value: any) => {
        setDestination(value);
    };
    const onChangeCost = (e: any) => {
        setCost(e.target.value);
    };

    const onChangeCostNonCommercial = (e: any) => {
        setCostNonCommercial(e.target.value);
    };
    const onChangeTimeDuration = (e: any) => {
        setTimeDuration(e.target.value);
    };


    const onClickEditBtn = (editRouteData: any) => {
        console.log(editRouteData)
        setEditRoute({isEditing: true, editItem: editRouteData});
        setSource(editRouteData.source.name)
        setDestination(editRouteData.destination.name)
        setCurrentScreen(routeConfigScreen.ADD_ROUTE_CONFIGS_SCREEN);
        setCostNonCommercial(editRouteData.fare_non_commercial)
        setCost(editRouteData.fare)
        setArchive("false")
    };
    const onClickDeleteBtn = async (id: any) => {
        console.log('delete route id', id);
        try {
            setIsLoading(true);
            const res = await deleteRouteById(id);
            console.log("delete route response", res.data);
            if (res.data && res.data.success) {
                setIsLoading(false);
                showNotification({
                    title: "Success",
                    message: "Route deleted successfully",
                    color: "green",
                });
                dispatch(getAllRoutes());
                //setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error", error);
            setIsLoading(false);
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }

    };

    const onOpenEditRoute = () => {
        setMode(editRoute.editItem.mode);
        console.log("here")
        console.log(editRoute.editItem)
        setSource(editRoute.editItem.source.id);
        setDestination(editRoute.editItem.destination.id);
        setCost(editRoute.editItem.fare);
        setCostNonCommercial(editRoute.editItem.fare_non_commercial)
        setTimeDuration(editRoute.editItem.time_estimates_minutes);
        setEnabled(editRoute.editItem.enabled)
        //setStop(editRoute.editItem.stop)
    };

    const onValidate = () => {
        // alert(JSON.stringify(availabilityDays))
        if (mode === "") {
            showNotification({
                title: "Error",
                message: "mode can not be empty",
                color: "red",
            });
        } else if (source === "") {
            showNotification({
                title: "Error",
                message: "source can not be empty",
                color: "red",
            });
        } else if (destination === "") {
            showNotification({
                title: "Error",
                message: "destination can not be empty",
                color: "red",
            });
        } else if (cost === "") {
            showNotification({
                title: "Error",
                message: "cost can not be empty",
                color: "red",
            });
        } else if (costNonCommercial === "") {
            showNotification({
                title: "Error",
                message: "cost non commercial can not be empty",
                color: "red",
            });
        } else if (timeDuration === "") {
            showNotification({
                title: "Error",
                message: "Time Durations can not be empty",
                color: "red",
            });
        } else {
            return true;
        }
    };
    const onSubmit = () => {
        if (onValidate()) {
            const data = {
                name: new Date().getTime(),
                source_id: source,
                destination_id: destination,
                time_estimates_minutes: Number(timeDuration),
                fare: Number(cost),
                mode: mode,
                fare_non_commercial: Number(costNonCommercial),
                enabled: enabled,
                archive: archive
            };
            _addRouteToServer(data);
        }
    };
    const _addRouteToServer = async (data: any) => {
        try {
            setIsLoading(true);
            const res = await addRoute(data);
            console.log("get add route response", res.data);
            if (res.data && res.data.success) {
                setIsLoading(false);
                showNotification({
                    title: "Success",
                    message: "Route added successfully",
                    color: "green",
                });
                dispatch(getAllRoutes());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error", error);
            setIsLoading(false);
            showNotification({
                title: "Error",
                message: error.message ? error.message : "Error in adding Route",
                color: "red",
            });
        }
    };
    const onUpdate = () => {
        if (onValidate()) {
            const data = {
                time_estimates_minutes: Number(timeDuration),
                fare: Number(cost),
                fare_non_commercial: Number(costNonCommercial),
                enabled: enabled,
                archive: archive
            };
            // debugger
            // alert(archive)
            console.log("add user data", data);
            _updateRouteToServer(data);
        }
    };

    const _updateRouteToServer = async (data: any) => {
        try {
            setIsLoading(true);
            const res = await updateRoute(editRoute.editItem.id, data);
            console.log("get update route response", res.data);
            if (res.data && res.data.success) {
                setIsLoading(false);
                showNotification({
                    title: "Success",
                    message: "Route updated successfully",
                    color: "green",
                });
                dispatch(getAllRoutes());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error", error);
            setIsLoading(false);
            showNotification({
                title: "Error",
                message: error.message ? error.message : "Error in adding Route",
                color: "red",
            });
        }
    };
    const onDiscard = () => {
        setMode("");
        setSource({});
        setDestination("");
        setCost("");
        setTimeDuration("");
        setCostNonCommercial("");
    };
    return (
        <>
            {currentScreen === routeConfigScreen.ALL_ROUTE_CONFIGS_SCREEN && (
                <RouteConfigurationScreen
                    onClickAddNew={onClickAddNew}
                    onClickEditBtn={onClickEditBtn}
                    routesList={routeLists}
                    onClickDeleteBtn={onClickDeleteBtn}
                    isLoading={isLoading}
                />
            )}
            {currentScreen === routeConfigScreen.ADD_ROUTE_CONFIGS_SCREEN && (
                <AddNewRoute
                    onClickBack={onClickBack}
                    onSubmit={onSubmit}
                    onUpdate={onUpdate}
                    onDiscard={onDiscard}
                    onChangeMode={onChangeMode}
                    onChangeSource={onChangeSource}
                    onChangeDestination={onChangeDestination}
                    onChangeCost={onChangeCost}
                    onChangeTimeDuration={onChangeTimeDuration}
                    onOpenEditRoute={onOpenEditRoute}
                    mode={mode}
                    source={source}
                    destination={destination}
                    cost={cost}
                    timeDuration={timeDuration}
                    editRoute={editRoute}
                    isLoading={isLoading}
                    locationsState={locationState}
                    enabled={enabled}
                    onChangeEnabled={onChangeEnabled}
                    archive={archive}
                    onChangeArchive={onChangeArchive}
                    costNonCommercial={costNonCommercial}
                    onChangeCostNonCommercial={onChangeCostNonCommercial}
                />
            )}
        </>
    );
};

export default RouteConfiguration;
