import {Box, Grid, Paper, Select, Table} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Heading from "../../../components/heading/Heading";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import {getHourlyReports} from "../../../redux/actions/cab.action";
//import { getDriverDataById } from "../../../redux/actions/driver.action";
import {RootState} from "../../../redux/store/store";
import {timeList} from "./hourlyReports.services";
import {useStyles} from "./HourlyReports.styles";
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const HourlyReportsScreen = () => {
    const {classes} = useStyles();
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState("");
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.cabs);

    useEffect(() => {
        dispatch(
            getHourlyReports(
                dayjs(date).format("YYYY-MM-DD"),
                dayjs(date).add(1, "day").format("YYYY-MM-DD")
            )
        );
    }, [dispatch, date]);
    useEffect(() => {
        const selectedTime = timeList.filter(
            (i: any) =>
                dayjs(new Date()).hour() >= Number(i.value) &&
                dayjs(new Date()).hour() < Number(i.value) + 2
        );
        setTime(selectedTime[0].value);
        //console.log('selectedTime',selectedTime);
    }, []);

    const onChangeDate = (value: any) => {
        setDate(value);
    };
    const onChangeTime = (value: any) => {
        //console.log("value", value);
        setTime(value);
    };

    const reportsByTime = state.hourlyReports.reports.length
        ? state.hourlyReports.reports.filter((i: any) => {
            // console.log('is report ',dayjs(i.start_time).hour() >= Number(time) &&
            // dayjs(i.start_time).hour() < Number(time) + 2);
            return (
                dayjs(i.start_time).hour() >= Number(time) &&
                dayjs(i.start_time).hour() < Number(time) + 2
            );
        })
        : [];
    //setData(reportsByTime);

    console.log("reportsByTime", reportsByTime);
    const rows = reportsByTime.length ? (
        reportsByTime.map((element: any, x: number) => {
            // getDriverNameById(element.driver_id);
            return (
                <tr key={x}>
                    <td>{element.cab_registration_number}</td>
                    <td>{element.name}</td>
                    <td>{element.trip_status}</td>
                    <td>{element.source}</td>
                    <td>{element.destination}</td>
                    <td>{dayjs(element.start_time).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>
                        {element.end_time
                            ? dayjs(element.end_time).format("YYYY-MM-DD HH:mm:ss")
                            : dayjs(element.expected_end_time).format("YYYY-MM-DD HH:mm:ss")}

                    </td>
                    <td>
                        {element.duration_minutes
                            ? element.duration_minutes
                            : element.end_time
                                ?
                                //  `${Math.ceil(dayjs(element.end_time).diff(element.start_time,'minute',true))} `
                                dayjs.duration(dayjs(element.end_time).diff(element.start_time)).format('HH:mm:ss')

                                : "__"}
                    </td>
                </tr>
            );
        })
    ) : (
        <tr>
            <td colSpan={8}>
                <NotEnoughData/>
            </td>
        </tr>
    );
    return (
        <>
            <Box py="md">
                <Box>
                    <Heading title="Hourly Reports"/>
                </Box>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <DatePicker
                            placeholder="Pick date"
                            label="Event date"
                            required
                            value={date}
                            onChange={onChangeDate}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <Select
                            searchable
                            label="Time"
                            placeholder="Pick one"
                            required
                            value={time}
                            onChange={onChangeTime}
                            data={timeList}
                        />
                    </Grid.Col>
                </Grid>
                <div style={{overflowX: "auto", height: "100%", marginTop: "24px"}}>
                    <Table highlightOnHover className={classes.table}>
                        <thead>
                        <tr>
                            <th>Cab #</th>
                            <th>Driver Name</th>
                            <th>Trip Status</th>
                            <th>Source</th>
                            <th>Destination</th>
                            <th>Start-time</th>
                            <th>End-time/Estimated Arrival Time</th>
                            <th>
                                Trip duration
                            </th>
                        </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                </div>
            </Paper>
        </>
    );
};

export default HourlyReportsScreen;
