import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AdminUser, BusReportPayload, Cab, CabTrip, CabWiseReportPayload} from "../../@types";

interface cabDefaultState {
    vehiclesList: {
        loading: boolean,
        vehicles: Cab[]
    },
    cabWiseReport: {
        loading: boolean,
        cabs: CabWiseReportPayload[]
    }
    busReport: {
        loading: boolean,
        buses: BusReportPayload[]
    },
    cabTrips: {
        loading: boolean,
        cabs: CabTrip[]
    },
    hourlyReports: {
        loading: boolean,
        reports: any
    },
    vehicleEstimationReports: {
        loading: boolean,
        reports: any
    },
    searchCabs: {
        loading: boolean,
        cab?: Cab
    },
    cabArrivalsReport: {
        loading: boolean,
        reports: Cab[]
    }

}

const initialState: cabDefaultState = {
    vehiclesList: {
        loading: false,
        vehicles: []
    },
    cabWiseReport: {
        loading: false,
        cabs: []
    },
    busReport: {
        loading: false,
        buses: []
    },
    cabTrips: {
        loading: false,
        cabs: []
    },
    hourlyReports: {
        loading: false,
        reports: []
    },
    vehicleEstimationReports: {
        loading: false,
        reports: []
    },
    searchCabs: {
        loading: false,
    },
    cabArrivalsReport: {
        loading: false,
        reports: []
    }
};
const cabStateSlice = createSlice({
    name: "cabList",
    initialState,
    reducers: {
        setLoadingVehiclesList: (state, action:PayloadAction<boolean>) => {
            state.vehiclesList.loading = action.payload;
        },
        setVehiclesList: (state, action: PayloadAction<Cab[]>) => {
            state.vehiclesList.loading = false;
            state.vehiclesList.vehicles = action.payload;
        },
        setLoadingCabWiseReports: (state, action:PayloadAction<boolean>) => {
            state.cabWiseReport.loading = action.payload;
        },
        setCabWiseReports: (state, action: PayloadAction<CabWiseReportPayload[]>) => {
            state.cabWiseReport.loading = false;
            state.cabWiseReport.cabs = action.payload;
        },
        setLoadingBusReports: (state, action:PayloadAction<boolean>) => {
            state.busReport.loading = action.payload;
        },
        setBusReports: (state, action:PayloadAction<BusReportPayload[]>) => {
            state.busReport.loading = false;
            state.busReport.buses = action.payload;
        },
        setLoadingCabTrips: (state, action:PayloadAction<boolean>) => {
            state.cabTrips.loading = action.payload;
        },
        setCabTrips: (state, action: PayloadAction<CabTrip[]>) => {
            state.cabTrips.loading = false;
            state.cabTrips.cabs = action.payload;
        },
        setLoadingHourlyReports: (state, action:PayloadAction<boolean>) => {
            state.hourlyReports.loading = action.payload;
        },
        setHourlyReports: (state, action) => {
            state.hourlyReports.loading = false;
            state.hourlyReports.reports = action.payload;
        },
        setLoadingVehicleEstimationReports: (state, action:PayloadAction<boolean>) => {
            state.vehicleEstimationReports.loading = action.payload;
        },
        setVehicleEstimationReports: (state, action) => {
            state.vehicleEstimationReports.loading = false;
            state.vehicleEstimationReports.reports = action.payload;
        },
        setLoadingSearchCabs: (state, action:PayloadAction<boolean>) => {
            state.searchCabs.loading = action.payload;
        },
        setSearchCabsResult: (state, action) => {
            state.searchCabs.loading = false;
            state.searchCabs.cab = action.payload.length > 0 ? action.payload[0] : null
        },
        setLoadingCabArrivalsReports: (state, action:PayloadAction<boolean>) => {
            state.cabArrivalsReport.loading = action.payload;
        },
        setCabArrivalsReports: (state, action:PayloadAction<Cab[]>) => {
            state.cabArrivalsReport.loading = false;
            state.cabArrivalsReport.reports = action.payload;
        },
    },
});

export const {

    setVehiclesList,

    setCabWiseReports,

    setBusReports,

    setCabTrips,

    setVehicleEstimationReports,

    setSearchCabsResult,
    setCabArrivalsReports,
    setLoadingCabArrivalsReports,
    setLoadingHourlyReports,
    setLoadingVehicleEstimationReports,
    setLoadingSearchCabs,
    setHourlyReports,
    setLoadingCabWiseReports,
    setLoadingCabTrips,
    setLoadingVehiclesList,
    setLoadingBusReports,
} = cabStateSlice.actions;

export default cabStateSlice.reducer;
