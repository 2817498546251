import {ReactElement, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "../../components/dashboard/Dashboard";
import ProtectedRoute from "../../components/ProtectedRoute";
import AllocationOfBusScreen from "./allocationOfVehicles/AllocationOfBusScreen";
import BusReportsScreen from "./busReports/BusReportsScreen";
import CabRegistration from "./cabRegistration";
import CabWiseReportScreen from "./cabWiseReports/CabWiseReportsScreen";
import Config from "./configScreen";
import DriverRegistration from "./driverRegistration";
import DriverReportsScreen from "./driverReports/DriverReportsScreen";
import HourlyReportsScreen from "./hourlyReports/HourlyReportsScreen";
import RouteConfiguration from "./routeConfiguration";
import TrackingVehicleArrivals from "./trackingVehiclesArrivals";
import TrainConfiguration from "./trainConfiguration/TrainConfiguration";
import User from "./user";
import VehicleEstimationReportScreen from "./vehicleEstimation/VehicleEstimationReportScreen";
import VisitorsEstimationScreen from "./visitorsEstimation/VisitorsEstimationScreen";
import CabStatus from "./cabStatusUpdate";
import LocationConfigurationScreen from "./locationConfiguration/LocationConfigurationScreen";
import LocationConfiguration from "./locationConfiguration";
import Prebooking from "./preBooking";

export default function AppScreen(): ReactElement {
    const data = localStorage.getItem("user");
    //console.log('data in app screen', data);
    const user = data ? JSON.parse(data) : null;
    console.log("ROLES")
    console.log(user)
    return (
        <Dashboard>
            <>
                <Routes>
                    <Route
                        path="/"
                        element={
                            user && user.roles && user.roles.includes("ADMIN") ? (
                                <Navigate to="routeConfiguration" replace/>
                            ) : (
                                <Navigate to="vehicleEstimationReport" replace/>
                            )
                        }
                    />
                    <Route
                        path="/vehicleEstimationReport"
                        element={
                                <VehicleEstimationReportScreen/>
                        }
                    />
                    <Route
                        path="/cabStatusUpdate"
                        element={

                                <CabStatus/>
                        }
                    />
                    <Route
                        path="/visitorsEstimation"
                        element={

                                <VisitorsEstimationScreen/>
                        }
                    />
                    <Route
                        path="/trackingVehiclesArrivals"
                        element={

                                <TrackingVehicleArrivals/>
                        }
                    />
                    <Route
                        path="/allocationOfBus"
                        element={

                                <AllocationOfBusScreen/>
                        }
                    />
                    <Route
                        path="/routeConfiguration"
                        element={

                                <RouteConfiguration/>
                        }
                    />
                    <Route
                        path="/locationConfiguration"
                        element={

                                <LocationConfiguration/>
                        }
                    />
                    <Route
                        path="/trainConfiguration"
                        element={

                                <TrainConfiguration/>
                        }
                    />
                    <Route
                        path="/cabRegistration"
                        element={
                                <CabRegistration/>
                        }
                    />
                    <Route
                        path="/driverRegistration"
                        element={

                                <DriverRegistration/>
                        }
                    />
                    <Route
                        path="/config"
                        element={

                                <Config/>
                        }
                    />
                    <Route
                        path="/cabWiseReport"
                        element={

                                <CabWiseReportScreen/>
                        }
                    />
                    <Route
                        path="/driverReports"
                        element={

                                <DriverReportsScreen/>
                        }
                    />
                    <Route
                        path="/hourlyReports"
                        element={

                                <HourlyReportsScreen/>
                        }
                    />
                    <Route
                        path="/busReports"
                        element={

                                <BusReportsScreen/>
                        }
                    />
                    <Route
                        path="/preBooking"
                        element={

                            <Prebooking/>
                        }
                    />
                    <Route path="/user" element={<User/>}/>
                </Routes>
            </>
        </Dashboard>
    );
}
