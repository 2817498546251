import {
    Bus,
    Gauge,
    Report,
    CurrentLocation,
    Man,
    Route,
    Train,
    Car,
    UserCheck,
    SettingsAutomation,
    FileReport,
    ReportAnalytics,
    UserCircle,
} from "tabler-icons-react";
import React from "react";

export const adminNavItems = [
    {
        href: "user",
        icon: UserCircle,
        title: "Users",
        mobileViewTitle: "Users",
    }, {
        href: "locationConfiguration",
        icon: Route,
        title: "Locations",
        mobileViewTitle: "Locations",
    },
    {
        href: "routeConfiguration",
        icon: Route,
        title: "Routes",
        mobileViewTitle: "Routes",
    },
    {
        href: "cabRegistration",
        icon: Car,
        title: "Cabs Registration",
        mobileViewTitle: "Cabs",
    },
    {
        href: "driverRegistration",
        icon: UserCheck,
        title: "Drivers Registration",
        mobileViewTitle: "Drivers",
    },
    {
        href: "config",
        icon: SettingsAutomation,
        title: "Config",
        mobileViewTitle: "config",
    },
    {
        href: "cabWiseReport",
        icon: FileReport,
        title: "Cabs Report",
        mobileViewTitle: "Cabs Report",
    },
    {
        href: "driverReports",
        icon: ReportAnalytics,
        title: " Drivers Report",
        mobileViewTitle: "Drivers Report",
    },
    {
        href: "hourlyReports",
        icon: Report,
        title: "Trip Report",
        mobileViewTitle: "Trips Report",
    },
    {
        href: "busReports",
        icon: Report,
        title: "Bus Report",
        mobileViewTitle: "Bus Report",
    },
];
export const navItems = [
    {
        href: "visitorsEstimation",
        icon: Man,
        title: "Visitors Arrival Estimation",
        mobileViewTitle: "Visitors",
    },
    {
        href: "trackingVehiclesArrivals",
        icon: CurrentLocation,
        title: "Tracking Cab Arrivals",
        mobileViewTitle: "Tracking",
    },
    {
        href: "cabStatusUpdate",
        icon: CurrentLocation,
        title: "Update Cab Status",
        mobileViewTitle: "Cab Status",
    },
    {
        href: "preBooking",
        icon: Car,
        title: "Prebooking Of Cabs",
        mobileViewTitle: "Prebooking",
    },
    {
        href: "allocationOfBus",
        icon: Bus,
        title: "Allocation Of Bus",
        mobileViewTitle: "Bus Allocation",
    },
];
