import {createSlice, PayloadAction,} from "@reduxjs/toolkit";
import {configPayload} from "../../@types";

interface driverDefaultState {
    configLists: {
        loading: boolean;
        configs: configPayload[];
    };
}

const initialState: driverDefaultState = {
    configLists: {
        loading: false,
        configs: [],
    },
};
const configStateSlice = createSlice({
    name: "configs",
    initialState,
    reducers: {
        setLoadingConfigsList: (state, action:PayloadAction<boolean>) => {
            state.configLists.loading = action.payload;
        },
        setConfigLists: (state, action) => {
            state.configLists.configs = action.payload;
            state.configLists.loading = false;
        }
    },
});

export const {
    setLoadingConfigsList,
    setConfigLists,
} = configStateSlice.actions;

export default configStateSlice.reducer;
