import {Table, Select, Grid, Paper, Box, Text} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
//import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Heading from "../../../components/heading/Heading";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import {
    getAllCabs,
    getAllCabTrips,
    getVehicleEstimationReports,
} from "../../../redux/actions/cab.action";
import {RootState} from "../../../redux/store/store";
import {useStyles} from "./VehicleEstimationReport.styles";
import {getAllLocationsAction} from "../../../redux/actions/location.action";

export const elements = [
    {
        startTime: 0,
        endTime: 2,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 2,
        endTime: 4,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 6,
        endTime: 8,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 8,
        endTime: 10,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 10,
        endTime: 12,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 12,
        endTime: 14,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 14,
        endTime: 16,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 16,
        endTime: 18,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 18,
        endTime: 20,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 20,
        endTime: 22,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
    {
        startTime: 22,
        endTime: 24,
        noOfCabsAvailable: 100,
        noOfVisitors: 50,
        noOfCabsRequired: 50,
    },
];

const VehicleEstimationReportScreen = () => {
    const [destinationPoint, setDestinationPoint] = useState<any>(null);
    const [date, setDate] = useState(new Date());
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const cabTripsList = useSelector((state: RootState) => state.cabs);
    const cabList = cabTripsList.cabTrips.cabs.filter(
        (i: any) => i.trip_type === destinationPoint
    );
    const locationState = useSelector((state: RootState) => state.location);
    const locations = locationState.locationList.locations.filter(
        (i: any) => i.enabled === true
    );
    console.log("cabList", cabList);

    useEffect(() => {
        dispatch(getAllCabTrips());
        dispatch(getAllCabs());
        dispatch(getAllLocationsAction());
        dispatch(
            getVehicleEstimationReports(
                dayjs(date).format("YYYY-MM-DD"),
                dayjs(date).add(1,"day").format("YYYY-MM-DD"),
                destinationPoint
            )
        );
    }, [dispatch, date, destinationPoint]);

    const onDatechange = (value: any) => {
        setDate(value);
    };
    const report = cabTripsList.vehicleEstimationReports.reports;

    const rows = report.length ? (
        report.map(
            (element: any, x: number) => (
                <tr key={x}>
                    <td>{element.time}</td>
                    <td>{element.cabs_available}</td>
                    <td>{element.cabs_arriving}</td>
                    <td>{element.expected_visitors}</td>
                    <td>{Math.ceil(element.expected_visitors / 4)}</td>
                </tr>
            )
        )
    ) : (
        <tr>
            <td colSpan={5}>
                <NotEnoughData/>
            </td>
        </tr>
    );

    return (
        <>
            <Box py="md">
                <Box>
                    <Heading title="Vehicle Estimation Report"/>
                </Box>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <DatePicker
                            placeholder="Pick date"
                            label="Date"
                            required
                            value={date}
                            onChange={onDatechange}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <Select
                            searchable
                            label="Location"
                            placeholder="Pick one"
                            defaultValue={destinationPoint}
                            onChange={setDestinationPoint}
                            data={
                                locations.map((i: any) => ({
                                    value: i.id,
                                    label: i.name,
                                }))
                            }
                        />
                    </Grid.Col>
                </Grid>
                <div style={{overflowX: "auto", height: '100%', margin: '24px 0'}}>
                    <Table highlightOnHover className={classes.table}>
                        <thead>
                        <tr>
                            <th>Time</th>
                            <th>Number of Cabs Available</th>
                            <th>Number of Cabs Arriving</th>
                            <th>Number of Visitors</th>
                            <th>Numbers of Cabs Needed</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cabTripsList.vehicleEstimationReports.reports.length ? (
                            rows
                        ) : (
                            <tr>
                                <td colSpan={5}>
                                    <NotEnoughData/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
            </Paper>
        </>
    );
};

export default VehicleEstimationReportScreen;
