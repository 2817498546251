import React, {useEffect, useState} from "react";
import LocationConfigurationScreen from "./LocationConfigurationScreen";
import {locationConfigScreen} from "../../../shared/constants";
import {showNotification} from "@mantine/notifications";
import AddNewLocation from "./AddNewLocation";
import {useDispatch, useSelector} from "react-redux";
import {getAllLocationsAction} from "../../../redux/actions/location.action";
import {RootState} from "../../../redux/store/store";
import {addLocation, deleteLocationById, updateLocation} from "../../../api/api";
import CircularLoader from "../../../components/loader/CircularLoader";

const LocationConfiguration = () => {
    const dispatch = useDispatch();
    const locationStore = useSelector((state: RootState) => state.location);
    const [currentScreen, setCurrentScreen] = useState(
        locationConfigScreen.ALL_LOCATION_CONFIGS_SCREEN
    );
    const [place, setPlace] = useState("");
    const [subPlace, setSubPlace] = useState("");
    const [enabled, setEnabled] = useState<string>();
    const [text, setText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [glink, setGlink] = useState("");
    const [lat, setLat] = useState("");
    const [lon, setLon] = useState("");

    const [editLocation, setEditLocation] = useState<any>({
        isEditing: false,
        editItem: null,
    });
    // console.log('unique1',sourceStopsData)
    useEffect(() => {
        dispatch(getAllLocationsAction());
    }, [dispatch]);

    const onClickAddNew = () => {
        setEditLocation({isEditing: false});
        setCurrentScreen(locationConfigScreen.ADD_LOCATION_CONFIGS_SCREEN);
    };
    const onClickBack = () => {
        onDiscard();
        setCurrentScreen(locationConfigScreen.ALL_LOCATION_CONFIGS_SCREEN);
    };


    const onChangePlace = (e: any) => {
        setPlace(e.target.value)
    };

    const onChangeSubPlace = (e: any) => {
        setSubPlace(e.target.value)
    };
    const onChangeEnabled = (e: any) => {
        setEnabled(e);
    };
    const onChangeText = (e: any) => {
        setText(e.target.value);
    };

    const onOpenEditLocation = () => {
        setEnabled(editLocation.editItem.enabled);
        setText(editLocation.editItem.name);
        setPlace(editLocation.editItem.place);
        setSubPlace(editLocation.editItem.sub_place);
        setEnabled(String(editLocation.editItem.enabled))
        setGlink(editLocation.editItem.glink)
        setLat(editLocation.editItem.lat)
        setLon(editLocation.editItem.lon)
        //setStop(editRoute.editItem.stop)
    };


    const onClickEditBtn = (editRouteData: Location) => {
        setEditLocation({isEditing: true, editItem: editRouteData});
        setCurrentScreen(locationConfigScreen.ADD_LOCATION_CONFIGS_SCREEN);
    };
    const onClickDeleteBtn = async (id: any) => {
        console.log('delete location id', id);
        try {
            setIsLoading(true);
            const res = await deleteLocationById(id);
            console.log("delete route response", res.data);
            if (res.data && res.data.success) {
                setIsLoading(false);
                showNotification({
                    title: "Success",
                    message: "Route deleted successfully",
                    color: "green",
                });
                dispatch(getAllLocationsAction());
                //setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error", error);
            setIsLoading(false);
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }

    };


    const onValidate = () => {
        console.log(place)
        if (place === "" ) {
            showNotification({
                title: "Error",
                message: "place can not be empty ",
                color: "red",
            });
        } else if (subPlace === "") {
            showNotification({
                title: "Error",
                message: "sub place can not be empty",
                color: "red",
            });
        } else if (text === "") {
            showNotification({
                title: "Error",
                message: "destination can not be empty",
                color: "red",
            });
        } else if (glink === "") {
            showNotification({
                title: "Error",
                message: "gclink can not be empty",
                color: "red",
            })
        } else if(lat === ""){
            showNotification({
                title: "Error",
                message: "latitude can not be empty",
                color: "red",
            })
        } else if(lon === ""){
            showNotification({
                title: "Error",
                message: "longitude can not be empty",
                color: "red",
            })
        } else {
            return true;
        }
    };
    const onSubmit = () => {
        if (onValidate()) {
            const data = {
                place: place,
                sub_place: subPlace,
                name: text,
                enabled: enabled,
                glink:glink,
                lat:lat,
                lon:lon,
            };
            _addLocationToServer(data);
        }
    };
    const _addLocationToServer = async (data: any) => {
        try {
            setIsLoading(true);
            const res = await addLocation(data);
            console.log("get add location response", res.data);
            if (res.data && res.data.success) {
                setIsLoading(false);
                showNotification({
                    title: "Success",
                    message: "Location added successfully",
                    color: "green",
                });
                dispatch(getAllLocationsAction());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error", error);
            setIsLoading(false);
            showNotification({
                title: "Error",
                message: error.message ? error.message : "Error in adding Location",
                color: "red",
            });
        }
    };
    const onUpdate = () => {
        // alert(enabled)
        if (onValidate()) {
            const data = {
                name: text,
                enabled: enabled,
                glink: glink,
                lat:lat,
                lon:lon,
            };
            console.log("update location", data);
            _updateLocationToServer(data);
        }
    };

    const _updateLocationToServer = async (data: any) => {
        try {
            setIsLoading(true);
            const res = await updateLocation(editLocation.editItem.id, data);
            console.log("get update location response", res.data);
            if (res.data && res.data.success) {
                setIsLoading(false);
                showNotification({
                    title: "Success",
                    message: "Route updated successfully",
                    color: "green",
                });
                dispatch(getAllLocationsAction());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error", error);
            setIsLoading(false);
            showNotification({
                title: "Error",
                message: error.message ? error.message : "Error in adding Route",
                color: "red",
            });
        }
    };
    const onDiscard = () => {
        setEditLocation({isEditing: false});
        setText("");
        setPlace("");
        setSubPlace("");
        setEnabled("true");
        setGlink("")
        setLat("")
        setLon("")
    };
    return (
        <>   {isLoading && <CircularLoader/>}
            {currentScreen === locationConfigScreen.ALL_LOCATION_CONFIGS_SCREEN && (
                <LocationConfigurationScreen
                    onClickAddNew={onClickAddNew}
                    onClickEditBtn={onClickEditBtn}
                    locationList={locationStore.locationList}
                    onClickDeleteBtn={onClickDeleteBtn}
                />
            )}
            {currentScreen === locationConfigScreen.ADD_LOCATION_CONFIGS_SCREEN && (
                <AddNewLocation
                    onClickBack={onClickBack}
                    onSubmit={onSubmit}
                    onUpdate={onUpdate}
                    onDiscard={onDiscard}
                    onOpenEditLocation={onOpenEditLocation}
                    place={place}
                    subPlace={subPlace}
                    text={text}
                    enabled={enabled}
                    onChangePlace={onChangePlace}
                    onChangeSubPlace={onChangeSubPlace}
                    onChangeText={onChangeText}
                    onChangeEnabled={onChangeEnabled}
                    isLoading={isLoading}
                    editLocation={editLocation}
                    glink={glink}
                    onChangeGlink={(v:any)=>setGlink(v.target.value)}
                    lat={lat}
                    onChangeLat={(v:any)=>setLat(v.target.value)}
                    lon={lon}
                    onChangeLon={(v:any)=>setLon(v.target.value)}
                />
            )}
        </>
    );
};

export default LocationConfiguration;
