import {getDriverById, getDriverReport} from "../../api/api";
import {
    driverDataByIdloadingError,
    loadingDriverDataById,
    setDriverDataById,
    setDriverReport, setLoadingDriverReport
} from "../reducers/driver.reducer";
import {AppThunk} from "../store/store";

export const getDriverReports = (date: string): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingDriverReport(true));
        const res = await getDriverReport(date);
        console.log('get driver report response ', res.data)
        if (res && res.data.success) {
            dispatch(setDriverReport(res.data.payload.report))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingDriverReport(false));
    }
};

export const getDriverDataById = (driverId: string): AppThunk => async (dispatch) => {
    try {
        dispatch(loadingDriverDataById);
        const res = await getDriverById(driverId);
        console.log('get driver  response by id', res.data)
        if (res && res.data.success) {
            dispatch(setDriverDataById(res.data.payload))
            return res.data.payload;
        }
    } catch (error) {
        console.log("error", error);
        dispatch(driverDataByIdloadingError);
    }
};