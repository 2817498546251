import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AdminUser, Prebooking, Route} from "../../@types";

export interface PrebookingList {
    bookings: Prebooking[];
    loading: boolean;
}

const initialState: PrebookingList = {
    bookings: [],
    loading: false,
};
const preBookingSlice = createSlice({
    name: "prebookingList",
    initialState,
    reducers: {
        setPrebookingList: (state, action:PayloadAction<Prebooking[]>) => {
            state.bookings = action.payload;
            state.loading = false;
        },
        setPrebookingLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const {
    setPrebookingList,
    setPrebookingLoading,
} = preBookingSlice.actions;

export default preBookingSlice.reducer;

