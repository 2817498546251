import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    table: {
        '& tr': {
            border: '1px solid #dee2e6',
            borderCollapse: 'collapse',
        },
        '& td': {
            border: '1px solid #dee2e6',
            borderCollapse: 'collapse',
        },
        '& th': {
            border: '1px solid #dee2e6',
            borderCollapse: 'collapse',
        }

    },

}));
  