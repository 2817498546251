import React from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import {NotFoundPage} from "./pages/error/400";
import AppScreen from "./pages/app";
import {LogIn} from "./pages/auth/Login/login.screen";
import {useThemeDetector} from "./utils/helpers";
import {MantineProvider} from "@mantine/core";
import {darkTheme, lightTheme} from "./config/theme";
import {Provider} from "react-redux";
import {store} from "./redux/store/store";
import {NotificationsProvider} from "@mantine/notifications";
import PrivateRoute from "./components/PrivateRoute";
import {FeedbackPage} from "./pages/feedback/feedback";

function App() {
    const isDarkTheme = useThemeDetector();
    return (
        <MantineProvider theme={isDarkTheme ? darkTheme : lightTheme}>
            <NotificationsProvider position="top-right">
                <Provider store={store}>
                    <Router>
                        <Routes>
                            <Route path="/" element={<Navigate to='login' replace/>}/>
                            <Route path="login/*" element={<LogIn/>}/>
                            <Route
                                path="app/*"
                                element={
                                    <PrivateRoute>
                                        <AppScreen/>
                                    </PrivateRoute>
                                }
                            />
                            <Route path="home/*" element={<NotFoundPage/>}/>
                            <Route path="feedback" element={<FeedbackPage/>}/>
                        </Routes>
                    </Router>
                </Provider>
            </NotificationsProvider>
        </MantineProvider>
    );
}

export default App;
