import {
    Box,
    Button,
    Grid,
    Paper,
    Select,
    Table,
    Text,
    TextInput,
} from "@mantine/core";
import React from "react";
import Heading from "../../../components/heading/Heading";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import {useStyles} from "./Config.styles";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";

const ConfigScreen = ({onClickAddNew, configData, onClickEditBtn, isLoading}: any) => {
    const {classes} = useStyles();

    const rows = (data: any) => (
        <>
            {data.map((ele: any, x: number) => (
                <tr key={x}>
                    <td>{ele.key_name}</td>
                    {/* <td>{ele.value}</td> */}
                    <td>
                        {ele.value.map((i: any, x: number) => (
                            <React.Fragment key={x}>
                                <Text component="span">
                                    {x === ele.value.length - 1 ? i : `${i}, `}
                                </Text>
                            </React.Fragment>
                        ))}
                    </td>
                    <td>
                        <Button onClick={() => onClickEditBtn(ele)}>Edit</Button>
                    </td>
                </tr>
            ))}
        </>
    );
    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Heading title="Config"/>
                <Button ml={16} onClick={onClickAddNew}>
                    Add New Config
                </Button>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                {isLoading ? SkeletonLoader() :
                <div style={{overflowX: "auto"}}>
                    <Table highlightOnHover mt={24} className={classes.table}>
                        <thead>
                        <tr>
                            <th>Key Name</th>
                            <th>Value</th>
                            <th>Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        {configData.length ? (
                            rows(configData)
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <NotEnoughData/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>}
            </Paper>
        </>
    );
};

export default ConfigScreen;
