import {
    Box,
    Button,
    Divider,
    Grid,
    Group,
    MultiSelect,
    Paper,
    PasswordInput,
    Select,
    TextInput,
} from "@mantine/core";
import React, {useEffect} from "react";
import Heading from "../../../components/heading/Heading";
import {ArrowLeft} from "tabler-icons-react";
import CircularLoader from "../../../components/loader/CircularLoader";

const AddUser = ({
                     onClickBack,
                     onSubmit,
                     onUpdate,
                     onDiscard,
                     onChangeUserName,
                     onChangeEmail,
                     onChangeMobileNo,
                     onChangePassword,
                     onChangeRole,
                     onOpenEditUser,
                     userName,
                     hfn_id,
                     email,
                     mobileNo,
                     password,
                     role,
                     editUser,
                     onChangeHfnId,
                     loading,
                 }: any) => {
    useEffect(() => {
        if (editUser.isEditing) {
            onOpenEditUser();
        }
    }, [editUser.isEditing]);

    return (
        <>
            {loading && <CircularLoader/>}
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Box
                    mr={16}
                    onClick={onClickBack}
                    sx={{cursor: "pointer", display: "flex", alignItems: "center"}}
                >
                    <ArrowLeft/>
                </Box>
                <Heading title={editUser.isEditing ? "Edit User" : "Add User"}/>
            </Box>

            <Paper shadow="xs" p="md" mt={18}>
                <Grid>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="hfn id"
                            label="hfn id"
                            required
                            value={hfn_id}
                            onChange={onChangeHfnId}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="UserName"
                            label="UserName"
                            required
                            value={userName}
                            onChange={onChangeUserName}
                            disabled={editUser.isEditing}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="Email"
                            label="Email"
                            required
                            value={email}
                            onChange={onChangeEmail}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <TextInput
                            placeholder="Mobile No"
                            label="Mobile No"
                            type="number"
                            required
                            value={mobileNo}
                            maxLength={10}
                            onChange={onChangeMobileNo}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <PasswordInput
                            required
                            label="Password"
                            placeholder="Your password"
                            value={password}
                            onChange={onChangePassword}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} md={6} lg={6}>
                        <MultiSelect
                            searchable
                            label="Role"
                            placeholder="Pick one"
                            required
                            value={role}
                            onChange={onChangeRole}
                            data={[
                                {value: "ADMIN", label: "Admin"},
                                {value: "VOLUNTEER", label: "Volunteer"},
                            ]}
                        />
                    </Grid.Col>
                </Grid>
                <Divider mt={16}/>
                <Group position="right" pt={24} pb={16} pr={8}>
                    <Button mr={8} onClick={onDiscard}>
                        Discard
                    </Button>
                    <Button
                        mr={8}
                        onClick={() => {
                            console.log("edit user", editUser);
                            editUser.isEditing ? onUpdate(editUser.editItem.id) : onSubmit();
                        }}
                    >
                        Save
                    </Button>
                </Group>
            </Paper>
        </>
    );
};

export default AddUser;
