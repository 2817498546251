import React, {useEffect, useState} from "react";
import PrebookingListScreen from "./PrebookingListScreen";

const Prebooking = () => {



    return (
        <PrebookingListScreen/>
    );
};

export default Prebooking;
