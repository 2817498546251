import {Box, Button, Paper, Table, Text} from "@mantine/core";
import Heading from "../../../components/heading/Heading";
import {useStyles} from "./CabRegistration.styles";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";
import {Cab} from "../../../@types";
import React, {useEffect, useState} from "react";
import {reverseGeocode} from "../../../utils/helpers";
//"How_to_use_icon.svg.png_24e6652d-4848-4514-8de8-e66844b2cf3d.png"

const CabRegistrationScreen = ({
                                   onClickAddNew,
                                   onClickEditBtn,
                                   cabList,
                                   loading,
                                   onDownloadImage,
                               }: any) => {
    const {classes} = useStyles();
    // @ts-ignore
    const rows = cabList.vehicles.map((element: Cab, x: number) => (
        <tr key={x}>
            <td  style={{ backgroundColor: element.commercial ? 'yellow' : '',
                color: element.commercial ? 'black' : '',
                fontWeight: element.commercial ? 'bold' : 'normal',
            }}>
                {element.registration_number}
            </td>
            <td>{element.type}</td>
            <td>{element.area}</td>
            <td style={{background: `${element.enabled?"green":"red"}`}}>{element.enabled?"Active":"Inactive"}</td>
            <td>{element.commercial ? "Commercial": "Non Commercial"}</td>
            <td>{element.enabled?element.status: "--"}</td>
            <td>
                { element.location_name}
            </td>
            <td>
                <Box>
                    {element.pictures?.map((i: any, x: number) => (
                        <Text
                            color="blue"
                            lineClamp={1}
                            className={classes.linkText}
                            onClick={() => onDownloadImage(i)}
                            key={x}
                            my={4}
                        >
                            {"Link "+ (x+1)+" "}
                        </Text>
                    ))}
                </Box>
            </td>
            <td>
                <Text
                    color="blue"
                    lineClamp={1}
                    className={classes.linkText}
                    onClick={() => onDownloadImage(element.rc_photo)}
                >
                    {"Link"}
                </Text>
            </td>
            <td>
                <Text
                    color="blue"
                    lineClamp={1}
                    className={classes.linkText}
                    onClick={() => onDownloadImage(element.pollution_certificate_photo)}
                >
                    {"Link"}
                </Text>
            </td>
            <td>
                <Text
                    color="blue"
                    lineClamp={1}
                    className={classes.linkText}
                    onClick={() => onDownloadImage(element.taxi_permit_photo)}
                >
                    {"Link"}
                </Text>
            </td>
            <td>
                <Text
                    color="blue"
                    lineClamp={1}
                    className={classes.linkText}
                    onClick={() => onDownloadImage(element.qr_code_photo)}
                >
                    {"Link"}
                </Text>
            </td>
            <td>
                <Button onClick={() => onClickEditBtn(element)}>Edit</Button>
            </td>
        </tr>
    ));
    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Heading title="Cab Registration"/>
                <Button ml={16} onClick={onClickAddNew}>
                    Add New
                </Button>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                {loading ? SkeletonLoader() :
                    <div style={{overflowX: "auto", height: '72vh', marginTop: '24px'}}>
                        <Table highlightOnHover pt={4} className={classes.table}>
                            <thead>
                            <tr>
                                <th>Vehicle Number</th>
                                <th>Car Type</th>
                                <th>Car Area</th>
                                <th>Active</th>
                                <th>Commercial</th>
                                <th>Status</th>
                                <th>Current Location</th>
                                <th>Car Pictures</th>
                                <th>RC Photo</th>
                                <th>Pollution Photo</th>
                                <th>Taxi Permit Photo</th>
                                <th>Generate QR code</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </div>}
            </Paper>
        </>
    );
};

export default CabRegistrationScreen;
