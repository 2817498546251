import {Box, Button, Paper, Table, Text} from "@mantine/core";
import React from "react";
import {AdminUser} from "../../../@types";
import Heading from "../../../components/heading/Heading";
import CircularLoader from "../../../components/loader/CircularLoader";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
// import { usersList } from "./user.services";
import {useStyles} from "./User.styles";

const UserScreen = ({
                        onClickAddNew,
                        onClickEditBtn,
                        userList,
                        loading,
                    }: any) => {
    const {classes} = useStyles();

    const rows = userList.users.map((element: AdminUser, x: number) => (
        <tr key={x}>
            <td>{element.username}</td>
            <td>{element.email}</td>
            <td>{element.phone_number}</td>
            <td>
                {element.roles.map((i: any, x: number) => (
                    <Text key={x}> {i}</Text>
                ))}
            </td>
            <td>
                <Button onClick={() => onClickEditBtn(element)}>Edit</Button>
            </td>
        </tr>
    ));
    return (
        <>
            <Box py="md" sx={{display: "flex", alignItems: "center"}}>
                <Heading title="All Users"/>
                <Button ml={16} onClick={onClickAddNew}>
                    Add New User
                </Button>
            </Box>

            <Paper shadow="xs" p="md" mt={18}>
                {userList.loading ? SkeletonLoader() :
                    <div style={{overflowX: "auto", height: '100%', margin: '24px 0'}}>
                        <Table highlightOnHover className={classes.table}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile No.</th>
                                <th>Role</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {userList.users.length ? (
                                rows
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <NotEnoughData/>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>}
            </Paper>
        </>
    );
};

export default UserScreen;
