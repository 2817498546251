import React from "react";
import {Group, Avatar, Text, Box, useMantineTheme} from "@mantine/core";
import {useStyles} from "./User.style";

export function User({view}: any) {
    const theme = useMantineTheme();
    const {classes} = useStyles();
    const data = localStorage.getItem("user");
    const user = data ? JSON.parse(data) : null;
    return (
        <Box className={classes.mainBox}>
            {view === "mobile" ? (
                <Box className={classes.userProfile}>
                    <Avatar
                        src={user && user.username}
                        radius="xl"
                        size={100}
                        color="cyan"
                    >
                        {/* {user && user.username.substring(0,1)} */}
                    </Avatar>
                    <Box mt={8}>
                        <Text align="center" size="sm" weight={500}>
                            {user && user.username}
                        </Text>
                        <Text align="center" size="xs">{user && user.email}</Text>
                    </Box>
                </Box>
            ) : (
                <Group>
                    <Avatar
                        src={null} alt={user && user.username}
                        radius="xl"
                        size="lg"
                        color="cyan"
                    >
                        {/* {user && user.username.substring(0,1)} */}
                    </Avatar>
                    <Box sx={{flex: 1}}>
                        <Text size="sm" weight={500}>
                            {user && user.username}
                        </Text>
                        <Text size="xs">{user && user.email}</Text>
                    </Box>
                </Group>
            )}
        </Box>
    );
}
