import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AdminUser, DriverReportPayload} from "../../@types";

interface userDefaultState {
    driverReport: {
        loading: boolean;
        drivers: DriverReportPayload[];
    };
    driverDataById: {
        loading: boolean;
        driver: any;
    };
}

const initialState: userDefaultState = {
    driverReport: {
        loading: false,
        drivers: [],
    },
    driverDataById: {
        loading: false,
        driver: {},
    },
};
const driverStateSlice = createSlice({
    name: "driver",
    initialState,
    reducers: {
        setLoadingDriverReport: (state, action:PayloadAction<boolean>) => {
            state.driverReport.loading = action.payload;
        },
        setDriverReport: (state, action) => {
            state.driverReport.drivers = action.payload;
            state.driverReport.loading = false;
        },

        loadingDriverDataById: (state) => {
            state.driverDataById.loading = true;
        },
        setDriverDataById: (state, action) => {
            state.driverDataById.driver = action.payload;
            state.driverDataById.loading = false;
        },

        driverDataByIdloadingError: (state) => {
            state.driverDataById.loading = false;
        },
    },
});

export const {
    setDriverReport,
    setLoadingDriverReport,
    loadingDriverDataById,
    setDriverDataById,
    driverDataByIdloadingError,
} = driverStateSlice.actions;

export default driverStateSlice.reducer;
