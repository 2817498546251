import {showNotification} from "@mantine/notifications";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addDriver, updateCab, updateDriver} from "../../../api/api";
import CircularLoader from "../../../components/loader/CircularLoader";
import {getAllCabs} from "../../../redux/actions/cab.action";
import {RootState} from "../../../redux/store/store";
import {
    downloadImage,
    uploadFile,
} from "../../../services/fileUploadServices";
import {driverRegistrationScreen} from "../../../shared/constants";
import AddNewDriver from "./AddNewDriver";
import DriverRegistrationScreen from "./DriverRegistrationScreen";

const DriverRegistration = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.cabs);
    const [currentScreen, setCurrentScreen] = useState(
        driverRegistrationScreen.ALL_DRIVERS_SCREEN
    );
    const [driverId, setDriverId] = useState("");
    const [vehicleNo, setVehicleNo] = useState("");
    const [driverFirstName, setDriverFirstName] = useState("");
    const [driverLastName, setDriverLastName] = useState("");
    const [licenseNo, setLicenseNo] = useState("");
    const [aadharNo, setAadharNo] = useState("");
    const [primaryMobileNo, setPrimaryMobileNo] = useState("");
    const [secondaryMobileNo, setSecondaryMobileNo] = useState("");
    const [rating, setRating] = useState(0);
    const [comments, setComments] = useState("");
    const [licenseImageSrc, setLicenseImageSrc] = useState<any>(null);
    const [driverImageSrc, setDriverImageSrc] = useState<any>(null);
    const [driverImageUrl, setDriverImageUrl] = useState<any>(null);
    const [licenseImageUrl, setLicenseImageUrl] = useState<any>(null);
    const [editDriver, setEditDriver] = useState<any>({
        isEditing: false,
        editItem: null,
    });
    const [enabled, setEnabled] = useState<string>();
    const cabsLoading = useSelector((state: RootState) => state.cabs.vehiclesList.loading);
    const [isLoading, setIsLoading] = useState({
        submit: false,
        licenseImg: false,
        driverImg: false,
    });
    const hiddenLicenseImageInputRef: any = React.useRef(null);
    const hiddenDriverImageInputRef: any = React.useRef(null);

    const selectedCabData = state.vehiclesList.vehicles.length
        ? state.vehiclesList.vehicles.filter(
            (i: any) => i.registration_number === vehicleNo
        )
        : [];
    const onChangeEnabled = (e: any) => {
        setEnabled(e);
    };
    useEffect(() => {
        dispatch(getAllCabs());
    }, [dispatch]);

    const onClickAddNew = () => {
        setEditDriver({isEditing: false});
        if (vehicleNo) {
            setCurrentScreen(driverRegistrationScreen.ADD_DRIVER_SCREEN);
        } else {
            showNotification({
                title: "Error",
                message: "Select a vehicle to add a driver ",
                color: "red",
            });
        }
    };
    const onClickBack = () => {
        onDiscard();
        setCurrentScreen(driverRegistrationScreen.ALL_DRIVERS_SCREEN);
    };
    const onClickEditBtn = (editDriverData: any) => {
        setEditDriver({isEditing: true, editItem: editDriverData});
        setCurrentScreen(driverRegistrationScreen.ADD_DRIVER_SCREEN);
        setEnabled(editDriverData.enabled)
    };
    const onChangeVehicleeNo = (value: any) => {
        setVehicleNo(value);
    };
    const onChangeDriverFirstName = (e: any) => {
        setDriverFirstName(e.target.value);
    };
    const onChangeDriverLastName = (e: any) => {
        setDriverLastName(e.target.value);
    };
    const onChangeLicenseNo = (e: any) => {
        setLicenseNo(e.target.value);
    };
    const onChangeAadharNo = (e: any) => {
        e.target.value.length <= 12 && setAadharNo(e.target.value);
    };
    const onChangePrimaryMobileNo = (e: any) => {
        e.target.value.length <= 10 && setPrimaryMobileNo(e.target.value);
    };
    const onChangeSecondaryMobileNo = (e: any) => {
        e.target.value.length <= 10 && setSecondaryMobileNo(e.target.value);
    };
    const onChangeRating = (e: any) => {
        setRating(e.target.value);
    };
    const onChangeComments = (e: any) => {
        setComments(e.target.value);
    };

    const handleUploadCarImgClick = () => {
        hiddenLicenseImageInputRef.current.click();
    };
    const handleUploadRcImgClick = () => {
        hiddenDriverImageInputRef.current.click();
    };
    const onDownloadImage = async (url: string) => {
        setIsLoading({...isLoading, driverImg: true, licenseImg: true});
        const res = await downloadImage(url);
        if (res) {
            setIsLoading({...isLoading, driverImg: false, licenseImg: false});
            window.open(`${res[url]}`, "_blank");
        } else {
            setIsLoading({...isLoading, driverImg: false, licenseImg: false});
        }
    };
    const onSelectLicenseImage = async (event: any) => {
        if (event.target.files[0]) {
            setIsLoading({...isLoading, licenseImg: true});
            const res = await uploadFile(event.target.files[0]);
            console.log("res from select image", res);
            if (res) {
                setIsLoading({...isLoading, licenseImg: false});
                setLicenseImageSrc(res);
                setLicenseImageUrl(res)
            } else {
                setIsLoading({...isLoading, licenseImg: false});
                showNotification({
                    title: "Error",
                    message: "Error in uploading license image",
                    color: "red",
                });
            }
        }
    };
    const onSelectDriverImage = async (event: any) => {
        if (event.target.files[0]) {
            setIsLoading({...isLoading, driverImg: true});
            const res = await uploadFile(event.target.files[0]);
            if (res) {
                setIsLoading({...isLoading, driverImg: false});
                setDriverImageSrc(res);
                setDriverImageUrl(res);
            } else {
                setIsLoading({...isLoading, driverImg: false});
                showNotification({
                    title: "Error",
                    message: "Error in uploading driver image",
                    color: "red",
                });
            }
            console.log("res from select image", res);
        }
    };

    const onCloseLicenseImage = () => {
        setLicenseImageSrc(null);
    };
    const onCloseDriverImage = () => {
        setDriverImageSrc(null);
    };

    const onOpeneditDriver = () => {
        setDriverId(editDriver.editItem.id);
        setDriverFirstName(editDriver.editItem.first_name);
        setDriverLastName(editDriver.editItem.last_name);
        setLicenseNo(editDriver.editItem.license_number);
        setAadharNo(editDriver.editItem.aadhar_number);
        setPrimaryMobileNo(editDriver.editItem.primary_phone_number);
        setSecondaryMobileNo(editDriver.editItem.secondary_phone_number);
        setRating(editDriver.editItem.rating);
        setLicenseImageSrc(editDriver.editItem.other_images?.license_image);
        setDriverImageSrc(editDriver.editItem.photo);
        setDriverImageUrl(editDriver.editItem.photo);
        setEnabled(editDriver.editItem.enabled)
    };
    const onValidate = () => {
        if (driverFirstName === "") {
            showNotification({
                title: "Error",
                message: "Driver first name can not be empty",
                color: "red",
            });
        } else if (driverLastName === "") {
            showNotification({
                title: "Error",
                message: "Driver last name can not be empty",
                color: "red",
            });
        } else if (licenseNo === "") {
            showNotification({
                title: "Error",
                message: "licenseNo can not be empty",
                color: "red",
            });
        } else if (aadharNo === "") {
            showNotification({
                title: "Error",
                message: "aadharNo can not be empty",
                color: "red",
            });
        } else if (primaryMobileNo === "") {
            showNotification({
                title: "Error",
                message: "primaryMobileNo can not be empty",
                color: "red",
            });
        } else if (
            primaryMobileNo.length !== 10 ||
            (secondaryMobileNo && secondaryMobileNo.length !== 10)
        ) {
            showNotification({
                title: "Invalid Mobile no",
                message: "Mobile no should have 10 digits ",
                color: "red",
            });
        } else if (aadharNo.length !== 12) {
            showNotification({
                title: "Invalid aadhaar number",
                message: "aadhaar number should be ofhave 12 digits",
                color: "red",
            });
        } else if (driverImageSrc === null) {
            showNotification({
                title: "Error",
                message: "driver photo can not be empty",
                color: "red",
            });
        } else {
            return true;
        }
    };
    const onSubmit = () => {
        if (onValidate()) {
            const data = {
                first_name: driverFirstName,
                last_name: driverLastName,
                license_number: licenseNo,
                aadhar_number: aadharNo,
                photo: driverImageUrl,
                primary_phone_number: primaryMobileNo,
                secondary_phone_number: secondaryMobileNo,
                rating: Number(rating),
                enabled: enabled,
                other_images: {license_image: licenseImageUrl}
            };
            _addDriverToServer(data);
        }
    };
    const _addDriverToServer = async (data: any) => {
        try {
            setIsLoading({...isLoading, submit: true});
            const res = await addDriver(data);
            console.log("get add driver response", res.data);
            if (res.data && res.data.success) {
                setIsLoading({...isLoading, submit: false});
                console.log("driver id", res.data.payload.id);
                showNotification({
                    title: "Success",
                    message: "Driver added successfully",
                    color: "green",
                });
                _assignDriverToCab(res.data.payload.id);
            }
        } catch (error: any) {
            console.log("error", error);
            setIsLoading({...isLoading, submit: false});
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }
    };
    const _assignDriverToCab = async (driverId: string) => {
        setIsLoading({...isLoading, submit: true});
        const driverIds = selectedCabData[0].drivers?.length
            ? selectedCabData[0].drivers.map((i: any) => i.id)
            : [];

        const data = {
            driver_ids: [...driverIds, driverId],
        };
        try {
            const res = await updateCab(String(selectedCabData[0].id), data);
            console.log("get assign driver to cab response", res.data);
            if (res.data && res.data.success) {
                setIsLoading({...isLoading, submit: false});
                showNotification({
                    title: "Success",
                    message: "Driver assigned to cab successfully",
                    color: "green",
                });
                dispatch(getAllCabs());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error:any) {
            console.log("error", error);
            setIsLoading({...isLoading, submit: false});
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }
    };
    console.log(licenseImageSrc)
    console.log(licenseImageUrl)
    const onUpdate = () => {
        if (onValidate()) {
            const data = {
                first_name: driverFirstName,
                last_name: driverLastName,
                license_number: licenseNo,
                aadhar_number: aadharNo,
                photo: driverImageUrl,
                primary_phone_number: primaryMobileNo,
                secondary_phone_number: secondaryMobileNo,
                rating: Number(rating),
                enabled: enabled,
                other_images: {}
            };
            if(editDriver.other_images == null || editDriver.other_images.license_image != licenseImageSrc){
                data.other_images= {license_image:licenseImageUrl}
            }
            _updateDriverToServer(data);
        }
    };
    const _updateDriverToServer = async (data: any) => {
        console.log("driverId", driverId);
        try {
            setIsLoading({...isLoading, submit: true});
            const res = await updateDriver(driverId, data);
            console.log("get add driver response", res.data);
            if (res.data && res.data.success) {
                setIsLoading({...isLoading, submit: false});
                console.log("driver id", res.data.payload.id);
                showNotification({
                    title: "Success",
                    message: "Driver updated successfully",
                    color: "green",
                });
                dispatch(getAllCabs());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error:any) {
            console.log("error", error);
            setIsLoading({...isLoading, submit: false});
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }
    };

    const onDiscard = () => {
        setDriverFirstName("");
        setDriverLastName("");
        setLicenseNo("");
        setAadharNo("");
        setPrimaryMobileNo("");
        setSecondaryMobileNo("");
        setRating(0);
        setLicenseImageSrc(null);
        setDriverImageSrc(null);
        setDriverImageUrl(null);
        setLicenseImageUrl(null);
    };
console.log(licenseImageSrc)
    return (
        <>
            {currentScreen === driverRegistrationScreen.ALL_DRIVERS_SCREEN && (
                <DriverRegistrationScreen
                    onClickAddNew={onClickAddNew}
                    onClickEditBtn={onClickEditBtn}
                    onChangeVehicleeNo={onChangeVehicleeNo}
                    vehicleNo={vehicleNo}
                    onDownloadImage={onDownloadImage}
                    isLoading={cabsLoading}
                />
            )}
            {currentScreen === driverRegistrationScreen.ADD_DRIVER_SCREEN && (
                <AddNewDriver
                    vehicleNo={vehicleNo}
                    driverFirstName={driverFirstName}
                    driverLastName={driverLastName}
                    licenseNo={licenseNo}
                    aadharNo={aadharNo}
                    primaryMobileNo={primaryMobileNo}
                    secondaryMobileNo={secondaryMobileNo}
                    rating={rating}
                    hiddenLicenseImageInputRef={hiddenLicenseImageInputRef}
                    hiddenDriverImageInputRef={hiddenDriverImageInputRef}
                    licenseImageSrc={licenseImageSrc}
                    driverImageSrc={driverImageSrc}
                    handleUploadCarImgClick={handleUploadCarImgClick}
                    handleUploadRcImgClick={handleUploadRcImgClick}
                    onSelectLicenseImage={onSelectLicenseImage}
                    onSelectDriverImage={onSelectDriverImage}
                    onCloseLicenseImage={onCloseLicenseImage}
                    onCloseDriverImage={onCloseDriverImage}
                    onClickBack={onClickBack}
                    editDriver={editDriver}
                    onOpeneditDriver={onOpeneditDriver}
                    onChangeDriverFirstName={onChangeDriverFirstName}
                    onChangeDriverLastName={onChangeDriverLastName}
                    onChangeLicenseNo={onChangeLicenseNo}
                    onChangeAadharNo={onChangeAadharNo}
                    onChangePrimaryMobileNo={onChangePrimaryMobileNo}
                    onChangeSecondaryMobileNo={onChangeSecondaryMobileNo}
                    onChangeRating={onChangeRating}
                    onDiscard={onDiscard}
                    onSubmit={onSubmit}
                    onUpdate={onUpdate}
                    isLoading={isLoading}
                    onChangeComments={onChangeComments}
                    comments={comments}
                    enabled={enabled}
                    onChangeEnabled={onChangeEnabled}
                />
            )}
        </>
    );
};

export default DriverRegistration;
