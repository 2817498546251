import {Box, Text} from '@mantine/core';
import React from 'react'
import {useStyles} from '../Dashboard.styles';

const Logo = () => {
    const {classes} = useStyles();
    return (
        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
            {/* <img src='../images/new_favicon.png' alt='hertintune logo' className={classes.logo}  /> */}
            <Box>
                <Text align='center' color='#fff' weight='bold' transform='uppercase'
                      sx={{lineHeight: 1, fontSize: '32px'}}>Kanha</Text>
                <Text align='center' color='#fff' sx={{lineHeight: 1}}>Transport Portal</Text>
            </Box>
        </div>
    )
}

export default Logo