export const userRole = {
    ADMIN: 'ADMIN',
    VOLUNTEER: 'VOLUNTEER',
}

export const userScreen = {
    ALL_USER_SCREEN: 0,
    ADD_USER_SCREEN: 1,
}
export const routeConfigScreen = {
    ALL_ROUTE_CONFIGS_SCREEN: 0,
    ADD_ROUTE_CONFIGS_SCREEN: 1,
}

export const locationConfigScreen = {
    ALL_LOCATION_CONFIGS_SCREEN: 0,
    ADD_LOCATION_CONFIGS_SCREEN: 1,
}

export const cabRegistrationScreen = {
    ALL_CABS_SCREEN: 0,
    ADD_CAB_SCREEN: 1,
}
export const driverRegistrationScreen = {
    ALL_DRIVERS_SCREEN: 0,
    ADD_DRIVER_SCREEN: 1,
}

export const cabStatusUpdateScreen = {
    ALL_CABS_SCREEN: 0,
    CAB_STATUS_SCREEN: 1,
}