import {Box, Grid, Paper, Select, Table} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Heading from "../../../components/heading/Heading";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import {getBusReports} from "../../../redux/actions/cab.action";
import {RootState} from "../../../redux/store/store";
import {busReports} from "./busReports.services";
import {useStyles} from "./BusReports.styles";

const BusReportsScreen = () => {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.cabs);
    const [date, setDate] = useState(new Date());
    const onDateChange = (value: any) => {
        //console.log('value',value)
        setDate(value);
    };

    useEffect(() => {
        //console.log(date.toISOString())
        dispatch(
            getBusReports(
                dayjs(date).format("YYYY-MM-DD"),
                dayjs(date).add(1, "day").format("YYYY-MM-DD")
            )
        );
    }, [dispatch, date]);

    const rows = (data: any) =>
        data.map((element: any, x: number) => (
            <tr key={x}>
                <td>{element.bus_registration_number}</td>
                <td>{element.driver_name}</td>
                <td>{element.total_visitors}</td>
                <td>{element.source}</td>
                <td>{element.destination}</td>
                <td>{dayjs(element.start_time).format("YYYY-MM-DD HH:mm:ss")}</td>
                <td>{dayjs(element.end_time).format("YYYY-MM-DD HH:mm:ss")}</td>
            </tr>
        ));
    return (
        <>
            <Box py="md">
                <Box>
                    <Heading title="Bus Reports"/>
                </Box>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <DatePicker
                            placeholder="Pick date"
                            label="Event date"
                            required
                            value={date}
                            onChange={onDateChange}
                        />
                    </Grid.Col>
                </Grid>
                <div style={{overflowX: "auto", height: '100%', margin: '24px 0'}}>
                    <Table highlightOnHover mt={24} className={classes.table}>
                        <thead>
                        <tr>
                            <th>AC Bus #</th>
                            <th>Driver Name</th>
                            <th># of Visitors</th>
                            <th>Source</th>
                            <th>Destination</th>
                            <th>Start-Time</th>
                            <th>Scheduled End-Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.busReport.buses.length ? (
                            rows(state.busReport.buses)
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <NotEnoughData/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>
            </Paper>
        </>
    );
};

export default BusReportsScreen;
