import {showNotification} from "@mantine/notifications";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addConfig, updateConfig} from "../../../api/api";
import {getConfigs} from "../../../redux/actions/config.action";
import {RootState} from "../../../redux/store/store";
import AddNewConfig from "./AddNewConfig";
import ConfigScreen from "./ConfigScreen";

const Config = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.config);
    const [currentScreen, setCurrentScreen] = useState(0);
    const [keyName, setKeyName] = useState("");
    const [value, setValue] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const configLoading = useSelector((state: RootState) => state.config.configLists.loading);
    const [editConfig, setEditConfig] = useState<any>({
        isEditing: false,
        editItem: null,
    });

    useEffect(() => {
        dispatch(getConfigs());
    }, [dispatch]);

    const onClickAddNew = () => {
        onDiscard();
        setEditConfig({isEditing: false});
        setCurrentScreen(1);
    };
    const onClickBack = () => {
        onDiscard();
        setCurrentScreen(0);
    };
    const onCreatedValues = (query: string) => {
        setValue((current: any) => current.includes(query) ? [...current] : [...current, query]);
    };
    const onChangeValue = (value: any) => {
        setValue(value);
    };
    const onChangeKey = (e: any) => {
        setKeyName(e.target.value);
    };
    const onClickEditBtn = (editConfigData: any) => {
        setEditConfig({isEditing: true, editItem: editConfigData});
        setCurrentScreen(1);
    };
    const onDiscard = () => {
        setKeyName("");
        setValue([]);
    };
    const onSubmit = () => {
        const data = {
            key_name: keyName,
            value: value,
        };
        _addConfigToServer(data)
    };
    const _addConfigToServer = async (data: any) => {
        try {
            setIsLoading(true);
            const res = await addConfig(data);
            console.log("get add config response", res.data);
            if (res.data && res.data.success) {
                setIsLoading(false);
                showNotification({
                    title: "Success",
                    message: "Config added successfully",
                    color: "green",
                });
                dispatch(getConfigs());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
            showNotification({
                title: "Error",
                message: "Error in adding config",
                color: "red",
            });
        }
    }
    const onUpdate = () => {
        const data = {
            key_name: keyName,
            value: value,
        };
        _updateConfigToServer(data)
    };
    const _updateConfigToServer = async (data: any) => {
        try {
            setIsLoading(true);
            const res = await updateConfig(editConfig.editItem.id, data);
            console.log("get updated config response", res.data);
            if (res.data && res.data.success) {
                setIsLoading(false);
                showNotification({
                    title: "Success",
                    message: "Config updated successfully",
                    color: "green",
                });
               dispatch(getConfigs());
               setTimeout(() => onClickBack(), 1000);
            }
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
            showNotification({
                title: "Error",
                message: "Error in updating config",
                color: "red",
            });
        }
    }
    const onOpenEditConfig = () => {
        setKeyName(editConfig.editItem.key_name);

        setValue(editConfig.editItem.value)
    };
    return (
        <>
            {currentScreen === 0 && (
                <ConfigScreen
                    onClickAddNew={onClickAddNew}
                    configData={state.configLists.configs}
                    onClickEditBtn={onClickEditBtn}
                    isLoading={configLoading}
                />
            )}
            {currentScreen === 1 && (
                <AddNewConfig
                    keyName={keyName}
                    value={value}
                    onClickBack={onClickBack}
                    onCreatedValues={onCreatedValues}
                    onChangeKey={onChangeKey}
                    editConfig={editConfig}
                    onDiscard={onDiscard}
                    onSubmit={onSubmit}
                    onUpdate={onUpdate}
                    onOpenEditConfig={onOpenEditConfig}
                    isLoading={isLoading}
                    onChangeValue={onChangeValue}
                />
            )}
        </>
    );
};

export default Config;
