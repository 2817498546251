import React, {useEffect, useState} from 'react';
import {Box, Button, createStyles, Divider, Grid, Paper, Text, Textarea} from '@mantine/core';
import CircularLoader from "../../components/loader/CircularLoader";
import Heading from "../../components/heading/Heading";
import {useDispatch} from "react-redux";
import {getCabTripByUidHasFeedback, updateCabTripFeedbackByUid} from "../../api/api";
import {AppThunk} from "../../redux/store/store";
import {useSearchParams} from "react-router-dom";
import RatingStars from "../../components/ratings/RatingStar";
import {CabTripFeedback} from "../../@types";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
    },

    title: {
        fontWeight: 900,
        fontSize: 34,
        marginBottom: theme.spacing.md,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,

        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },

    control: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
        },
    },

    mobileImage: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    desktopImage: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },
}));

export function FeedbackPage() {
    const {classes} = useStyles();
    const [loading, setLoading] = useState(false);
    const [ratingDone, setRatingDone] = useState(false);
    const [noTrip, setNoTrip] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [feedbackGiven, setFeedbackGiven] = useState(false);
    const tripId = searchParams.get("t")
    const dispatch = useDispatch();
    const [driverRating, setDriverRating] = useState<number>(0);
    const [cabRating, setCabRating] = useState<number>(0);
    const [feedbackComment, setFeedbackComment] = useState<string>("");
    const getCabTripHasFeedback = (uid: string): AppThunk => async (dispatch) => {
        try {
            setLoading(true)
            const res = await getCabTripByUidHasFeedback(uid);
            console.log('get cabs trip feedback response', res.data)
            if (res.data && res.data.success) {
                setLoading(false)
                setFeedbackGiven(res.data.payload)
            }
        } catch (error) {
            console.log("error", error);
            setLoading(false)
        }
    };


    const updateCabTripFeedback = (uid: string, data: CabTripFeedback): AppThunk => async (dispatch) => {
        try {
            setLoading(true)
            const res = await updateCabTripFeedbackByUid(uid, data);
            console.log('update cabs trip feedback response', res.data)
            if (res.data && res.data.success) {
                setLoading(false)
                setFeedbackGiven(true)
            }
        } catch (error) {
            console.log("error", error);
            setLoading(false)
        }
    };


    useEffect(() => {
        if (tripId != null){
            dispatch(getCabTripHasFeedback(tripId))
        }

    },[dispatch])

    const onSubmit = async () => {
        if(tripId == null)
            return

        var data:CabTripFeedback = {
            driver_rating: Number(driverRating),
            cab_rating: Number(cabRating),
            comment: feedbackComment
        }

        dispatch(updateCabTripFeedback(tripId, data))
    };
    return (
        <>
            {(loading) && <CircularLoader/>}

            <Paper shadow="xs" pt="md" style={{height: "100vh", alignItems: "center", textAlign: "center"}}>
                <Box>
                    <Heading title={"Rate your trip"}/>
                </Box>


                {tripId == null && <Box mt={50}>
                    <h3>{"Invalid Trip, please check the url!!"}</h3>
                </Box>}

                {feedbackGiven ?
                    <h3>{" Thankyou, Your trip has been rated!!"}</h3>:
                    <Box mt={50}>
                        <table style={{margin: "auto"}}>
                            <tr>
                                <td>
                                    <RatingStars title={"Rate Driver:"}
                                                 onRatingChange={(v: number) => setDriverRating(v)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <RatingStars title={"Rate Cab:"}
                                                 onRatingChange={(v: number) => setCabRating(v)}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <Textarea
                                        mt={20}
                                        style={{width: "300px", textAlign: "left"}}
                                        placeholder="Comments"
                                        label="Comments/Feedback"
                                        required
                                        value={feedbackComment}
                                        onChange={(v: any) => setFeedbackComment(v.target.value)}
                                    />
                                </td>
                            </tr>

                        </table>
                        <Divider mt={16} mb={16}/>
                        <Button onClick={onSubmit}>
                            Submit
                        </Button>
                    </Box>
                }
            </Paper>
        </>
    );
}