import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Location} from "../../@types";

export interface Locations {
    locationList: {
        loading: boolean,
        locations: Location[]
    },
    availableLocationList: {
        loading: boolean,
        locations: Location[]
    }
}

const initialState: Locations = {
    locationList: {
        loading: false,
        locations: []
    },
    availableLocationList: {
        loading: false,
        locations: []
    }
};
const locationStateSlice = createSlice({
    name: "locationList",
    initialState,
    reducers: {
        setLocationListLoading: (state,action:PayloadAction<boolean>) => {
            state.locationList.loading = action.payload;
            state.availableLocationList.loading = action.payload;
        },
        setLocationList: (state, action:PayloadAction<Location[]>) => {
            state.locationList.locations = action.payload;
            state.availableLocationList.locations = state.locationList.locations.filter((e)=> e.enabled)
            state.locationList.loading = false;
            state.availableLocationList.loading = false;
        }
    },
});

export const {
    setLocationListLoading,
    setLocationList,
} = locationStateSlice.actions;

export default locationStateSlice.reducer;
