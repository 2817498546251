import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    imageContainer: {
        width: "100%",
        height: "100%",
        position: 'relative'
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    iconBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        width: '30px',
        height: '30px',
        background: '#FACDA3',
        //border:'1px solid #0e150e',
        borderRadius: '50%',
        zIndex: 1,
    }
}));
