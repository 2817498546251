import {getPrebookingList, getUserList, getVisitosEstimationReport} from "../../api/api";
import {
    setLoadingVisitorEstimationReports,
    setUserList, setUserListLoading,
    setVisitorEstimationReports,
} from "../reducers/user.reducer";
import {AppThunk} from "../store/store";
import {setPrebookingList, setPrebookingLoading} from "../reducers/prebooking.reducer";

export const getAllPrebookings = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setPrebookingLoading(true));
        const res = await getPrebookingList();
        console.log('get prebooking response', res.data)
        if (res.data && res.data.success) {
            dispatch(setPrebookingList(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setPrebookingLoading(false));
    }
};

