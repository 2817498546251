import axios, {AxiosRequestConfig} from "axios";
import {
    AddUserReq,
    AddVisitor,
    BusTripCreateRequest, Cab,
    CabTripCreateRequest, CabTripFeedback,
    configRequestPayload,
    Login, PrebookingCreateRequest, RegisterCab,
    RegisterDriver,
    RoutePayload,
    UpdateCabPayload,
    UpdateUserReq,
} from "../@types";
import {AxiosWithOutToken, AxiosWithToken} from "./api-config";
const API_URL =
    process.env.API_URL ||
    // "http://localhost:8080";
    "https://transport-service.dev.heartfulnessinstitute.in";
    // "https://transport-service.qa.heartfulnessinstitute.in";
    // "https://transport-service.heartfulnessinstitute.in";

const login = (payload: Login) => {
    try {
        const res = axios.post(`${API_URL}/admin/login`, payload);
        return res;
    } catch (error) {
        console.log("error in login", error);
    }
};
const addUser = (payload: AddUserReq) => {
    try {
        const res = AxiosWithToken.post(`${API_URL}/users`, payload);
        return res;
    } catch (error) {
        console.log("error adding user", error);
    }
};
const addCab = (payload: RegisterCab) => {
    const res = AxiosWithToken.post(`${API_URL}/cabs`, payload);
    return res;
};
const addDriver = (payload: RegisterDriver) => {
    const res = AxiosWithToken.post(`${API_URL}/drivers`, payload);
    return res;
};
const addVisitor = (payload: AddVisitor) => {
    const res = AxiosWithToken.post(`${API_URL}/visitors`, payload);
    return res;
};
const addRoute = (payload: RoutePayload) => {
    const res = AxiosWithToken.post(`${API_URL}/routes`, payload);
    return res;
};
const addConfig = (payload: configRequestPayload) => {
    const res = AxiosWithToken.post(`${API_URL}/configs`, payload);
    return res;
};
const getUserList = () => {
    const res = AxiosWithToken.get(`${API_URL}/users`);
    return res;
};
const getCabsList = () => {
    const res = AxiosWithToken.get(`${API_URL}/cabs`);
    return res;
};
const getRoutersList = () => {
    const res = AxiosWithToken.get(`${API_URL}/routes`);
    console.log(res)
    return res;
};
const getAvailableRoutersList = (days:string[]) => {
    const res = AxiosWithToken.get(`${API_URL}/routes?availability_days=${days}`);
    console.log(res)
    return res;
};
const getCabDataById = (cabId: string) => {
    const res = AxiosWithToken.get(`${API_URL}/cabs/${cabId}`);
    return res;
};
const getCabTripByUidHasFeedback = (uid: string) => {
    const res = AxiosWithOutToken.get(`${API_URL}/open/cab-trips/${uid}/has-feedback`);
    return res;
};

const updateCabTripFeedbackByUid = (uid: string, payload: CabTripFeedback) => {
    const res = AxiosWithOutToken.post(`${API_URL}/open/cab-trips/${uid}/feedback`, payload);
    return res;
};

const getCabWiseReport = (date: any) => {
    const res = AxiosWithToken.get(`${API_URL}/admin/reports/cab?date=${date}`);
    return res;
};
const getHourlyReport = (start: any, end: any) => {
    const res = AxiosWithToken.get(`${API_URL}/admin/reports/cab-trip?start=${start}&end=${end}`);
    return res;
};
const getBusReport = (start: any, end: any) => {
    const res = AxiosWithToken.get(`${API_URL}/admin/reports/bus-trip?start=${start}&end=${end}`);
    return res;
};
const getCabArrivalReport = ( destination?: string) => {
    const res = AxiosWithToken.get(`${API_URL}/admin/reports/arriving-cabs-report?destination=${destination}`);
    return res;
};
const getDriverReport = (date: any) => {
    const res = AxiosWithToken.get(
        `${API_URL}/admin/reports/driver?date=${date}`
    );
    return res;
};
const getDriverById = (driverId: string) => {
    const res = AxiosWithToken.get(
        `${API_URL}/drivers?driverId=${driverId}`
    );
    return res;
};
const getAllCabTrip = () => {
    const res = AxiosWithToken.get(`${API_URL}/cab-trips?size=500`);
    return res;
};
const getVehicleEstimationReport = (start: any, end: any, destination: string) => {
    const res = AxiosWithToken.get(`${API_URL}/admin/reports/vehicle-estimation-report?start=${start}&end=${end}&destination=${destination}`);
    return res;
};
const getVisitosEstimationReport = (start: any, end: any, destination: string) => {
    const res = AxiosWithToken.get(`${API_URL}/admin/reports/visitor-estimation-report?start=${start}&end=${end}&destination=${destination}`);
    return res;
};
const getAllCabsTripByCabId = (cabId: string[]) => {
    const res = AxiosWithToken.get(`${API_URL}/cab-trips?cab_ids=${cabId}`);
    return res;
};
const getAllConfig = () => {
    const res = AxiosWithToken.get(`${API_URL}/configs`);
    return res;
};
const getRouteById = (routeId: string) => {
    const res = AxiosWithToken.get(`${API_URL}/routes/${routeId}`);
    return res;
};

const updateUser = (userId: string, payload: UpdateUserReq) => {
    const res = AxiosWithToken.patch(`${API_URL}/users/${userId}`, payload);
    return res;
};
const updateCab = (cabId: string, payload: any) => {
    const res = AxiosWithToken.patch(`${API_URL}/cabs/${cabId}`, payload);
    return res;
};
const updateDriver = (driverId: string, payload: RegisterDriver) => {
    const res = AxiosWithToken.patch(`${API_URL}/drivers/${driverId}`, payload);
    return res;
};
const updateRoute = (routeId: string, payload: RoutePayload) => {
    const res = AxiosWithToken.patch(`${API_URL}/routes/${routeId}`, payload);
    return res;
};
const updateConfig = (configId: string, payload: configRequestPayload) => {
    const res = AxiosWithToken.put(`${API_URL}/config/${configId}`, payload);
    return res;
};
const addCabTrips = (payload: CabTripCreateRequest) => {
    const res = AxiosWithToken.post(`${API_URL}/cab-trips`, payload);
    return res;
};
const addCabTripsComplete = (tripId: string) => {
    const res = AxiosWithToken.post(`${API_URL}/cab-trips/${tripId}/complete`);
    return res;
};
const addCabTripsCancel = (tripId: string) => {
    const res = AxiosWithToken.post(`${API_URL}/cab-trips/${tripId}/cancel`);
    return res;
};
const uploadImageFile = (payload: any) => {
    const res = AxiosWithToken.post(`${API_URL}/admin/get-upload-urls`, payload);
    return res;
};
const downLoadImageUrl = (payload: any) => {
    const res = AxiosWithToken.post(
        `${API_URL}/admin/get-download-urls`,
        payload
    );
    return res;
};
const getVisitorById = (visitorId: string[]) => {
    const res = AxiosWithToken.get(`${API_URL}/visitors?hfn_ids=${visitorId}`);
    return res;
};

const addBusTrips = (payload: BusTripCreateRequest) => {
    const res = AxiosWithToken.post(`${API_URL}/bus-trips`, payload);
    return res;
};
const deleteRouteById = (routeId: string) => {
    const res = AxiosWithToken.delete(`${API_URL}/routes/delete/${routeId}`);
    return res;
};

const searchCabByRegistrationNumber = (regNumber: string) => {
    console.log('regNumber', regNumber)
    const res = AxiosWithToken.get(`${API_URL}/cabs?registration_numbers=${regNumber}`);
    return res;
}

const getAllLocations = () => {
    const res = AxiosWithToken.get(`${API_URL}/locations`);
    return res;
}
const deleteLocationById = (locationId: string) => {
    const res = AxiosWithToken.delete(`${API_URL}/locations/${locationId}`);
    return res;
};
const addLocation = (payload: Location) => {
    const res = AxiosWithToken.post(`${API_URL}/locations`, payload);
    return res;
};
const updateLocation = (locationId: string, payload: Location) => {
    const res = AxiosWithToken.patch(`${API_URL}/locations/${locationId}`, payload);
    return res;
};

const getPrebookingList = () => {
    const res = AxiosWithToken.get(`${API_URL}/prebookings`);
    return res;
};

const addPrebooking = (payload: PrebookingCreateRequest) => {
    const res = AxiosWithToken.post(`${API_URL}/prebookings`, payload);
    return res;
};

const cancelPrebooking = (id: string) => {
    const res = AxiosWithToken.post(`${API_URL}/prebookings/${id}/cancel`);
    return res;
};

const assignPrebooking = (id: string,payload: CabTripCreateRequest) => {
    const res = AxiosWithToken.post(`${API_URL}/prebookings/${id}/assigntrip`, payload);
    return res;
};
export {
    login,
    addUser,
    addCab,
    addDriver,
    addVisitor,
    addRoute,
    getUserList,
    updateUser,
    getCabsList,
    getRoutersList,
    getCabDataById,
    updateCab,
    updateDriver,
    updateRoute,
    uploadImageFile,
    downLoadImageUrl,
    getVisitorById,
    addCabTrips,
    getCabWiseReport,
    getBusReport,
    getDriverReport,
    getAllConfig,
    addConfig,
    updateConfig,
    getAllCabTrip,
    getAllCabsTripByCabId,
    getRouteById,
    addBusTrips,
    getHourlyReport,
    getDriverById,
    getVehicleEstimationReport,
    getVisitosEstimationReport,
    deleteRouteById,
    addCabTripsComplete,
    addCabTripsCancel,
    searchCabByRegistrationNumber,
    getAllLocations,
    deleteLocationById,
    addLocation,
    getCabArrivalReport,
    getAvailableRoutersList,
    updateLocation,
    getCabTripByUidHasFeedback,
    updateCabTripFeedbackByUid,
    getPrebookingList,
    addPrebooking,
    cancelPrebooking,
    assignPrebooking,
};
