import {Box, Divider, Skeleton} from "@mantine/core";
import React from "react";

const SkeletonLoader = () => {
    return Array(4)
        .fill(0)
        .map((i, x) => (
            <React.Fragment key={`MedSkele${x}`}>
                <Box mb='md'>
                    <Skeleton height={50} circle mb="sm"/>
                    <Skeleton height={8} radius="xl"/>
                    <Skeleton height={8} mt={6} radius="xl"/>
                    <Skeleton height={8} mt={6} width="70%" radius="xl"/>
                </Box>
            </React.Fragment>
        ));
};

export default SkeletonLoader;
