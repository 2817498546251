import {Action, configureStore, ThunkAction} from "@reduxjs/toolkit";
import driverReducer from "../reducers/driver.reducer";
import cabReducer from "../reducers/cab.reducer";
import volunteerReducer from "../reducers/volunteerReducer";
import userReducer from "../reducers/user.reducer";
import routeReducer from "../reducers/route.reducer";
import configReducer from "../reducers/config.reducer";
import locationReducer from "../reducers/location.reducer";
import prebookingReducer from "../reducers/prebooking.reducer";

export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    reducer: {
        volunteer: volunteerReducer,
        user: userReducer,
        driver: driverReducer,
        cabs: cabReducer,
        route: routeReducer,
        config: configReducer,
        location: locationReducer,
        prebooking: prebookingReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
