import {showNotification} from "@mantine/notifications";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// import { CabListPayload } from "../../../@types";
import {getAllCabs, searchCabByRegistration} from "../../../redux/actions/cab.action";
import {Cab, EditCab} from "../../../@types";
import {
    addCabTripsCancel,
    addCabTripsComplete,
    searchCabByRegistrationNumber,
    updateCab
} from "../../../api/api";
import {RootState} from "../../../redux/store/store";
import {cabRegistrationScreen, cabStatusUpdateScreen} from "../../../shared/constants";
import CabDetailScreen from "./CabDetailScreen";
import CabRegistrationScreen from "./CabSearchScreen";
import {
    downloadImage,
    uploadFile,
} from "../../../services/fileUploadServices";
import CabSearchScreen from "./CabSearchScreen";
import {stat} from "fs";
import {getAllLocationsAction} from "../../../redux/actions/location.action";
import {getAllAvailableRoutes, getAllRoutes} from "../../../redux/actions/route.action";
import dayjs from "dayjs";
import {getDistanceFromLatLonInKm} from "../../../utils/helpers";

const CabStatus = () => {
    const [currentScreen, setCurrentScreen] = useState(
        cabStatusUpdateScreen.ALL_CABS_SCREEN
    );
    const dispatch = useDispatch();
    const [cab, setCab] = useState<Cab>();

    const [cabStatus, setCabStatus] = useState("");
    const [currentDriver, setCurrentDriver] = useState<string>("");
    const [location, setLocation] = useState<string>("")
    const [eta, setEta] = useState<number>(0)
    const [captureLocationAssignment, setCaptureLocationAssignment] = useState(false)

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getAllCabs())
    }, [dispatch]);

    const locationState = useSelector((state: RootState) => state.location);
    const locations = locationState.locationList.locations.filter(
        (i: any) => i.enabled === true
    );



    const onClickOpen = async (regNumber: string) => {
        try {
            setLoading(true);
            var response = await searchCabByRegistrationNumber(regNumber);
            if (response.data && response.data.success) {
                setLoading(false);
                if(response.data.payload.length){
                    if(!response.data.payload[0].enabled){
                        showNotification({
                            title: "Success",
                            message: "Cab is Deactivated, Contact Admin:"+ regNumber,
                            color: "red",
                        });
                        return
                    }
                   setCab(response.data.payload[0])
                    showNotification({
                        title: "Success",
                        message: "Cab Details Opened successfully",
                        color: "green",
                    });
                    setCurrentScreen(cabRegistrationScreen.ADD_CAB_SCREEN);
                    setCabStatus(response.data.payload[0]?.status);
                    setCaptureLocationAssignment(response.data.payload[0].status == "READY_FOR_WORK")

                    if(response.data.payload[0].location){
                        setLocation(response.data.payload[0].location.id)
                    }

                    if(response.data.payload[0].current_driver)
                        setCurrentDriver(response.data.payload[0].current_driver.id)

                }else{
                    showNotification({
                        title: "Success",
                        message: "No Cab Found with registration number: "+ regNumber,
                        color: "green",
                    });
                }

            }
        } catch (error: any) {
            console.log("error updating cab", error);
            setLoading(false);
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }

    };
    const onClickBack = () => {
        setCurrentScreen(cabRegistrationScreen.ALL_CABS_SCREEN);
    };

    const onChangeCabStatus = (value: any) => {
        setCabStatus(value);
        if (value === "READY_FOR_WORK") {
            setCaptureLocationAssignment(true)
        } else {
            setCaptureLocationAssignment(false)
        }
    };

    const onChangeLocation = (value: any) => {
        setLocation(value)
        debugger;
        const l = locations.find((l) => l.id == value)
        if(l && cab){
            const distance = getDistanceFromLatLonInKm(l?.lat, l?.lon, cab?.lat!, cab?.lon!)
            setEta(Math.round((distance * 60) / 22))
        }else{
            setEta(30)
        }

    }

    const onChangeEta = (value: number) => {
        // debugger
        console.log("HERE"+(value))
        setEta(value)
    }

    const onCurrentDriverChange = (value: any)=> {
        setCurrentDriver(value)
    }

    const cancelCabTrip = async () => {
        setLoading(true);

        try {
            const response = await addCabTripsCancel(String(cab?.current_trip?.id));
            if (response.data && response.data.success) {
                setLoading(false);
                showNotification({
                    title: "Success",
                    message: "Cab Cancelled successfully",
                    color: "green",
                });
                dispatch(getAllCabs());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error updating cab", error);
            setLoading(false);
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }
    }

    const completeCab = async () => {
        setLoading(true);

        try {
            const response = await addCabTripsComplete(String(cab?.current_trip?.id));
            if (response.data && response.data.success) {
                setLoading(false);
                showNotification({
                    title: "Success",
                    message: "Cab updated successfully",
                    color: "green",
                });
                dispatch(getAllCabs());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error updating cab", error);
            setLoading(false);
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }
    }
    const updateCabStatus = async () => {
        console.log(cab)
        if (cabStatus === '') {
            showNotification({
                title: "Error",
                message: "Cab Status Cannot be empty",
                color: "red",
            });
            return
        }
        const data: EditCab = {
            status: cabStatus,
        }
        if (cabStatus == "READY_FOR_WORK") {

            data.destination_id = location

            data.destination_eta_minutes = Number(eta)
            if (!currentDriver) {
                showNotification({
                    title: "Error",
                    message: "Current Driver is Mandatory for this status",
                    color: "red",
                });
                return
            }
            data.current_driver_id=currentDriver
        }

        setLoading(true);

        try {
            const response = await updateCab(String(cab?.id), data);
            if (response.data && response.data.success) {
                setLoading(false);
                showNotification({
                    title: "Success",
                    message: "Cab updated successfully",
                    color: "green",
                });
                dispatch(getAllCabs());
                setTimeout(() => onClickBack(), 1000);
            }
        } catch (error: any) {
            console.log("error updating cab", error);
            setLoading(false);
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Something went wrong",
                color: "red",
            });
        }
    };

    return (
        <>
            {currentScreen === cabRegistrationScreen.ALL_CABS_SCREEN && (
                <CabSearchScreen
                    onClickOpen={onClickOpen}
                    isLoading={loading}
                />
            )}
            {currentScreen === cabRegistrationScreen.ADD_CAB_SCREEN && (
                <CabDetailScreen
                    cab={cab}
                    onClickBack={onClickBack}
                    updateCabStatus={updateCabStatus}
                    onChangeCabStatus={onChangeCabStatus}
                    cabStatus={cabStatus}
                    loading={loading}
                    location={location}
                    eta={eta}
                    captureLocationAssignment={captureLocationAssignment}
                    locations={locations}
                    onChangeLocation={onChangeLocation}
                    onChangeEta={onChangeEta}
                    cancelCabTrip={cancelCabTrip}
                    completeCabTrip={completeCab}
                    currentDriver={currentDriver}
                    onCurrentDriverChange={onCurrentDriverChange}
                />
            )}
        </>
    );
};

export default CabStatus;
