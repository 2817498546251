import {Loader, Text} from "@mantine/core";
import React from "react";
import "./spinner.css";
const CircularLoader = ({size, style, color}: any) => {
    console.log('loading')
    return (
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    );
};

export default CircularLoader;
