import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    table: {
        '& tr': {
            border: '1px solid #dee2e6',
            borderCollapse: 'collapse',
        },
        '& td': {
            border: '1px solid #dee2e6',
            borderCollapse: 'collapse',
        },
        '& th': {
            border: '1px solid #dee2e6',
            borderCollapse: 'collapse',
            position: "sticky",
            top: "0",
            zIndex: 1,
            background: theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
        },


    },
    status: {
        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            width: '200px',
        },

    }

}));
  