import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    table: {
        "& tr": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
        },
        "& td": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
        },
        "& th": {
            border: "1px solid #dee2e6",
            borderCollapse: "collapse",
            position: "sticky",
            top: "0",
            zIndex: 1,
            background: theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
        },
    },
    thumbnail: {
        height: "250px",
        width: "100%",
        border: "1px solid #ced4da",
    },
    thumbnail1: {
        minHeight: "250px",
        width: "100%",
        // border:'1px solid #ced4da'
    },
    uploadImageBox: {
        height: "100%",
        width: "100%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignContent: "cenrter",
        alignItems: "center",
    },
    uploadImageBox1: {
        height: "200px",
        width: "100%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignContent: "cenrter",
        alignItems: "center",
        border: "1px solid #ced4da",
        position: "relative",
    },
    linkText: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    closeIcon: {
        // position:"absolute",
        // top:'10px',
        // right:'10px',
        cursor: "pointer",
    },
    imagePrevieBox: {
        border: "1px solid #ced4da",
        height: "200px",
    },
    deleteIcon: {
        width: "100%",
        //height:'200px',
        padding: "10px 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #ced4da",
    },
    addBtn: {
        width: "100%",
        //height:'200px',
        padding: "10px 0",
        display: "flex",
        //justifyContent:'center',
        alignItems: "center",
        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            height: "40px",
        },
    },
    addBtn1: {
        cursor: "pointer",
    },
}));
