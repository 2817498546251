import {
    getAllCabTrip,
    getBusReport, getCabArrivalReport, getCabDataById,
    getCabsList,
    getCabWiseReport,
    getHourlyReport,
    getVehicleEstimationReport,
    searchCabByRegistrationNumber
} from "../../api/api";
import {
    setBusReports,
    setCabArrivalsReports,
    setCabTrips,
    setCabWiseReports, setHourlyReports, setLoadingBusReports,
    setLoadingCabArrivalsReports, setLoadingCabTrips,
    setLoadingCabWiseReports, setLoadingHourlyReports,
    setLoadingSearchCabs, setLoadingVehicleEstimationReports,
    setLoadingVehiclesList,
    setSearchCabsResult,
    setVehicleEstimationReports,
    setVehiclesList,

} from "../reducers/cab.reducer";
import {AppThunk} from "../store/store";
import {Cab} from "../../@types";



export const getAllCabs = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingVehiclesList(true));
        const res = await getCabsList();
        console.log('get cabs response', res.data)
        if (res.data && res.data.success) {
            dispatch(setVehiclesList(res.data.payload))
        }

    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingVehiclesList(false));

    }
};


export const getCabWiseReports = (date: any): AppThunk => async (dispatch) => {
    console.log('date from action', typeof date)
    try {
        dispatch(setLoadingCabWiseReports(true));
        const res = await getCabWiseReport(date);
        console.log('get cab wise report response by id', res.data)
        if (res && res.data.success) {
            dispatch(setCabWiseReports(res.data.payload.report))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingCabWiseReports(false));
    }
};
export const getBusReports = (start: any, end: any): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingBusReports(true));
        const res = await getBusReport(start, end);
        console.log('get bus report response', res.data)
        if (res && res.data.success) {
            dispatch(setBusReports(res.data.payload.report))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingBusReports(false));
    }
};

export const getAllCabTrips = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingCabTrips(true));
        const res = await getAllCabTrip();
        console.log('get cab trips response', res.data)
        if (res.data && res.data.success) {
            dispatch(setCabTrips(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingCabTrips(false));
    }
};


export const getHourlyReports = (start: any, end: any): AppThunk => async (dispatch) => {
    try {
        dispatch(setLoadingHourlyReports(true));
        const res = await getHourlyReport(start, end);
        console.log('get hourlyreport response', res.data)
        if (res.data && res.data.success) {
            dispatch(setHourlyReports(res.data.payload.report))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingHourlyReports(false));
    }
};
export const getVehicleEstimationReports = (start: any, end: any, destination: string): AppThunk => async (dispatch) => {
    console.log('getVehicleEstimationReports')
    try {
        dispatch(setLoadingVehicleEstimationReports(true));
        const res = await getVehicleEstimationReport(start, end, destination);
        console.log('get vehicle estimation response', res.data)
        if (res.data && res.data.success) {
            dispatch(setVehicleEstimationReports(res.data.payload.report))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingVehicleEstimationReports(false));
    }
};

export const searchCabByRegistration = (regNumber: string): AppThunk => async (dispatch) => {
    console.log('searchCabByRegistrationNumber')
    try {
        dispatch(setLoadingSearchCabs(true));
        const res = await searchCabByRegistrationNumber(regNumber);
        console.log('search cab response', res.data)
        if (res.data && res.data.success) {
            console.log(res.data.payload)
            dispatch(setSearchCabsResult(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingSearchCabs(false));
    }
};

export const getCabArrivalsReports = ( destination?: string, showLoading?: boolean): AppThunk => async (dispatch) => {
    console.log('getCabArrivalsReports')
    try {
        if(showLoading)
            dispatch(setLoadingCabArrivalsReports(true));
        const res = await getCabArrivalReport(destination?destination:"");
        console.log('get cab arrival response', res.data)
        if (res.data && res.data.success) {
            dispatch(setCabArrivalsReports(res.data.payload))
        }
    } catch (error) {
        console.log("error", error);
        dispatch(setLoadingCabArrivalsReports(false));
    }
};