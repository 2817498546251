import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme, _params, getRef) => {
    const icon = getRef("icon");
    return {
        openMoredrawer: {
            backgroundColor: theme.colors[theme.primaryColor][1],
        },
        linkItems: {
            display: "flex",
            alignItems: "center",
        },
        mobileLinkItem: {
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
        },
        mobileLinkIContainer: {
            display: "flex",
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            overflowX: 'auto',
        },
        link: {
            ...theme.fn.focusStyles(),
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            fontSize: theme.fontSizes.sm,
            color: "#fff",
            padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
            borderRadius: theme.radius.md,
            fontWeight: 500,
            margin: "8px 0",

            "&:hover": {
                backgroundColor: theme.colors[theme.primaryColor][0],
                color: theme.white,

                [`& .${icon}`]: {
                    color: theme.white,
                },
            },
        },
        link1: {
            // color: "#000",
            // display: "flex",
            // alignItems: "center",
            // textDecoration: "none",
        },

        linkIcon: {
            ref: icon,
            color: theme.white,
            marginRight: theme.spacing.sm,
        },
        mobileLinkIcon: {
            ref: icon,
            color: theme.white,
        },

        linkActive: {
            "&, &:hover": {
                backgroundColor: theme.colors[theme.primaryColor][0],
                color: theme.white,
                [`& .${icon}`]: {
                    color: theme.white,
                },
            },
        },
    };
});