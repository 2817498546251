import {MantineThemeOverride} from "@mantine/core";

export const lightTheme: MantineThemeOverride = {
    colorScheme: 'light',
    primaryColor: 'grape',
    colors: {
        grape: ['#8364A9', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94'],
    },
    fontSizes: {xs: 12, sm: 14, md: 16, lg: 20, xl: 24}

};
export const darkTheme: MantineThemeOverride = {
    colorScheme: 'dark',
    primaryColor: 'grape',
    colors: {
        grape: ['#8364A9', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94', '#643E94'],
    },
    fontSizes: {xs: 12, sm: 14, md: 16, lg: 20, xl: 24}
};