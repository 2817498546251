import {Text} from "@mantine/core";
import React from "react";
import {classicNameResolver} from "typescript";
import {useStyles} from "./Heading.styles";

const Heading = ({title}: any) => {
    const {classes} = useStyles();

    return (
        <Text weight={600} size="xl" className={classes.title}>
            {title}
        </Text>
    );
};

export default Heading;
