import {Box, Grid, Paper, Table} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Heading from "../../../components/heading/Heading";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import {getCabWiseReports} from "../../../redux/actions/cab.action";
import {RootState} from "../../../redux/store/store";
import {useStyles} from "./CabWiseReports.styles";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";

const CabWiseReportScreen = () => {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.cabs.cabWiseReport.cabs);
    const loading = useSelector((state: RootState) => state.cabs.cabWiseReport.loading);
    const [date, setDate] = useState(new Date());
    const onDateChange = (value: any) => {
        setDate(value);
        dispatch(getCabWiseReports(dayjs(value).format("YYYY-MM-DD")));
    };
    // console.log('state.cabWiseReport.cabs',state.cabWiseReport.cabs)
    const rows = (data: any) => {
        //console.log('data',data)
        return (
            <>
                {data.map((element: any, x: number) => (
                    <tr key={x}>
                        <td>{element.registration_number}</td>
                        <td>{element.number_of_trips}</td>
                        <td>{element.enabled?"Active":"Inactive"}</td>
                        <td>{element.enabled?element.current_status:"--"}</td>
                        <td>{element.rating.toFixed(1)}</td>
                        <td>{element.location_name}</td>
                        <td>{element.location}</td>
                        <td>{element.location_eta? dayjs(element.location_eta).isBefore(new Date())? "Arrived/About to Arrive": dayjs(element.location_eta).format("YYYY-MM-DD HH:mm a"): ""}</td>
                    </tr>
                ))}
            </>
        );
    };

    useEffect(() => {
        console.log(dayjs(date).format("YYYY-MM-DD"));
        dispatch(getCabWiseReports(dayjs(date).format("YYYY-MM-DD")));
    }, [dispatch, date]);
    return (
        <>
            <Box py="md">
                <Box>
                    <Heading title="Cab Wise Report"/>
                </Box>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>

                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <DatePicker
                            placeholder="Pick date"
                            label="Event date"
                            required
                            value={date}
                            onChange={onDateChange}
                        />
                    </Grid.Col>
                </Grid>
                {loading ? SkeletonLoader() :
                <div style={{overflowX: "auto", height: "100%", margin: "24px 0"}}>
                    <Table highlightOnHover className={classes.table}>
                        <thead>
                        <tr>
                            <th>Cab #</th>
                            <th># of trips</th>
                            <th>Active</th>
                            <th>Status</th>
                            <th>Rating</th>
                            <th>Current Location</th>
                            <th>Destination</th>
                            <th>Estimated Arrival Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.length ? (
                            rows(state)
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <NotEnoughData/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>}
            </Paper>
        </>
    );
};

export default CabWiseReportScreen;
