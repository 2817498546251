import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    navbar: {
        backgroundColor: theme.colors[theme.primaryColor][1],
        marginTop: "-1px",
    },
    mobileNavbar: {
        backgroundColor: theme.colors[theme.primaryColor][1],
    },
    header: {
        backgroundColor: theme.colors[theme.primaryColor][1],
    },
    logo: {
        width: "auto",
        height: "80%",
        paddingLeft: "16px",
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            paddingLeft: "0px",
            height: "60%",
        },
    },
    version: {
        borderTop: `1px solid  ${theme.colors.gray[5]}`,
        paddingTop: "16px",
    },
    button: {
        backgroundColor: "#ffb875",
        color: "#000",
        '&:hover': {
            backgroundColor: "#ffb875",
        }
    },
}));
