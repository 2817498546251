import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    Modal,
    Paper,
    Select,
    Table,
    Text,
    Textarea,
    TextInput
} from "@mantine/core";
import {useStyles} from "./prebooking.styles";
import Heading from "../../../components/heading/Heading";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store/store";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";
import {getAllCabs, getCabArrivalsReports} from "../../../redux/actions/cab.action";
import {
    addPrebooking, assignPrebooking,
    cancelPrebooking,
    getVisitorById
} from "../../../api/api";
import {showNotification} from "@mantine/notifications";
import ImagePreview from "../../../components/imagePreview/ImagePreview";
import {Cab, CabTrip, CabTripCreateRequest, Prebooking, PrebookingCreateRequest} from "../../../@types";
import CircularLoader from "../../../components/loader/CircularLoader";
import {Qrcode} from "tabler-icons-react";
import {QrReader} from "react-qr-reader";
import {getAllAvailableRoutes} from "../../../redux/actions/route.action";
import {getAllPrebookings} from "../../../redux/actions/prebooking.action";
import {DatePicker, TimeInput} from '@mantine/dates';
import ReactGoogleAutocomplete from "react-google-autocomplete";
import {GMAPIK} from "../../../config/google";

const PrebookingListScreen = () => {

    const {classes} = useStyles();
    const [hfnId, setHfnId] = useState<string>("");
    const [visitorContact, setVisitorContact] = useState<string>("");
    const [visitorEmail, setVisitorEmail] = useState<string>("");
    const [visitorName, setVisitorName] = useState<string>("");
    const [headCount, setHeadCount] = useState<any>("");
    const prebookings = useSelector((state: RootState) => state.prebooking);
    const [route, setRoute] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [showModalNewBooking, setShowModalNewBooking] = useState(false)
    const [showModalAssign, setShowModalAssign] = useState(false)
    const [openVidQr, setOpenVidQr] = useState(false);
    const [cabNumber, setCabNumber] = useState<string>("");
    const dispatch = useDispatch();
    const [date, setDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [data, setData] = useState("No result");
    const routesList = useSelector((state: RootState) => state.route.availableRoutesList);
    const cabsList = useSelector((state: RootState) => state.cabs.vehiclesList.vehicles).filter((e) => e.enabled);
    const [currentBooking, setCurrentBooking] = useState<any>(null);
    const [driverContact, setDriverContact] = useState<string>("");
    const [driverPhotoUrl, setDriverPhotoUrl] = useState<string>("");
    const [cabId, setCabId] = useState<string>();
    const [driverId, setDriverId] = useState<string>();
    const [cab, setCab] = useState<any>();
    const [tripDuration, setTripDuration] = useState<any>("");
    const [fare, setFare] = useState<any>("");
    const [sourcePlace, setSourcePlace] = useState<any>(null);
    const [destinationPlace, setDestinationPlace] = useState<any>(null);
    const [sourcePlaceName, setSourcePlaceName] = useState<any>(null);
    const [destinationPlaceName, setDestinationPlaceName] = useState<any>(null);
    const [preDefinedRoute, setPreDefinedRoute] = useState<any>(false);
    const [note, setNote] = useState<string>("");
    useEffect(() => {
        dispatch(getAllCabs());
        dispatch(getAllPrebookings())
        dispatch(getAllAvailableRoutes([dayjs(date).format("YYYY-MM-DD")]))
        dispatch(getAllCabs());
    }, [dispatch]);


    const onChangeRoute = (v: any) => {
        console.log(v)
        setRoute(v)
        debugger
        var route = routesList.routes?.find((el) => String(el.id) === String(v))
        if (route) {
            setFare(route.fare)
        }

    }

    const handleTimeChange = (time: any) => {
        const newDateTime = new Date(date);
        newDateTime.setHours(time.getHours());
        newDateTime.setMinutes(time.getMinutes());
        newDateTime.setSeconds(time.getSeconds());
        setDate(newDateTime);
    };

    const handleVehicleIdChange = (v: any) => {
        setDriverPhotoUrl("");
        setDriverId("")
        setCabId("")
        setDriverContact("")
        setDriverPhotoUrl("")
        setCab(null)
        setRoute("")
        var cabs = cabsList.find((el) => el.registration_number === v)
        if (cabs) {
            setCab(cabs);
            setCabNumber(v);
            setCabId(String(cabs.id));
            if (cabs.current_driver && cabs.current_driver?.enabled === true) {
                setDriverContact(cabs.current_driver.primary_phone_number);
                setDriverPhotoUrl(cabs.current_driver.photo);
                setDriverId(cabs.current_driver.id);
            }
        }

    };

    const _cancelPrebooking = async (id?: any) => {
        try {
            const res = await cancelPrebooking(id)
            if (res.data && res.data.success) {
                setLoading(false)
                console.log("res from cancel  prebooking", res.data);
                showNotification({
                    title: "Success",
                    message: "Prebooking cancelled successfully",
                    color: "green",
                });
                resetForm();
                setShowModalNewBooking(false)
                dispatch(
                    getAllPrebookings()
                );
            } else {
                setLoading(false)
                console.log("res from prebooking cancel", res);
                showNotification({
                    title: "Error",
                    message: "Error in cancelling prebooking",
                    color: "red",
                });
                dispatch(
                    getAllPrebookings()
                );
            }
        } catch (error) {
            setLoading(false)
            dispatch(
                getAllPrebookings()
            );
            console.log(error);
            showNotification({
                title: "Error",
                message: "Error in cancelling prebooking",
                color: "red",
            });
        }
    }
    const _addPrebooking = async (id?: any) => {
        setLoading(true)
        const prebookingData: PrebookingCreateRequest = {
            visitor_name: visitorName,
            visitor_email: visitorEmail,
            visitor_hfn_id: hfnId,
            visitor_phone: visitorContact,
            route_id: route,
            visitor_count: Number(headCount),
            scheduled_time: date,
            has_pre_defined_route: preDefinedRoute,
            note:note,
            fare: Number(fare),
        };
        if (preDefinedRoute) {
            prebookingData.route_id = route
        }
        if (!preDefinedRoute) {
            prebookingData.source_geo_location = {
                lat: sourcePlace?.geometry?.location.lat(),
                lon: sourcePlace?.geometry?.location.lng(),
                name: sourcePlaceName
            }
            prebookingData.destination_geo_location = {
                lat: destinationPlace?.geometry?.location.lat(),
                lon: destinationPlace?.geometry?.location.lng(),
                name: destinationPlaceName
            }
        }
        console.log(prebookingData)
        try {
            const res = await addPrebooking(prebookingData);
            if (res.data && res.data.success) {
                setLoading(false)
                console.log("res from add prebooking", res.data);
                showNotification({
                    title: "Success",
                    message: "Prebooking added successfully",
                    color: "green",
                });
                resetForm();
                setShowModalNewBooking(false)
                dispatch(
                    getAllPrebookings()
                );
            } else {
                setLoading(false)
                console.log("res from prebooking", res);
                showNotification({
                    title: "Error",
                    message: "Error in adding prebooking",
                    color: "red",
                });
                dispatch(
                    getAllPrebookings()
                );
            }
        } catch (error) {
            setLoading(false)
            dispatch(
                getAllPrebookings()
            );
            console.log(error);
            showNotification({
                title: "Error",
                message: "Error in adding prebooking",
                color: "red",
            });
        }
    };

    const onValidatePrebooking = () => {
        if (visitorContact) {
            if (visitorContact.length >= 0) {
                if (visitorContact.startsWith("+91") && visitorContact.length != 13) {

                    showNotification({
                        title: "Error",
                        message: "Invalid Indian Contact number",
                        color: "red",
                    });
                    return false
                } else if (!visitorContact.startsWith("+") && visitorContact.length != 10) {
                    showNotification({
                        title: "Error",
                        message: "Invalid Indian Contact number",
                        color: "red",
                    });
                    return false
                }
            }

        }

        const emailRegex = /\S+@\S+\.\S+/;

        if (visitorEmail && !emailRegex.test(visitorEmail)) {
            showNotification({
                title: "Error",
                message: "Invalid Email",
                color: "red",
            });
            return false
        }

        if (fare === "") {
            showNotification({
                title: "Error",
                message: "Fare has to be defined",
                color: "red",
            });
        }else if (hfnId === "") {
            showNotification({
                title: "Error",
                message: "Visitor ID cannot be empty",
                color: "red",
            });
        } else if (visitorName === "") {
            showNotification({
                title: "Error",
                message: "Visitor name can not be empty",
                color: "red",
            });
        } else if (!headCount || headCount === "") {
            showNotification({
                title: "Error",
                message: "Please enter Passengers headcount",
                color: "red",
            });
        } else if (headCount > 10) {
            showNotification({
                title: "Error",
                message: "Passengers Headcount cannot be more than 5:",
                color: "red",
            });
        } else if (preDefinedRoute && route === "") {
            showNotification({
                title: "Error",
                message: "Select A Route",
                color: "red",
            });
        } else if (!preDefinedRoute && sourcePlace == null || sourcePlace == "") {
            showNotification({
                title: "Error",
                message: "Select Source Place",
                color: "red",
            });
        } else if (!preDefinedRoute && destinationPlace == null || destinationPlace == "") {
            showNotification({
                title: "Error",
                message: "Select Destination Place",
                color: "red",
            });
        } else {
            return true;
        }
    };
    const onSubmitCreatePrebooking = () => {
        if (onValidatePrebooking()) {
            _addPrebooking()
        }
    };

    const onSubmitAssign = () => {
        if (onValidateAssign()) {
            _assignTrip()
        }
    };
    const resetForm = () => {
        setHfnId("");
        setVisitorContact("");
        setVisitorEmail("");
        setVisitorName("");
        setCabNumber("");
        setHeadCount("");
        setRoute("");
        setFare("")
        setPreDefinedRoute(false)
        setDriverContact("");
        setDriverPhotoUrl("");
        setNote("")
    };

    const handleIdChange = async (v: string) => {
        // console.log("e.target.value", e.target.value);
        setHfnId(v);
        try {
            const res = await getVisitorById([v]);
            console.log("res from visitorbyid", res.data);
            if (res.data && res.data.payload.length) {
                setVisitorContact(res.data.payload[0].phone_number);
                setVisitorEmail(res.data.payload[0].email);
                setVisitorName(res.data.payload[0].name)
            } else {
                setVisitorContact("");
                setVisitorEmail("");
            }
        } catch (error: any) {
            setVisitorContact("");
            setVisitorEmail("");
            console.log("error in getting visitor id");
        }
    };

    const handleDriverChange = (v: any) => {
        // debugger
        if (cab && v) {
            if (cab?.drivers) {
                var d = cab.drivers.find((e: any) => e.id == v)
                if (d) {
                    setDriverContact(d.primary_phone_number);
                    setDriverPhotoUrl(d.photo);
                    setDriverId(d.id);
                } else {
                    setDriverContact("");
                    setDriverPhotoUrl("");
                    setDriverId("");
                }
            }
        }
    }

    const onValidateAssign = () => {
        if (cabNumber === "") {
            showNotification({
                title: "Error",
                message: "Select a vehicle from the the dropdown",
                color: "red",
            });
        } else if (driverId === "") {
            showNotification({
                title: "Error",
                message: "Please select the Driver",
                color: "red",
            });
        } else if (driverContact === "") {
            showNotification({
                title: "Error",
                message: "Please enter driver's contact number",
                color: "red",
            });
        } else {
            return true;
        }
    };

    const getLatestAssignment = (prebooking: Prebooking): CabTrip | null => {
        if (prebooking.trips && prebooking.trips.length) {
            return prebooking.trips[prebooking.trips.length - 1];
        }
        return null;
    }
    const _assignTrip = async () => {
        setLoading(true)
        const cabTripData: CabTripCreateRequest = {
            cab_id: String(cabId),
            driver_id: String(driverId),
            number_of_seats: Number(currentBooking.visitors_count),
            route_id: currentBooking.route?.id,
            visitor_name: currentBooking.visitor.name,
            visitor_email: currentBooking.visitor.email,
            visitor_hfn_id: currentBooking.visitor.hfn_id,
            visitor_phone: currentBooking.visitor.phone_number,
            has_pre_defined_route: preDefinedRoute,
            note:note,
            fare: Number(fare),
            scheduled_time: date,
            eta: dayjs(date).add(tripDuration, 'minute').toDate()
        };
        console.log(cabTripData)
        try {
            const res = await assignPrebooking(currentBooking.id, cabTripData);
            setLoading(false)
            dispatch(getAllPrebookings());
            if (res.data && res.data.success) {
                console.log("res from assign prebooking", res.data);
                showNotification({
                    title: "Success",
                    message: "prebooking assigned successfully",
                    color: "green",
                });
                resetForm();
                setShowModalAssign(false);
            } else {
                console.log("res from assign prebooking", res);
                showNotification({
                    title: "Error",
                    message: "Error in assigning prebooking",
                    color: "red",
                });
            }
        } catch (error: any) {
            setLoading(false)
            console.log(error);
            showNotification({
                title: "Error",
                message: error.response.data.message ? error.response.data.message : error.message ? error.message : "Error in assigning prebooking",
                color: "red",
            });
        }
    };
    console.log(prebookings)
    console.log(routesList)
    debugger
    const rows = prebookings.bookings.length ? (
        prebookings.bookings.map(
            (element: any, x: number) => (
                <tr key={x}>
                    <td>
                        <Table>
                            <tr>
                                <td>
                                    <Text style={{textAlign: 'left'}}>
                                        Name:&nbsp;&nbsp;&nbsp;{element.visitor.name}
                                    </Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text style={{textAlign: 'left'}}>
                                        Headcount:&nbsp;&nbsp;&nbsp;{element.visitors_count}
                                    </Text>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Text style={{textAlign: 'left'}}>
                                        Phone:&nbsp;&nbsp;&nbsp;<a
                                        href={"tel:" + element.visitor.phone_number}>{element.visitor.phone_number}</a>
                                    </Text>
                                </td>
                            </tr>
                        </Table>
                    </td>
                    <td>{dayjs(element.scheduled_time).format("YYYY-MM-DD  @  hh:mm a")}</td>
                    <td>
                        <Text style={{textAlign: 'left'}}>
                            {element.latest_trip?.cab_registration_number}
                        </Text>
                    </td>
                    <td>
                        <Grid>
                            <Grid.Col mt={3} ml={15}>{element.latest_trip?.driver.first_name}</Grid.Col>
                            <Grid.Col mt={3} ml={15}>
                                <Text style={{textAlign: 'left'}}>
                                    <a
                                    href={"tel:" + element.latest_trip?.driver.primary_phone_number}>{element.latest_trip?.driver.primary_phone_number}</a>
                                </Text>
                            </Grid.Col>
                        </Grid>
                    </td>
                    <td>
                        <Grid>
                            <Grid.Col>
                                <Button onClick={async() => {
                                    debugger
                                    resetForm()
                                    setPreDefinedRoute(element.has_pre_defined_route)
                                    setCurrentBooking(element)
                                    setRoute(element.route?.id)
                                    setFare(element.fare)
                                    setDate(new Date(element.scheduled_time))
                                    setTripDuration(element.has_pre_defined_route?element.route?.time_estimates_minutes:"")
                                    setNote(element.note)
                                    setShowModalAssign(true)
                                }} ml={15}>
                                    {element.latest_trip ? "Re Assign" : "Assign"}
                                </Button>
                            </Grid.Col>
                            <Grid.Col>
                                <Button onClick={() => {
                                    _cancelPrebooking(element.id)
                                }} ml={15}>
                                    Cancel
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </td>
                </tr>
            )
        )
    ) : (
        <tr>
            <td colSpan={5}>
                <NotEnoughData/>
            </td>
        </tr>
    );

    return (
        <>
            <Box py="md">
                <Box>
                    <Heading title="Prebooking"/>
                </Box>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                {loading && <CircularLoader/>}
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <Button onClick={() => {
                            setShowModalNewBooking(true)
                        }} ml={15}>
                            New Booking
                        </Button>
                    </Grid.Col>
                </Grid>
                {prebookings.loading ? (
                    SkeletonLoader()
                ) : (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            overflow: "auto",
                            marginTop: "24px",
                        }}
                    >
                        <Table highlightOnHover className={classes.table}>
                            <thead>
                            <tr>
                                <th>Visitor</th>
                                <th>Scheduled Date Time</th>
                                <th>Cab</th>
                                <th>Driver</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {prebookings.bookings.length ? (
                                rows
                            ) : (
                                <tr>
                                    <td colSpan={11}>
                                        <NotEnoughData/>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                        <Modal opened={showModalNewBooking} onClose={() => {
                            setShowModalNewBooking(false)
                        }}>
                            <Box py="md">
                                <Heading title="New Booking"/>
                            </Box>
                            <Paper shadow="xs" p="md">
                                {/* <Text color='red' size="xs" mb={8}>Note: Email or contact # is mandatory</Text> */}
                                <Grid>
                                    <Grid.Col>
                                        <Box className={classes.qrSccannerBox}>
                                            <TextInput
                                                //type="number"
                                                placeholder="Enter Abhyasi ID / Government ID"
                                                label="Enter Abhyasi ID / Government ID"
                                                value={hfnId}
                                                required
                                                onChange={(e) => handleIdChange(e.target.value)}
                                            />
                                            <Qrcode size={40} onClick={() => setOpenVidQr(true)}
                                                    style={{cursor: 'pointer'}}/>
                                        </Box>

                                        {openVidQr && (
                                            <Modal centered opened={openVidQr} onClose={() => setOpenVidQr(false)}
                                                   title="Scan Qr">
                                                <QrReader
                                                    onResult={(result: any, error) => {
                                                        setData(result?.text)
                                                        if (result) {
                                                            console.log('result from qr scanner', result)
                                                            setOpenVidQr(false)
                                                            handleIdChange(result?.text)
                                                        }

                                                        if (error) {
                                                            console.info(error);
                                                        }
                                                    }}
                                                    constraints={{facingMode: "environment"}}
                                                    videoStyle={{width: "100%"}}
                                                />
                                                <p>{data}</p>
                                            </Modal>
                                        )}
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            placeholder="+910000000000"
                                            label="Visitor contact #"
                                            type="tel"
                                            value={visitorContact}
                                            onChange={(e) => {
                                                const phoneRegex = /^[\+]?[0-9]*$/;
                                                if (e.target.value.length >= 0 && phoneRegex.test(e.target.value)) {
                                                    if (e.target.value.startsWith("+")) {
                                                        if (e.target.value.startsWith("+91")) {
                                                            e.target.value.length <= 13 && setVisitorContact(e.target.value)
                                                        } else {
                                                            e.target.value.length <= 20 && setVisitorContact(e.target.value)
                                                        }
                                                    } else {
                                                        e.target.value.length <= 10 && setVisitorContact(e.target.value)
                                                    }
                                                }

                                            }
                                            }
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            placeholder="Visitor email"
                                            label="Visitor email"
                                            type="email"
                                            value={visitorEmail}
                                            onChange={(e) => setVisitorEmail(e.target.value)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            placeholder=" Visitor Name"
                                            label="Visitor Name "
                                            required
                                            value={visitorName}
                                            onChange={(e) => setVisitorName(e.target.value)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            placeholder="Passengers"
                                            label="Passengers"
                                            required
                                            type={"number"}
                                            value={headCount}
                                            onChange={(e) => setHeadCount(Number(e.target.value))}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <DatePicker

                                            placeholder="Pick date"
                                            label="Trip date"
                                            required
                                            value={date}
                                            onChange={(e: any) => {
                                                setDate(e!);
                                                setRoute("");
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TimeInput
                                            placeholder="Select time"
                                            label="Trip time"
                                            format={"12"}
                                            required
                                            value={date}
                                            onChange={(e: any) => {
                                                handleTimeChange(e!)
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Checkbox
                                            label="Pre Defined Routes"
                                            required
                                            value={preDefinedRoute}
                                            onChange={(e) => setPreDefinedRoute(!preDefinedRoute)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col style={{display: preDefinedRoute ? 'block' : 'none'}}>
                                        <Select
                                            placeholder="Pick one"
                                            label="Route"
                                            required
                                            searchable
                                            value={route}
                                            defaultValue={""}
                                            onChange={onChangeRoute}
                                            data={
                                                routesList.routes.length
                                                    ? routesList.routes?.map((i: any) => ({
                                                        value: String(i.id),
                                                        label: i.source.name + " ===>> " + i.destination.name,
                                                    }))
                                                    : []
                                            }
                                        />
                                    </Grid.Col>
                                    <Grid.Col style={{display: !preDefinedRoute ? 'block' : 'none'}}>
                                        <ReactGoogleAutocomplete style={{width: "100%", height: "40px"}}
                                                                 apiKey={GMAPIK}
                                                                 onError={event => console.log(event)}
                                                                 placeholder={"Enter Source Location"}
                                                                 options={{fields: ["address_components", "geometry", "icon", "name"],types: ['establishment',], componentRestrictions:{ country: "in" }}}
                                                                 onPlaceSelected={(places, ref: any) => {setSourcePlace(places); setSourcePlaceName(ref.value) }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col style={{display: !preDefinedRoute ? 'block' : 'none'}}>
                                        <ReactGoogleAutocomplete style={{width: "100%", height: "40px"}}
                                                                 onError={event => console.log(event)}
                                                                 apiKey={GMAPIK}
                                                                 placeholder={"Enter Destination Location"}
                                                                 options={{fields: ["address_components", "geometry", "icon", "name"],types: ['establishment',], componentRestrictions:{ country: "in" }}}
                                                                 onPlaceSelected={(places, ref: any) => {setDestinationPlace(places); setDestinationPlaceName(ref.value) }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Textarea placeholder={"Notes"} value={note} onChange={event => setNote(event.target.value)}>
                                        </Textarea>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <TextInput label="Fare"
                                                   type={"number"}
                                                   value={fare}
                                                   onChange={event => (setFare(event.target.value))}
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Divider mt={16}/>
                                <Grid justify="flex-end" pt={24} pb={16} pr={8}>
                                    <Button mr={8} onClick={() => {
                                        resetForm();
                                        setShowModalNewBooking(false)
                                    }}>
                                        Discard
                                    </Button>
                                    <Button onClick={onSubmitCreatePrebooking}>Book</Button>
                                </Grid>
                                {loading && <CircularLoader/>}
                            </Paper>
                        </Modal>
                        <Modal opened={showModalAssign} onClose={() => {
                            setShowModalAssign(false)
                        }}>
                            <Box py="md">
                                <Heading title="Allocation of Cab to Bookings"/>
                            </Box>
                            <Paper shadow="xs" p="md">
                                {/* <Text color='red' size="xs" mb={8}>Note: Email or contact # is mandatory</Text> */}
                                <Grid>
                                    <Grid.Col>
                                        <TextInput
                                            readOnly={true}
                                            disabled={true}
                                            placeholder="Enter Abhyasi ID / Government ID"
                                            label="Enter Abhyasi ID / Government ID"
                                            value={currentBooking?.visitor.hfn_id}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            disabled={true}
                                            readOnly={true}
                                            placeholder="+910000000000"
                                            label="Visitor contact #"
                                            type="tel"
                                            value={currentBooking?.visitor.phone_number}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            disabled={true}
                                            readOnly={true}
                                            placeholder="Visitor email"
                                            label="Visitor email"
                                            type="email"
                                            value={currentBooking?.visitor.email}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            disabled={true}
                                            readOnly={true}
                                            placeholder=" Visitor Name"
                                            label="Visitor Name "
                                            required
                                            value={currentBooking?.visitor.name}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <TextInput
                                            disabled={true}
                                            readOnly={true}
                                            placeholder="Passengers"
                                            label="Passengers"
                                            required
                                            type={"number"}
                                            value={currentBooking?.visitors_count}
                                        />
                                    </Grid.Col>

                                   <Grid.Col>
                                            <TextInput
                                                disabled={true}
                                                readOnly={true}
                                                placeholder="Route"
                                                label="Route"
                                                required
                                                value={currentBooking?.source_geo_location.name + " ===>> " + currentBooking?.destination_geo_location.name}
                                            />
                                   </Grid.Col>
                                    <Grid.Col>
                                        <Textarea placeholder={"Notes"} value={note} onChange={event => setNote(event.target.value)}>
                                        </Textarea>
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Select
                                            label="Cab"
                                            placeholder={"Pick one"}
                                            searchable={true}
                                            required
                                            data={cabsList.filter((e: any) => e.enabled).map((i: any) => ({
                                                value: String(i.registration_number),
                                                label: `${i.registration_number}`,
                                            }))}
                                            onChange={(value: any) => {
                                                handleVehicleIdChange(value)
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col>
                                        <Select
                                            label="Driver Name"
                                            placeholder={"Pick one"}
                                            required
                                            data={cab ? cab.drivers?.filter((e: any) => e.enabled).map((i: any) => ({
                                                value: String(i.id),
                                                label: `${i.first_name} ${i.last_name}`,
                                            }))! : []}
                                            value={String(driverId)}
                                            onChange={(value: any) => handleDriverChange(value)}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <TextInput label="Fare"
                                                   type={"number"}
                                                   value={fare}
                                                   onChange={event => (setFare(event.target.value))}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <DatePicker

                                            placeholder="Pick date"
                                            label="Trip date"
                                            required
                                            value={date}
                                            onChange={(e: any) => {
                                                setDate(e!);
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <TimeInput
                                            placeholder="Select time"
                                            label="Start time"
                                            format={"12"}
                                            required
                                            value={date}
                                            onChange={(e: any) => {
                                                handleTimeChange(e!)
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6} lg={4}>
                                        <TextInput readOnly={preDefinedRoute} label="Trip Duration in Minutes" type={"number"} value={tripDuration} onChange={event => {setTripDuration(event.target.value)}}/>
                                    </Grid.Col>

                                    <Grid.Col>
                                        <Text weight={500} mb={8} size="sm">
                                            Driver Photo
                                        </Text>
                                        <Box sx={{width: "100%", height: "250px"}}>
                                            {driverPhotoUrl ? (
                                                <Box
                                                    key={driverPhotoUrl}
                                                    sx={{width: "100%", height: "250px"}}
                                                >
                                                    <ImagePreview src={driverPhotoUrl} closeIcon={false}/>
                                                </Box>
                                            ) : null}
                                        </Box>
                                    </Grid.Col>
                                </Grid>
                                <Divider mt={16}/>
                                <Grid justify="flex-end" pt={24} pb={16} pr={8}>
                                    <Button mr={8} onClick={() => {
                                        resetForm();
                                        setShowModalAssign(false)
                                    }}>
                                        Discard
                                    </Button>
                                    <Button onClick={onSubmitAssign}>Assign</Button>
                                </Grid>
                                {loading && <CircularLoader/>}
                            </Paper>
                        </Modal>
                    </div>
                )}
            </Paper>
        </>
    );
};

export default PrebookingListScreen;
