import React from "react";
import {
    AppShell,
    Navbar,
    Header,
    Footer,
    Aside,
    Text,
    MediaQuery,
    useMantineTheme,
    Box,
    ScrollArea,
    Group,
    Button,
    Avatar,
} from "@mantine/core";
import Nav from "../Navbar/Nav";
import {useStyles} from "./Dashboard.styles";
import {User} from "./user/User";
import version from "../../../package.json";
import Logo from "./logo/Logo";
import SwipableDrawer from "./swipableDrawer/SwipableDrawer";
import {useNavigate} from "react-router-dom";

type Props = {
    children?: React.ReactNode;
};

export default function Dashboard({children}: Props) {
    const theme = useMantineTheme();
    const {classes} = useStyles();
    const navigate = useNavigate();
    return (
        <AppShell
            styles={{
                main: {
                    background:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            }}
            fixed
            navbar={
                <MediaQuery smallerThan="sm" styles={{display: "none"}}>
                    <Navbar
                        p="md"
                        hiddenBreakpoint="sm"
                        width={{sm: 280, lg: 280}}
                        height="100%"
                        className={classes.navbar}
                    >
                        <Navbar.Section>
                            <User/>
                        </Navbar.Section>
                        <Navbar.Section grow component={ScrollArea} mx="-xs" mt={8}>
                            <Nav/>
                        </Navbar.Section>
                        <Navbar.Section mb="30%">
                            <Box className={classes.version}>
                                <Text align="center" color="#fff">
                                    Transport portal
                                </Text>
                                <Text
                                    align="center"
                                    color="#ffb875"
                                >{`V #${version.version}`}</Text>
                            </Box>
                        </Navbar.Section>
                    </Navbar>
                </MediaQuery>
            }
            footer={
                <MediaQuery largerThan="sm" styles={{display: "none"}}>
                    <Footer height={60} px="xl" py={4} className={classes.mobileNavbar}>
                        <Nav view="mobile"/>
                    </Footer>
                </MediaQuery>
            }
            header={
                <Header height={70} p="md" className={classes.header}>
                    <Group sx={{height: "100%"}} px={20} position="apart">
                        <Logo/>
                        <Box>
                            <MediaQuery smallerThan="sm" styles={{display: "none"}}>
                                <Button
                                    onClick={() => {
                                        localStorage.clear();
                                        navigate('/')
                                    }}
                                    className={classes.button}
                                >
                                    LogOut
                                </Button>
                            </MediaQuery>
                            <MediaQuery largerThan="sm" styles={{display: "none"}}>
                                <SwipableDrawer/>
                            </MediaQuery>
                        </Box>
                    </Group>
                </Header>
            }
        >
            <Box
                styles={{padding: {lg: "16px 32px", md: "16px 24px", sm: "16px"}}}
            >
                {children}
            </Box>
        </AppShell>
    );
}
