import {Box, Grid, Paper, Table} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Heading from "../../../components/heading/Heading";
import NotEnoughData from "../../../components/notEnoughData/NotEnoughData";
import {getDriverReports} from "../../../redux/actions/driver.action";
import {RootState} from "../../../redux/store/store";
import {useStyles} from "./DriverReports.styles";
import SkeletonLoader from "../../../components/loader/SkeletonLoader";

const DriverReportsScreen = () => {
    const {classes} = useStyles();
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.driver);
    const loading = useSelector((state: RootState) => state.driver.driverReport.loading);
    const [date, setDate] = useState(new Date());
    const onDateChange = (value: any) => {
        setDate(value);
    };
    useEffect(() => {
        dispatch(getDriverReports(dayjs(date).format('YYYY-MM-DD')));
    }, [dispatch, date]);

    const rows = (data: any) =>
        data.map((element: any, x: number) => (
            <tr key={x}>
                <td>{element.cab_registration_number}</td>
                <td>{element.name}</td>
                <td>{element.primary_phone_number}</td>
                <td>{element.number_of_trips}</td>
                <td>{element.enabled?"Active":"Inactive"}</td>
                <td>{element.cab_current_status}</td>
                <td>{element.driver_rating.toFixed(1)}</td>
            </tr>
        ));
    return (
        <>
            <Box py="md">
                <Box>
                    <Heading title="Driver Reports"/>
                </Box>
            </Box>
            <Paper shadow="xs" p="md" mt={18}>
                <Grid>
                    <Grid.Col sm={12} md={6} lg={3}>
                        <DatePicker
                            placeholder="Pick date"
                            label="Event date"
                            required
                            value={date}
                            onChange={onDateChange}
                        />
                    </Grid.Col>
                </Grid>
                {loading ? SkeletonLoader() :
                <div style={{overflowX: "auto", height: '100%', marginTop: '24px'}}>
                    <Table highlightOnHover className={classes.table}>
                        <thead>
                        <tr>
                            <th>Cab #</th>
                            <th>Driver Name</th>
                            <th>Contact # of driver</th>
                            <th># of trips</th>
                            <th>Status</th>
                            <th>Current Cab Status</th>
                            <th>Driver Rating</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.driverReport.drivers.length ? (
                            rows(state.driverReport.drivers)
                        ) : (
                            <tr>
                                <td colSpan={6}>
                                    <NotEnoughData/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </div>}
            </Paper>
        </>
    );
};

export default DriverReportsScreen;
